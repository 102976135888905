import React from 'react';
import {
  Settings as SettingsIcon,
  Users as UsersIcon,
  Truck as TruckIcon,
  Slack as SlackIcon,
  User as HrmIcon,
  MapPin as MapPinIcon,
  Command as CommandIcon,
  PlusCircle as PlusIcon,
  HelpCircle as HelpIcon,
  Monitor as MonitorIcon,
  Package as PackageIcon,
  Sliders as SlidersIcon,
  FileText as FileTextIcon,
  Archive as ArchiveIcon,
  ShoppingCart as ShoppingIcon,
  TrendingUp as TrendingUpIcon,
  DollarSign as DollarSignIcon,
  ShoppingBag as ShoppingBagIcon,
  Codesandbox as CodesandboxIcon,
  Smile as SmileIcon,
  Tag as RPIcon,
  // Box as BoxIcon,
  // Lock as LockIcon,
  // File as FileIcon,
  // Code as CodeIcon,
  // Info as InfoIcon,
  // Book as BookICon,
  // Award as AwardIcon,
  // Truck as TruckIcon,
  // Inbox as InboxIcon,
  // Folder as FolderIcon,
  // Database as DatabaseIcon,
  // Archive as ArchiveIcon,
  // Sliders as SlidersIcon,
  // Settings as SettingsIcon,
  // BookOpen as BookOpenIcon,
  // Clipboard as ClipboardIcon,
  // Briefcase as BriefcaseIcon,
  // DollarSign as DollarSignIcon,
  // CreditCard as CreditCardIcon,
  // ShoppingCart as ShoppingCartIcon,
} from 'react-feather';
import { IconButtonBase } from 'components';
import { IconButton } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

const RP_LINK = process.env.REACT_APP_RP_LINK;
const MODULE_RP = process.env.REACT_APP_MODULE_RP;

const returnBool = (data) => {
  if (data === undefined) {
    return true;
  }
  if (data === 'true') {
    return true;
  }
  return false;
};

const navConfig = [
  // {
  //   subheader: 'Operación',
  //   items: [
  //     {
  //       title: 'RP Dental',
  //       href: '/app/rpdental',
  //       icon: RPIcon,
  //       items: [
  //         // {
  //         //   title: 'Asignaciones soportes quirúrgicos',
  //         //   href: '/app/rpdental/programacion/listtable',
  //         //   permission: ['rpdental:asignacionessoportesquirurgicos:list'],
  //         //   info: () => (
  //         //     <IconButtonBase url="/app/rpdental/programacion/asignacions
  //         // oportequirurgico" permission=
  // {['rpdental:asignacionessoportesquirurgicos:create']} />
  //         //   ),
  //         // },
  //         {
  //           title: 'Reportes gastos',
  //           href: '/app/rpdental/reportegastos',
  //           permission: ['rpdental:reportesgastos:list'],
  //           info: () => (
  //             <IconButtonBase url="/app/rpdental/reportegastos"
  // permission={['rpdental:reportesgastos:create']} />
  //           ),
  //         },
  //         {
  //           title: 'Consultas',
  //           href: '/app/rpdental/informes',
  //           items: [
  //             {
  //               title: 'Asignaciones soportes quirúrgicos',
  //               href: '/app/rpdental/informes/programacionsoporte',
  //               permission: ['rpdental:informes:informesasignacionessoportesquirurgicos:list'],
  //             },
  //             {
  //               title: 'Reportes gastos',
  //               href: '/app/rpdental/informes/reportegastos',
  //               permission: ['rpdental:informes:informesreportesgastos:list'],
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  {
    items: [
      {
        title: 'GENEFIX',
        href: '/app/rpdental',
        icon: RPIcon,
        forbidden: returnBool(MODULE_RP),
        items: [
          {
            title: 'Operación',
            href: '/app/operacion',
            items: [
              {
                title: 'Prepedido',
                href: '/app/operacion/prepedido',
                path: `${RP_LINK}/app/operacion/prepedido`,
              },
              {
                title: 'Asignación soporte quirúrgico',
                href: '/app/operacion/asignacionsoportequirurgico',
                path: `${RP_LINK}/app/operacion/asignacionsoportequirurgico`,
              },
              {
                title: 'Reporte de gastos',
                href: '/app/operacion/reportegastos',
                path: `${RP_LINK}/app/operacion/reportegastos`,
              },
            ],
          },
          {
            title: 'Configuración',
            href: '/app/configuracion/',
            items: [
              {
                title: 'eps',
                href: '/app/rpdental/eps',
                path: `${RP_LINK}/app/configuracion/eps`,
              },
              {
                title: 'Especialidades',
                href: '/app/rpdental/especialidad',
                path: `${RP_LINK}/app/configuracion/especialidades`,
              },
              {
                title: 'Universidad',
                href: '/app/rpdental/universidad',
                path: `${RP_LINK}/app/configuracion/universidad`,
              },
              {
                title: 'Fellow',
                href: '/app/rpdental/fellow',
                path: `${RP_LINK}/app/configuracion/fellow`,
              },
              {
                title: 'Sub especialidad',
                href: '/app/rpdental/subespecialidad',
                path: `${RP_LINK}/app/configuracion/subespecialidad`,
              },
              {
                title: 'Tipo afiliación',
                href: '/app/rpdental/tipoafiliacion',
                path: `${RP_LINK}/app/configuracion/tipoafiliacion`,
              },
              {
                title: 'Diagnóstico',
                href: '/app/rpdental/diagnostico',
                path: `${RP_LINK}/app/configuracion/diagnostico`,
              },
              {
                title: 'Instituciones',
                href: '/app/rpdental/institucion',
                path: `${RP_LINK}/app/configuracion/institucion`,
              },
              {
                title: 'Pacientes',
                href: '/app/rpdental/paciente',
                path: `${RP_LINK}/app/configuracion/pacientes`,
              },
              {
                title: 'Cirujanos',
                href: '/app/rpdental/cirujanos',
                path: `${RP_LINK}/app/configuracion/cirujanos`,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    items: [
      {
        title: 'CRM',
        href: '/app/crm',
        icon: UsersIcon,
        permission: [
          'app:general:crm:cotizaciones:view',
          'app:general:crm:consultas:cotizaciones:view',
        ],
        items: [
          {
            title: 'Cotizaciones',
            href: '/app/crm/cotizaciones',
            permission: ['app:general:crm:cotizaciones:view'],
            info: () => (
              <IconButtonBase
                url="/app/crm/cotizaciones/new"
                permission={['app:general:crm:cotizaciones:new']}
              />
            ),
          },
          {
            title: 'Consultas',
            href: '/app/crm/consultas',
            items: [
              {
                title: 'Cotizaciones',
                href: '/app/crm/consultas/cotizaciones',
                permission: ['app:general:crm:consultas:cotizaciones:view'],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    items: [
      {
        title: 'Ventas',
        href: '/app/ventas',
        icon: ShoppingBagIcon,
        permission: [
          'app:general:ventas:pedidos:view',
          'app:general:ventas:facturas:view',
          'app:general:ventas:pendientesporfacturar:view',
          'app:general:ventas:consultas:pedidos:view',
          'app:general:ventas:consultas:facturas:view',
          'app:general:ventas:consultas:ventas:view',
          'app:general:ventas:notascredito:view',
          'app:general:ventas:notasdebito:view',
          'app:general:ventas:consultas:notascredito:view',
          'app:general:ventas:consultas:notasdebito:view',
          'app:general:ventas:consultas:margen:view',
          'app:general:ventas:consultas:ventasproductos:view',
        ],
        items: [
          // {
          //   title: 'Pre pedidos',
          //   href: '/app/venta/prepedido',
          //   permission: ['venta:prepedidos:list'],
          //   info: () => (
          //     <IconButtonBase url="/app/venta/pedido/new"
          // permission={['venta:prepedidos:create']} />
          //   ),
          // },
          {
            title: 'Pedidos',
            href: '/app/ventas/pedidos',
            permission: ['app:general:ventas:pedidos:view'],
            info: () => (
              <IconButtonBase
                url="/app/ventas/pedidos/new"
                permission={['app:general:ventas:pedidos:new']}
              />
            ),
          },
          // {
          //   title: 'Pedidos',
          //   href: '/app/venta/ventadirecta',
          //   permission: ['venta:ventasdirectas:list'],
          //   info: () => (
          //     <IconButtonBase url="/app/venta/ventadirecta/new"
          // permission={['venta:ventasdirectas:create']} />
          //   ),
          // },
          {
            title: 'Facturas',
            href: '/app/ventas/facturas',
            permission: ['app:general:ventas:facturas:view'],
            info: () => (
              <IconButtonBase
                url="/app/ventas/facturas/new"
                permission={['app:general:ventas:facturas:new']}
              />
            ),
          },
          {
            title: 'Pendientes por facturar',
            href: '/app/ventas/pendientesporfacturar',
            permission: ['app:general:ventas:pendientesporfacturar:view'],
          },
          {
            title: 'Notas crédito',
            href: '/app/ventas/notascredito',
            permission: ['app:general:ventas:notascredito:view'],
            info: () => (
              <IconButtonBase
                url="/app/ventas/notascredito/new"
                permission={['app:general:ventas:notascredito:new']}
              />
            ),
          },
          {
            title: 'Notas débito',
            href: '/app/ventas/notasdebito',
            permission: ['app:general:ventas:notasdebito:view'],
            info: () => (
              <IconButtonBase
                url="/app/ventas/notasdebito/new"
                permission={['app:general:ventas:notasdebito:new']}
              />
            ),
          },
          {
            title: 'Consultas',
            href: '/app/ventas/consultas',
            permission: [
              'app:general:ventas:consultas:pedidos:view',
              'app:general:ventas:consultas:facturas:view',
              'app:general:ventas:consultas:ventas:view',
              'app:general:ventas:consultas:notascredito:view',
              'app:general:ventas:consultas:notasdebito:view',
              'app:general:ventas:consultas:margen:view',
              'app:general:ventas:consultas:ventasproductos:view',
            ],
            items: [
              {
                title: 'Pedidos',
                href: '/app/ventas/consultas/pedidos',
                permission: ['app:general:ventas:consultas:pedidos:view'],
              },
              {
                title: 'Facturas',
                href: '/app/ventas/consultas/facturas',
                permission: ['app:general:ventas:consultas:facturas:view'],
              },
              {
                title: 'Ventas',
                href: '/app/ventas/consultas/ventas',
                permission: ['app:general:ventas:consultas:ventas:view'],
              },
              {
                title: 'Ventas productos',
                href: '/app/ventas/consultas/ventasproductos',
                permission: ['app:general:ventas:consultas:ventasproductos:view'],
              },
              {
                title: 'Notas crédito',
                href: '/app/ventas/consultas/notascredito',
                permission: ['app:general:ventas:consultas:notascredito:view'],
              },
              {
                title: 'Notas débito',
                href: '/app/ventas/consultas/notasdebito',
                permission: ['app:general:ventas:consultas:notasdebito:view'],
              },
              {
                title: 'Margen',
                href: '/app/ventas/consultas/margen',
                permission: ['app:general:ventas:consultas:margen:view'],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    items: [
      {
        title: 'Gestión proveedores (SRM)',
        href: '/app/gestionproveedores',
        icon: PackageIcon,
        permission: [
          'app:general:gestionproveedores:view',
        ],
        items: [
          {
            title: 'Consultas',
            href: '/app/gestionproveedores/consultas',
            items: [],
          },
        ],
      },
    ],
  },
  {
    items: [
      {
        title: 'Compras',
        href: '/app/compras',
        icon: ShoppingIcon,
        permission: [
          'app:general:compras:sir:view',
          'app:general:compras:ordenescompras:view',
          'app:general:compras:consultas:ordenescompras:view',
          'app:general:compras:consultas:comprasproductos:view',
        ],
        items: [
          {
            title: 'SIR',
            href: '/app/compras/sir',
            permission: ['app:general:compras:sir:view'],
            info: () => (
              <IconButtonBase
                url="/app/compras/sir/new"
                permission={['app:general:compras:sir:new']}
              />
            ),
          },
          {
            title: 'Ordenes compras',
            href: '/app/compras/ordenescompras',
            permission: ['app:general:compras:ordenescompras:view'],
          },
          {
            title: 'Consultas',
            href: '/app/compras/consultas',
            items: [
              {
                title: 'Ordenes compras',
                href: '/app/compras/consultas/ordenescompras',
                permission: [
                  'app:general:compras:consultas:ordenescompras:view',
                ],
              },
              {
                title: 'Compras-Producto',
                href: '/app/compras/consultas/comprasproductos',
                permission: [
                  'app:general:compras:consultas:comprasproductos:view',
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    items: [
      {
        title: 'Producción (PLM)',
        href: '/app/produccion',
        icon: SlidersIcon,
        permission: ['app:general:produccion:view'],
        items: [
          {
            title: 'Consultas',
            href: '/app/produccion/consultas',
            items: [],
          },
        ],
      },
    ],
  },
  {
    items: [
      {
        title: 'Distribución (WMS)',
        href: '/app/distribucion',
        icon: MapPinIcon,
        permission: [
          'app:general:distribucion:programacionestomasinventario:view',
          'app:general:distribucion:ejecucionestomasinventario:view',
          'app:general:distribucion:ajustesinventario:view',
          'app:general:distribucion:inventariogeneral:entradas:view',
          'app:general:distribucion:inventariogeneral:salidas:view',
          'app:general:distribucion:inventariogeneral:traslados:view',
          'app:general:distribucion:productoterminado:entradas:view',
          'app:general:distribucion:productoterminado:salidas:view',
          'app:general:distribucion:productoterminado:traslados:view',
          'app:general:distribucion:productoterminado:solicituddetraslados:view',
          'app:general:distribucion:consultas:bodegas:view',
          'app:general:distribucion:consultas:historicoajusteinventario:view',
          'app:general:distribucion:consultas:productosproximosavencer:view',
          'app:general:distribucion:consultas:inventariogeneral:existencias:view',
          'app:general:distribucion:consultas:inventariogeneral:activosfijos:view',
          'app:general:distribucion:consultas:productoterminado:existencias:view',
          'app:general:distribucion:consultas:productoterminado:inventariovalorizable:view',
          'app:general:distribucion:consultas:productoterminado:reservas:view',
          'app:general:distribucion:consultas:productoterminado:semaforo:view',
          'app:general:distribucion:consultas:productoterminado:mercanciasentransito:view',
          'app:general:distribucion:consultas:productoterminado:kardex:view',
          'app:general:distribucion:consultas:productoterminado:kardexdetalle:view',
          'app:general:distribucion:materiaprima:view',
        ],
        items: [
          {
            title: 'Programaciones tomas inventario',
            href: '/app/distribucion/programacionestomasinventario',
            permission: [
              'app:general:distribucion:programacionestomasinventario:view',
            ],
            info: () => (
              <IconButtonBase
                url="/app/distribucion/programacionestomasinventario/new"
                permission={[
                  'app:general:distribucion:programacionestomasinventario:new',
                ]}
              />
            ),
          },
          {
            title: 'Ejecuciones tomas inventario',
            href: '/app/distribucion/ejecucionestomasinventario',
            permission: [
              'app:general:distribucion:ejecucionestomasinventario:view',
            ],
          },
          {
            title: 'Ajustes inventario',
            href: '/app/distribucion/ajustesinventario',
            permission: ['app:general:distribucion:ajustesinventario:view'],
          },
          {
            title: 'Inventario general',
            href: '/app/distribucion/inventariogeneral',
            permission: [
              'app:general:distribucion:inventariogeneral:entradas:view',
              'app:general:distribucion:inventariogeneral:salidas:view',
              'app:general:distribucion:inventariogeneral:traslados:view',
            ],
            items: [
              {
                title: 'Entradas',
                href: '/app/distribucion/inventariogeneral/E',
                permission: [
                  'app:general:distribucion:inventariogeneral:entradas:view',
                ],
              },
              {
                title: 'Salidas',
                href: '/app/distribucion/inventariogeneral/S',
                permission: [
                  'app:general:distribucion:inventariogeneral:salidas:view',
                ],
              },
              {
                title: 'Traslados',
                href: '/app/distribucion/inventariogeneral/traslados',
                permission: [
                  'app:general:distribucion:inventariogeneral:traslados:view',
                ],
              },
            ],
          },
          {
            title: 'Materia prima',
            href: '/app/distribucion/materiaprima',
            permission: [
              'app:general:distribucion:materiaprima:view',
            ],
            items: [
              {
                title: 'Entradas',
                href: '/app/distribucion/materiaprima/E',
                permission: ['scm:inventario:inventariogeneral:entradas:list'],
              },
              {
                title: 'Salidas',
                href: '/app/distribucion/materiaprima/S',
                permission: ['scm:inventario:inventariogeneral:salidas:list'],
              },
              {
                title: 'Traslados',
                href: '/app/distribucion/materiaprima/traslados',
                permission: ['scm:inventario:inventariogeneral:traslados:list'],
              },
            ],
          },
          {
            title: 'Producto terminado',
            href: '/app/distribucion/productoterminado',
            items: [
              {
                title: 'Entradas',
                href: '/app/distribucion/productoterminado/E',
                permission: [
                  'app:general:distribucion:productoterminado:entradas:view',
                ],
              },
              {
                title: 'Salidas',
                href: '/app/distribucion/productoterminado/S',
                permission: [
                  'app:general:distribucion:productoterminado:salidas:view',
                ],
              },
              {
                title: 'Traslados',
                href: '/app/distribucion/productoterminado/traslados',
                permission: [
                  'app:general:distribucion:productoterminado:traslados:view',
                ],
              },
              {
                title: 'Solicitudes traslados',
                href:
                  '/app/distribucion/productoterminado/solicitudestraslados',
                permission: [
                  'app:general:distribucion:productoterminado:solicituddetraslados:view',
                ],
                info: () => (
                  <IconButtonBase
                    url="/app/distribucion/productoterminado/solicitudestraslados/new"
                    permission={[
                      'app:general:distribucion:productoterminado:solicituddetraslados:new',
                    ]}
                  />
                ),
              },
            ],
          },
          {
            title: 'Consultas',
            href: '/app/distribucion/consultas',
            items: [
              {
                title: 'Bodegas',
                href: '/app/distribucion/consultas/bodegas',
                permission: ['app:general:distribucion:consultas:bodegas:view'],
              },
              {
                title: 'Históricos ajustes inventario',
                href: '/app/distribucion/consultas/historicoajustes',
                permission: [
                  'app:general:distribucion:consultas:historicoajusteinventario:view',
                ],
              },
              {
                title: 'Productos próximos a vencer',
                href: '/app/distribucion/consultas/proximosavencer',
                permission: [
                  'app:general:distribucion:consultas:productosproximosavencer:view',
                ],
              },
              {
                title: 'Inventario general',
                href: '/app/distribucion/consultas/inventariogeneral',
                permission: [
                  'app:general:distribucion:consultas:inventariogeneral:existencias:view',
                  'app:general:distribucion:consultas:inventariogeneral:activosfijos:view',
                ],
                items: [
                  {
                    title: 'Existencias',
                    href:
                      '/app/distribucion/consultas/inventariogeneral/existencias',
                    permission: [
                      'app:general:distribucion:consultas:inventariogeneral:existencias:view',
                    ],
                  },
                  {
                    title: 'Activos fijos',
                    href:
                      '/app/distribucion/consultas/inventariogeneral/activofijo',
                    permission: [
                      'app:general:distribucion:consultas:inventariogeneral:activosfijos:view',
                    ],
                  },
                ],
              },
              {
                title: 'Materia prima',
                href: '/app/distribucion/consultas/materiaprima',
                permission: [
                  'app:general:distribucion:materiaprima:view',
                ],
                items: [
                  {
                    title: 'Existencias',
                    href:
                      '/app/distribucion/consultas/materiaprima/existencias',
                    permission: [
                      'scm:inventario:inventariogeneral:consulta:existencia:list',
                    ],
                  },
                ],
              },
              {
                title: 'Producto terminado',
                href: '/app/distribucion/consultas/productoterminado',
                permission: [
                  'app:general:distribucion:consultas:productoterminado:existencias:view',
                  'app:general:distribucion:consultas:productoterminado:inventariovalorizable:view',
                  'app:general:distribucion:consultas:productoterminado:reservas:view',
                  'app:general:distribucion:consultas:productoterminado:semaforo:view',
                  'app:general:distribucion:consultas:productoterminado:mercanciasentransito:view',
                  'app:general:distribucion:consultas:productoterminado:kardex:view',
                  'app:general:distribucion:consultas:productoterminado:kardexdetalle:view',
                ],
                items: [
                  {
                    title: 'Existencias',
                    href:
                      '/app/distribucion/consultas/productoterminado/existencias',
                    permission: [
                      'app:general:distribucion:consultas:productoterminado:existencias:view',
                    ],
                  },
                  {
                    title: 'Inventario valorizable',
                    href:
                      '/app/distribucion/consultas/productoterminado/inventariovalorizable',
                    permission: [
                      'app:general:distribucion:consultas:productoterminado:inventariovalorizable:view',
                    ],
                  },
                  {
                    title: 'Reservas',
                    href:
                      '/app/distribucion/consultas/productoterminado/reservas',
                    permission: [
                      'app:general:distribucion:consultas:productoterminado:reservas:view',
                    ],
                  },
                  {
                    title: 'Semáforo',
                    href:
                      '/app/distribucion/consultas/productoterminado/semaforo',
                    permission: [
                      'app:general:distribucion:consultas:productoterminado:semaforo:view',
                    ],
                  },
                  {
                    title: 'Mercancías en tránsito',
                    href:
                      '/app/distribucion/consultas/productoterminado/mercanciaentransito',
                    permission: [
                      'app:general:distribucion:consultas:productoterminado:mercanciasentransito:view',
                    ],
                  },
                  {
                    title: 'Kardex',
                    href:
                      '/app/distribucion/consultas/productoterminado/kardex/PT',
                    permission: [
                      'app:general:distribucion:consultas:productoterminado:kardex:view',
                    ],
                  },
                  {
                    title: 'Kardex - Detalle',
                    href:
                      '/app/distribucion/consultas/productoterminado/kardexdetalle',
                    permission: [
                      'app:general:distribucion:consultas:productoterminado:kardexdetalle:view',
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    items: [
      {
        title: 'Logística',
        href: '/app/logistica',
        icon: TruckIcon,
        permission: [
          'app:general:logistica:solicitudeslogisticas:view',
          'app:general:logistica:logisticaentrada:recepcionmercancia:view',
          'app:general:logistica:logisticasalida:programacionalistamiento:view',
          'app:general:logistica:logisticasalida:alistamiento:view',
          'app:general:logistica:logisticasalida:despachos:view',
          'app:general:logistica:logisticasalida:hojasderuta:view',
        ],
        items: [
          {
            title: 'Solicitudes logísticas',
            href: '/app/logistica/solicitudeslogisticas',
            permission: ['app:general:logistica:solicitudeslogisticas:view'],
            info: () => (
              <IconButtonBase
                url="/app/logistica/solicitudeslogisticas/new"
                permission={['app:general:logistica:solicitudeslogisticas:new']}
              />
            ),
          },
          {
            title: 'Transporte terceros',
            href: '/app/logistica/transporteterceros',
            permission: ['app:general:logistica:transporteterceros:view'],
          },
          {
            title: 'Logística de entrada',
            href: '/app/logistica/logisticaentrada',
            items: [
              {
                title: 'Recepción mercancía',
                href: '/app/logistica/logisticaentrada/recepcionmercancia',
                permission: ['app:general:logistica:logisticaentrada:recepcionmercancia:view'],
              },
            ],
          },
          {
            title: 'Logística de salida',
            href: '/app/logistica/logisticasalida',
            items: [
              {
                title: 'Programaciones alistamiento',
                href: '/app/logistica/logisticasalida/programacionalistamiento',
                permission: ['app:general:logistica:logisticasalida:programacionalistamiento:view'],
              },
              {
                title: 'Alistamiento',
                href: '/app/logistica/logisticasalida/alistamiento',
                permission: ['app:general:logistica:logisticasalida:alistamiento:view'],
              },
              {
                title: 'Despachos',
                href: '/app/logistica/logisticasalida/despachos',
                permission: ['app:general:logistica:logisticasalida:despachos:view'],
              },
              {
                title: 'Hojas de rutas',
                href: '/app/logistica/logisticasalida/hojasderutas',
                permission: ['app:general:logistica:logisticasalida:hojasderuta:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/logistica/logisticasalida/hojasderutas/new"
                    permission={['app:general:logistica:logisticasalida:hojasderuta:new']}
                  />
                ),
              },
            ],
          },
          {
            title: 'Consultas',
            href: '/app/logistica/consultas',
            items: [],
          },
        ],
      },
    ],
  },
  {
    items: [
      {
        title: 'Contabilidad',
        href: '/app/contabilidad',
        permission: [
          'app:general:contabilidad:otroscomprobantescontables:view',
          'app:general:contabilidad:reportescontables:estadoderesultados:view',
          'app:general:contabilidad:reportescontables:balancegeneral:view',
          'app:general:contabilidad:reportescontables:balancedepruebas:view',
          'app:general:contabilidad:reportescontables:balancedepruebasportercero:view',
          'app:general:contabilidad:reportescontables:reportediariodeventas:view',
          'app:general:contabilidad:reportescontables:impuestosyretenciones:view',
          'app:general:contabilidad:reportescontables:librodiario:view',
          'app:general:contabilidad:reportescontables:auxiliarportercero:view',
          'app:general:contabilidad:reportescontables:libromayor:view',
          'app:general:contabilidad:reportescontables:industriaycomercio:view',
          'app:general:contabilidad:reportescontables:supersociedades:view',
          'app:general:contabilidad:reportescontables:dane:view',
          'app:general:contabilidad:reportescontables:pruebaacida:view',
          'app:general:contabilidad:reportescontables:consultaroi:view',
          'app:general:contabilidad:reportescontables:consultarentabilidadbruto:view',
          'app:general:contabilidad:niif:catalogodecuentasniif:view',
          'app:general:contabilidad:niif:estadosfinancieros:estadoderesultadosroi:view',
          'app:general:contabilidad:niif:estadosfinancieros:estadodesituacionfinanciera:view',
          'app:general:contabilidad:niif:estadosfinancieros:estadodecambiosenelpatrimonio:view',
          'app:general:contabilidad:niif:estadosfinancieros:estadodeflujosdeefectivo:view',
          'app:general:contabilidad:niif:estadosfinancieros:notasalosestadosfinancieros:view',
          'app:general:contabilidad:niif:informacionexogena:view',
          'app:general:contabilidad:niif:certificadosderetencion:view',
        ],
        icon: TrendingUpIcon,
        items: [
          {
            title: 'Otros comprobantes contables',
            href: '/app/contabilidad/comprobantescontables',
            permission: ['app:general:contabilidad:otroscomprobantescontables:view'],
          },
          // {
          //  title: 'Causación',
          //  href: '/app/contabilidad/comprobantescontables/causacion',
          // },
          // {
          //  title: 'Gastos recurrentes',
          //  href: '/app/contabilidad/comprobantescontables/gastosrecurrentes',
          // },
          // {
          //   title: 'Certificados de retención',
          //   href: '/app/contabilidad/certificadosretencion',
          // },
          {
            title: 'Forward',
            href: '/app/contabilidad/comprobantescontables/forward',
            permission: ['contabilidad:comprobantescontables:forward:list'],
            info: () => (
              <RouterLink to="/app/contabilidad/comprobantescontables/forward/new">
                <IconButton>
                  <PlusIcon />
                </IconButton>
              </RouterLink>
            ),
          },
          {
            title: 'Reportes contables',
            href: '/app/contabilidad/reportescontables',
            items: [
              {
                title: 'Estado de resultados',
                href: '/app/contabilidad/reportescontables/estadoresultados',
                permission: ['app:general:contabilidad:reportescontables:estadoderesultados:view'],
              },
              {
                title: 'Balance general',
                href: '/app/contabilidad/reportescontables/balancegeneral',
                permission: ['app:general:contabilidad:reportescontables:balancegeneral:view'],
              },
              {
                title: 'Balance de pruebas',
                href: '/app/contabilidad/reportescontables/balanceprueba',
                permission: ['app:general:contabilidad:reportescontables:balancedepruebas:view'],
              },
              {
                title: 'Balance de pruebas por tercero',
                href:
                  '/app/contabilidad/reportescontables/balancepruebatercero',
                permission: ['app:general:contabilidad:reportescontables:balancedepruebasportercero:view'],
              },
              {
                title: 'Reporte diario de ventas',
                href: '/app/contabilidad/reportescontables/reportediarioventas',
                permission: ['app:general:contabilidad:reportescontables:reportediariodeventas:view'],
              },
              {
                title: 'Impuestos y retenciones',
                href:
                  '/app/contabilidad/reportescontables/impuestosretenciones',
                permission: ['app:general:contabilidad:reportescontables:impuestosyretenciones:view'],
              },
              {
                title: 'Movimientos por cuenta contable',
                href: '/app/contabilidad/reportescontables/movimientocontable',
                permission: [
                  'contabilidad:reportescontables:movimientoscontables:list',
                ],
              },
              {
                title: 'Libro diario',
                href: '/app/contabilidad/reportescontables/librodiario',
                permission: ['app:general:contabilidad:reportescontables:librodiario:view'],
              },
              {
                title: 'Auxiliar por tercero',
                href: '/app/contabilidad/reportescontables/auxiliartercero',
                permission: ['app:general:contabilidad:reportescontables:auxiliarportercero:view'],
              },
              {
                title: 'Libro mayor',
                href: '/app/contabilidad/reportescontables/libromayor',
                permission: ['app:general:contabilidad:reportescontables:libromayor:view'],
              },
              {
                title: 'Industria y comercio',
                href: '/app/contabilidad/reportescontables/industriacomercio',
                permission: ['app:general:contabilidad:reportescontables:industriaycomercio:view'],
              },
              {
                title: 'Supersociedades',
                href: '/app/contabilidad/reportescontables/supersociedades',
                permission: ['app:general:contabilidad:reportescontables:supersociedades:view'],
              },
              {
                title: 'DANE',
                href: '/app/contabilidad/reportescontables/dane',
                permission: ['app:general:contabilidad:reportescontables:dane:view'],
              },
              {
                title: 'Prueba acida',
                href: '/app/contabilidad/reportescontables/pruebaacida',
                permission: ['app:general:contabilidad:reportescontables:pruebaacida:view'],
              },
              {
                title: 'Consulta ROI',
                href: '/app/contabilidad/reportescontables/informeroi',
                permission: ['app:general:contabilidad:reportescontables:consultaroi:view'],
              },
              {
                title: 'Consulta rentabilidad bruto',
                href:
                  '/app/contabilidad/reportescontables/informerentabilidadbruto',
                permission: ['app:general:contabilidad:reportescontables:consultarentabilidadbruto:view'],
              },
            ],
          },
          {
            title: 'NIIF',
            href: '/app/contabilidad/niif',
            items: [
              {
                title: 'Catálogo de cuentas NIIF',
                href: '/app/contabilidad/niif/catalogocuentasniif',
                permission: ['app:general:contabilidad:niif:catalogodecuentasniif:view'],
              },
              {
                title: 'Estados financieros',
                href: '/app/contabilidad/niif/estadosfinancieros',
                items: [
                  {
                    title: 'Estado de resultados ROI',
                    href:
                      '/app/contabilidad/niif/estadosfinancieros/estadoresultados',
                    permission: ['app:general:contabilidad:niif:'
                      + 'estadosfinancieros:estadoderesultadosroi:view'],
                  },
                  {
                    title: 'Estado de situación financiera',
                    href:
                      '/app/contabilidad/niif/estadosfinancieros/estadosituacionfinanciera',
                    permission: ['app:general:contabilidad:niif:estadosfinancieros:estadodesituacionfinanciera:view'],
                  },
                  {
                    title: 'Estado de cambios en el patrimonio',
                    href:
                      '/app/contabilidad/niif/estadosfinancieros/estadocambiospatrimonio',
                    permission: ['app:general:contabilidad:niif:estadosfinancieros:estadodecambiosenelpatrimonio:view'],
                  },
                  {
                    title: 'Estado de flujos de efectivo',
                    href:
                      '/app/contabilidad/niif/estadosfinancieros/estadoflujosefectivo',
                    permission: ['app:general:contabilidad:niif:estadosfinancieros:estadodeflujosdeefectivo:view'],
                  },
                  {
                    title: 'Notas a los estados financieros',
                    href:
                      '/app/contabilidad/niif/estadosfinancieros/notaestadosfinancieros',
                    permission: ['app:general:contabilidad:niif:estadosfinancieros:notasalosestadosfinancieros:view'],
                  },
                ],
              },
              {
                title: 'Información exógena',
                href: '/app/contabilidad/niif/informacionexogenia',
                permission: ['app:general:contabilidad:niif:informacionexogena:view'],
              },
              {
                title: 'Certificados de retención',
                href: '/app/contabilidad/niif/certificadosretencion',
                permission: ['app:general:contabilidad:niif:certificadosderetencion:view'],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    items: [
      {
        title: 'Tesorería',
        href: '/app/tesoreria',
        icon: DollarSignIcon,
        permission: [
          'app:general:tesoreria:view',
          'app:general:tesoreria:comprobantesingresosegresos:view',
        ],
        items: [
          {
            title: 'Comprobantes ingresos - egresos',
            href: '/app/tesoreria/comprobantesingresosegresos',
            permission: ['app:general:tesoreria:comprobantesingresosegresos:view'],
          },
          {
            title: 'Conciliación bancaria',
            href: '/app/tesoreria/conciliacionbancaria',
          },
          {
            title: 'Pagos recurrentes',
            href: '/app/tesoreria/pagosrecurrentes',
          },
          // {
          //  title: 'Caja menor',
          //  href: '/app/contabilidad/tesoreria/cajamenor',
          // },
          // {
          //  title: 'Pagos',
          //  href: '/app/tesoreria/pagos',
          //  permission: ['contabilidad:tesoreria:pagos:list'],
          //  info: () => (
          //    <RouterLink to="/app/tesoreria/pagos/new">
          //      <IconButton>
          //        <PlusIcon />
          //       </IconButton>
          //    </RouterLink>
          //  ),
          // },
          // {
          //  title: 'Programación de pagos',
          //  href: '/app/tesoreria/programacionpago',
          //  permission: ['contabilidad:tesoreria:programacionpago:list'],
          //  info: () => (
          //    <RouterLink to="/app/tesoreria/programacionpago/new">
          //      <IconButton>
          //        <PlusIcon />
          //      </IconButton>
          //    </RouterLink>
          //  ),
          // },
          {
            title: 'Flujo de caja',
            href: '/app/contabilidad/tesoreria/flujodecaja',
          },
          {
            title: 'Consignaciones por identificar',
            href: '/app/tesoreria/consignacionesporidentificar',
          },
          {
            title: 'Cuentas por cobrar',
            href: '/app/tesoreria/cuentascobrar',
          },
          {
            title: 'Cuentas por pagar',
            href: '/app/tesoreria/cuentaspagar',
          },
          {
            title: 'Caja diario',
            href: '/app/contabilidad/tesoreria/cajadiario',
          },
          {
            title: 'Diario general',
            href: '/app/contabilidad/tesoreria/diariogeneral',
          },
          {
            title: 'Diario legal',
            href: '/app/contabilidad/tesoreria/diariolegal',
          },
          {
            title: 'Documentos recibidos',
            href: '/app/contabilidad/tesoreria/documentosrecibidos',
          },
          {
            title: 'Transacciones financieras',
            href: '/app/tesoreria/transaccionesfinancieras',
            permission: ['contabilidad:tesoreria:documentofinanciero:list'],
            info: () => (
              <RouterLink to="/app/tesoreria/transaccionesfinancieras/new">
                <IconButton>
                  <PlusIcon />
                </IconButton>
              </RouterLink>
            ),
          },
        ],
      },
    ],
  },
  {
    items: [
      {
        title: 'Gestión activos fijos',
        href: '/app/gestionactivosfijos',
        icon: MonitorIcon,
        permission: [
          'app:general:gestionactivosfijos:administracionactivosfijos:view',
          'app:general:gestionactivosfijos:conceptosmovimientoactivos:view',
          'app:general:gestionactivosfijos:mantenimientos:view',
        ],
        items: [
          {
            title: 'Administración de activos fijos',
            href: '/app/gestionactivosfijos/administracionactivosfijos',
            permission: ['app:general:gestionactivosfijos:administracionactivosfijos:view'],
          },
          {
            title: 'Conceptos movimiento activos',
            href: '/app/gestionactivosfijos/conceptosmovimientoactivos',
            permission: ['app:general:gestionactivosfijos:conceptosmovimientoactivos:view'],
            info: () => (
              <IconButtonBase
                url="/app/gestionactivosfijos/conceptosmovimientoactivos/new"
                permission={['app:general:gestionactivosfijos:conceptosmovimientoactivos:new']}
              />
            ),
          },
          {
            title: 'Mantenimientos',
            href: '/app/gestionactivosfijos/mantenimientos',
            permission: ['app:general:gestionactivosfijos:mantenimientos:view'],
            info: () => (
              <IconButtonBase
                url="/app/gestionactivosfijos/mantenimientos/new"
                permission={['app:general:gestionactivosfijos:mantenimientos:new']}
              />
            ),
          },
          {
            title: 'Consultas',
            href: '/app/gestionactivosfijos/consultas',
            items: [],
          },
        ],
      },
    ],
  },
  {
    items: [
      {
        title: 'Inteligencia de negocio (B.I)',
        href: '/app/inteligenciadenegocio',
        icon: SlackIcon,
        permission: [
          'app:general:inteligenciadenegocio:view'
        ],
        items: [
          {
            title: 'Inventario',
            href: '/app/inteligenciadenegocio/inventario',
            items: [
              {
                title: 'Punto de reorden',
                href: '/app/inteligenciadenegocio/inventario/puntoreorden',
                // permission: ['bi:inventario:puntoreorden:list'],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    items: [
      {
        title: 'Gestión financiera',
        href: '/app/gestionfinanciera',
        icon: CommandIcon,
        permission: ['app:general:gestionfinanciera:view'],
        items: [
          {
            title: 'Consultas',
            href: '/app/gestionfinanciera/consultas',
            items: [],
          },
        ],
      },
    ],
  },
  {
    items: [
      {
        title: 'Recursos Humanos (RRHH)',
        href: '/app/recursoshumanos',
        icon: HrmIcon,
        permission: [
          'app:general:recursoshumanos:gestiondeltalento:solicitudrecursoshumanos:view',
          'app:general:recursoshumanos:gestiondeltalento:evaluacioncandidatos:view',
          'app:general:recursoshumanos:gestiondeltalento:programacionesgestiondeltalento:view',
          'app:general:recursoshumanos:seguimientoyretencion:ordenaprovisionamiento:view',
          'app:general:recursoshumanos:seguimientoyretencion:programarcapacitaciones:view',
          'app:general:recursoshumanos:seguimientoyretencion:evaluarcapacitaciones:view',
          'app:general:recursoshumanos:seguimientoyretencion:programacionevaluaciondesempeno:view',
          'app:general:recursoshumanos:seguimientoyretencion:evaluaciondesempeno:view',
          'app:general:recursoshumanos:seguimientoyretencion:plandesempenoindividual:view',
          'app:general:recursoshumanos:procesosdisciplinarios:procesodisciplinario:view',
          'app:general:recursoshumanos:bienestarlaboral:solicitudesbeneficios:view',
          'app:general:recursoshumanos:bienestarlaboral:bonificaciones:view',
          'app:general:recursoshumanos:gestiondeltiempo:tareas:asignaciondetareas:view',
          'app:general:recursoshumanos:gestiondeltiempo:tareas:gestiondetareas:view',
          'app:general:recursoshumanos:gestiondeltiempo:gestiondeltiempo:programacionturnos:view',
          'app:general:recursoshumanos:gestiondeltiempo:gestiondeltiempo:reportedetiempo:view',
          'app:general:recursoshumanos:gestiondeltiempo:gestiondeltiempo:solicitudmodalidaddetrabajo:view',
          'app:general:recursoshumanos:gestiondeltiempo:gestiondeltiempo:reportehorasextras:view',
          'app:general:recursoshumanos:gestiondeltiempo:ausencias:solicitudespermisos:view',
          'app:general:recursoshumanos:gestiondeltiempo:ausencias:solicitudesvacaciones:view',
          'app:general:recursoshumanos:gestiondeltiempo:ausencias:incapacidadesmedicas:view',
          'app:general:recursoshumanos:gestiondeltiempo:ausencias:registrolicencias:view',
          'app:general:recursoshumanos:nomina:liquidaciones:liquidacionvacaciones:view',
          'app:general:recursoshumanos:nomina:liquidaciones:liquidacionprimadeservicios:view',
          'app:general:recursoshumanos:nomina:liquidaciones:liquidacionauxiliocesantias:view',
          'app:general:recursoshumanos:nomina:liquidaciones:liquidacioninterescesantias:view',
          'app:general:recursoshumanos:nomina:liquidaciones:comprobantesnomina:view',
          'app:general:recursoshumanos:gestiondeacceso:accesoempleados:view',
          'app:general:recursoshumanos:consultas:gestiondeltalento:vacantesempleo:view',
          'app:general:recursoshumanos:consultas:gestiondeltalento:solicitudrecursoshumanos:view',
          'app:general:recursoshumanos:saludyseguridadeneltrabajo:view',
        ],
        items: [
          {
            title: 'Gestión del talento',
            href: '/app/recursoshumanos/gestiondeltalento',
            permission: [
              'app:general:recursoshumanos:gestiondeltalento:solicitudrecursoshumanos:view',
              'app:general:recursoshumanos:gestiondeltalento:evaluacioncandidatos:view',
              'app:general:recursoshumanos:gestiondeltalento:programacionesgestiondeltalento:view',
            ],
            items: [
              {
                title: 'Solicitud recursos humanos',
                href:
                  '/app/recursoshumanos/gestiondeltalento/solicitudrecursoshumanos',
                permission: [
                  'app:general:recursoshumanos:gestiondeltalento:solicitudrecursoshumanos:view',
                ],
                info: () => (
                  <IconButtonBase
                    url="/app/recursoshumanos/gestiondeltalento/solicitudrecursoshumanos/new"
                    permission={[
                      'app:general:recursoshumanos:gestiondeltalento:solicitudrecursoshumanos:new',
                    ]}
                  />
                ),
              },
              {
                title: 'Evaluación de candidatos',
                href:
                  '/app/recursoshumanos/gestiondeltalento/evaluacioncandidatos',
                permission: ['app:general:recursoshumanos:gestiondeltalento:evaluacioncandidatos:view'],
              },
              {
                title: 'Programaciones gestión del talento',
                href:
                  '/app/recursoshumanos/gestiondeltalento/programacionesgestiondeltalento',
                permission: ['app:general:recursoshumanos:gestiondeltalento:programacionesgestiondeltalento:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/recursoshumanos/gestiondeltalento/programacionesgestiondeltalento/new"
                    permission={[
                      'app:general:recursoshumanos:gestiondeltalento:programacionesgestiondeltalento:new',
                    ]}
                  />
                ),
              },
            ],
          },
          {
            title: 'Seguimiento y retención',
            href: '/app/recursoshumanos/seguimientoyretencion',
            permission: [
              'app:general:recursoshumanos:seguimientoyretencion:ordenaprovisionamiento:view',
              'app:general:recursoshumanos:seguimientoyretencion:programarcapacitaciones:view',
              'app:general:recursoshumanos:seguimientoyretencion:evaluarcapacitaciones:view',
              'app:general:recursoshumanos:seguimientoyretencion:programacionevaluaciondesempeno:view',
              'app:general:recursoshumanos:seguimientoyretencion:evaluaciondesempeno:view',
              'app:general:recursoshumanos:seguimientoyretencion:plandesempenoindividual:view',
            ],
            items: [
              {
                title: 'Orden aprovisionamiento',
                href:
                  '/app/recursoshumanos/seguimientoyretencion/ordenaprovisionamiento',
                permission: ['app:general:recursoshumanos:seguimientoyretencion:ordenaprovisionamiento:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/recursoshumanos/seguimientoyretencion/ordenaprovisionamiento/new"
                    permission={['app:general:recursoshumanos:seguimientoyretencion:ordenaprovisionamiento:new']}
                  />
                ),
              },
              {
                title: 'Programar capacitaciones',
                href:
                  '/app/recursoshumanos/seguimientoyretencion/programarcapacitaciones',
                permission: ['app:general:recursoshumanos:seguimientoyretencion:programarcapacitaciones:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/recursoshumanos/seguimientoyretencion/programarcapacitaciones/new"
                    permission={[
                      'app:general:recursoshumanos:seguimientoyretencion:programarcapacitaciones:new',
                    ]}
                  />
                ),
              },
              {
                title: 'Evaluar capacitaciones',
                href:
                  '/app/recursoshumanos/seguimientoyretencion/evaluarcapacitaciones',
                permission: ['app:general:recursoshumanos:seguimientoyretencion:evaluarcapacitaciones:view'],
              },
              {
                title: 'Programación evaluación desempeño',
                href:
                  '/app/recursoshumanos/seguimientoyretencion/programacionevaluaciondesempeno',
                permission: [
                  'app:general:recursoshumanos:seguimientoyretencion:programacionevaluaciondesempeno:view',
                ],
                info: () => (
                  <IconButtonBase
                    url="/app/recursoshumanos/seguimientoyretencion/programacionevaluaciondesempeno/new"
                    permission={[
                      'app:general:recursoshumanos:seguimientoyretencion:programacionevaluaciondesempeno:new',
                    ]}
                  />
                ),
              },
              {
                title: 'evaluación desempeño',
                href:
                  '/app/recursoshumanos/seguimientoyretencion/evaluaciondesempeno',
                permission: [
                  'app:general:recursoshumanos:seguimientoyretencion:evaluaciondesempeno:view',
                ],
              },
              {
                title: 'plan desempeño individual',
                href:
                  '/app/recursoshumanos/seguimientoyretencion/plandesempenoindividual',
                permission: [
                  'app:general:recursoshumanos:seguimientoyretencion:plandesempenoindividual:view',
                ],
              },
            ],
          },
          {
            title: 'Procesos disciplinarios',
            href: '/app/recursoshumanos/procesosdisciplinarios',
            permission: [
              'app:general:recursoshumanos:procesosdisciplinarios:procesodisciplinario:view',
            ],
            items: [
              {
                title: 'Proceso disciplinario',
                href:
                  '/app/recursoshumanos/procesosdisciplinarios/procesodisciplinario',
                permission: [
                  'app:general:recursoshumanos:procesosdisciplinarios:procesodisciplinario:view',
                ],
                info: () => (
                  <IconButtonBase
                    url="/app/recursoshumanos/procesosdisciplinarios/procesodisciplinario/new"
                    permission={[
                      'app:general:recursoshumanos:procesosdisciplinarios:procesodisciplinario:new',
                    ]}
                  />
                ),
              },
              /*
              {
                title: 'llamados atención',
                href: '/app/rrhh/seguimientoyretencion/procesosdisciplinarios/llamadosatencion',
                permission: ['rrhh:seguimientoyretencion:llamadoatencion:list']
              },
              {
                title: 'descargos',
                href: '/app/rrhh/seguimientoyretencion/procesosdisciplinarios/descargos',
                permission: ['rrhh:seguimientoyretencion:descargos:list']
              },
              {
                title: 'suspenciones',
                href: '/app/rrhh/seguimientoyretencion/procesosdisciplinarios/suspenciones',
                permission: ['rrhh:seguimientoyretencion:suspenciones:list']
              },
              {
                href: '/app/rrhh/seguimientoyretencion/procesosdiciplinarios/llamadosatencion',
                permission: ['rrhh:seguimientoyretencion:llamadoatencion:list'],
                info: () => (
                  <IconButtonBase
                    url="/app/rrhh/seguimientoyretencion/
                    procesosdiciplinarios/llamadosatencion/new"
                    permission={['rrhh:seguimientoyretencion:llamadoatencion:create']}
                  />
                ),
              },
              {
                title: 'descargos',
                href: '/app/rrhh/seguimientoyretencion/procesosdiciplinarios/descargos',
                permission: ['rrhh:seguimientoyretencion:descargos:list'],
                info: () => (
                  <IconButtonBase
                    url="/app/rrhh/seguimientoyretencion/procesosdiciplinarios/descargos/new"
                    permission={['rrhh:seguimientoyretencion:descargos:create']}
                  />
                ),
              },
              {
                title: 'suspenciones',
                href: '/app/rrhh/seguimientoyretencion/procesosdiciplinarios/suspenciones',
                permission: ['rrhh:seguimientoyretencion:suspenciones:list'],
                info: () => (
                  <IconButtonBase
                    url="/app/rrhh/seguimientoyretencion/procesosdiciplinarios/suspenciones/new"
                    permission={['rrhh:seguimientoyretencion:suspenciones:create']}
                  />
                ),
              }
              */
            ],
          },
          {
            title: 'Bienestar laboral',
            href: '/app/recursoshumanos/bienestarlaboral',
            permission: [
              'app:general:recursoshumanos:bienestarlaboral:solicitudesbeneficios:view',
              'app:general:recursoshumanos:bienestarlaboral:bonificaciones:view',
            ],
            items: [
              {
                title: 'Solicitudes beneficios',
                href:
                  '/app/recursoshumanos/bienestarlaboral/solicitudesbeneficios',
                permission: ['app:general:recursoshumanos:bienestarlaboral:solicitudesbeneficios:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/recursoshumanos/bienestarlaboral/solicitudesbeneficios/new"
                    permission={['app:general:recursoshumanos:bienestarlaboral:solicitudesbeneficios:new']}
                  />
                ),
              },
              {
                title: 'Bonificaciones',
                href: '/app/recursoshumanos/bienestarlaboral/bonificaciones',
                permission: ['app:general:recursoshumanos:bienestarlaboral:bonificaciones:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/recursoshumanos/bienestarlaboral/bonificaciones/new"
                    permission={['app:general:recursoshumanos:bienestarlaboral:bonificaciones:new']}
                  />
                ),
              },
            ],
          },
          {
            title: 'Gestión del tiempo',
            href: '/app/recursoshumanos/gestiondeltiempo',
            permission: [
              'app:general:recursoshumanos:gestiondeltiempo:tareas:asignaciondetareas:view',
              'app:general:recursoshumanos:gestiondeltiempo:tareas:gestiondetareas:view',
              'app:general:recursoshumanos:gestiondeltiempo:gestiondeltiempo:programacionturnos:view',
              'app:general:recursoshumanos:gestiondeltiempo:gestiondeltiempo:reportedetiempo:view',
              'app:general:recursoshumanos:gestiondeltiempo:gestiondeltiempo:solicitudmodalidaddetrabajo:view',
              'app:general:recursoshumanos:gestiondeltiempo:gestiondeltiempo:reportehorasextras:view',
              'app:general:recursoshumanos:gestiondeltiempo:ausencias:solicitudespermisos:view',
              'app:general:recursoshumanos:gestiondeltiempo:ausencias:solicitudesvacaciones:view',
              'app:general:recursoshumanos:gestiondeltiempo:ausencias:incapacidadesmedicas:view',
              'app:general:recursoshumanos:gestiondeltiempo:ausencias:registrolicencias:view',
            ],
            items: [
              {
                title: 'Tareas',
                href: '/app/recursoshumanos/gestiondeltiempo/tareas',
                permission: [
                  'app:general:recursoshumanos:gestiondeltiempo:tareas:asignaciondetareas:view',
                ],
                items: [
                  {
                    title: 'Asignación de Tareas',
                    href:
                      '/app/recursoshumanos/gestiondeltiempo/tareas/asignaciondetareas',
                    permission: [
                      'app:general:recursoshumanos:gestiondeltiempo:tareas:asignaciondetareas:view',
                    ],
                    info: () => (
                      <IconButtonBase
                        url="/app/recursoshumanos/gestiondeltiempo/tareas/asignaciondetareas/new"
                        permission={[
                          'app:general:recursoshumanos:gestiondeltiempo:tareas:asignaciondetareas:new',
                        ]}
                      />
                    ),
                  },
                  {
                    title: 'Gestión de Tareas',
                    href:
                      '/app/recursoshumanos/gestiondeltiempo/tareas/gestiondetareas',
                    permission: [
                      'app:general:recursoshumanos:gestiondeltiempo:tareas:gestiondetareas:view',
                    ],
                  },
                ],
              },
              {
                title: 'Gestión del tiempo',
                href: '/app/recursoshumanos/gestiondeltiempo/gestiondeltiempo',
                permission: [
                  'app:general:recursoshumanos:gestiondeltiempo:gestiondeltiempo:programacionturnos:view',
                  'app:general:recursoshumanos:gestiondeltiempo:gestiondeltiempo:reportedetiempo:view',
                  'app:general:recursoshumanos:gestiondeltiempo:gestiondeltiempo:solicitudmodalidaddetrabajo:view',
                  'app:general:recursoshumanos:gestiondeltiempo:gestiondeltiempo:reportehorasextras:view',
                ],
                items: [
                  {
                    title: 'Programación turnos',
                    href:
                      '/app/recursoshumanos/gestiondeltiempo/gestiondeltiempo/programacionturnos',
                    permission: [
                      'app:general:recursoshumanos:gestiondeltiempo:gestiondeltiempo:programacionturnos:view',
                    ],
                    info: () => (
                      <IconButtonBase
                        url="/app/recursoshumanos/gestiondeltiempo/gestiondeltiempo/programacionturnos/new"
                        permission={[
                          'app:general:recursoshumanos:gestiondeltiempo:gestiondeltiempo:programacionturnos:new',
                        ]}
                      />
                    ),
                  },
                  {
                    title: 'Reporte de tiempo',
                    href:
                      '/app/recursoshumanos/gestiondeltiempo/gestiondeltiempo/reportedetiempo',
                    permission: [
                      'app:general:recursoshumanos:gestiondeltiempo:gestiondeltiempo:reportedetiempo:view',
                    ],
                    info: () => (
                      <IconButtonBase
                        url="/app/recursoshumanos/gestiondeltiempo/gestiondeltiempo/reportedetiempo/new"
                        permission={[
                          'app:general:recursoshumanos:gestiondeltiempo:gestiondeltiempo:reportedetiempo:new',
                        ]}
                      />
                    ),
                  },
                  {
                    title: 'Solicitud modalidad de trabajo',
                    href:
                      '/app/recursoshumanos/gestiondeltiempo/gestiondeltiempo/solicitudmodalidadtrabajo',
                    permission: [
                      'app:general:recursoshumanos:gestiondeltiempo:gestiondeltiempo:solicitudmodalidaddetrabajo:view',
                    ],
                    info: () => (
                      <IconButtonBase
                        url="/app/recursoshumanos/gestiondeltiempo/gestiondeltiempo/solicitudmodalidadtrabajo/new"
                        permission={[
                          'app:general:recursoshumanos:gestiondeltiempo:'
                          + 'gestiondeltiempo:solicitudmodalidaddetrabajo:new',
                        ]}
                      />
                    ),
                  },
                  {
                    title: 'Reporte horas extras',
                    href:
                      '/app/recursoshumanos/gestiondeltiempo/gestiondeltiempo/reportehorasextras',
                    permission: ['app:general:recursoshumanos:'
                      + 'gestiondeltiempo:gestiondeltiempo:reportehorasextras:view'],
                    info: () => (
                      <IconButtonBase
                        url="/app/recursoshumanos/gestiondeltiempo/gestiondeltiempo/reportehorasextras/new"
                        permission={['app:general:recursoshumanos:'
                          + 'gestiondeltiempo:gestiondeltiempo:reportehorasextras:new']}
                      />
                    ),
                  },
                ],
              },
              {
                title: 'Ausencias',
                href: '/app/recursoshumanos/gestiondeltiempo/ausencias',
                permission: [
                  'app:general:recursoshumanos:gestiondeltiempo:ausencias:solicitudespermisos:view',
                  'app:general:recursoshumanos:gestiondeltiempo:ausencias:solicitudesvacaciones:view',
                  'app:general:recursoshumanos:gestiondeltiempo:ausencias:incapacidadesmedicas:view',
                  'app:general:recursoshumanos:gestiondeltiempo:ausencias:registrolicencias:view',
                ],
                items: [
                  {
                    title: 'Solicitudes permisos',
                    href:
                      '/app/recursoshumanos/gestiondeltiempo/ausencias/solicitudespermisos',
                    permission: ['app:general:recursoshumanos:gestiondeltiempo:ausencias:solicitudespermisos:view'],
                    info: () => (
                      <IconButtonBase
                        url="/app/recursoshumanos/gestiondeltiempo/ausencias/solicitudespermisos/new"
                        permission={['app:general:recursoshumanos:gestiondeltiempo:ausencias:solicitudespermisos:new']}
                      />
                    ),
                  },
                  {
                    title: 'Solicitudes vacaciones',
                    href:
                      '/app/recursoshumanos/gestiondeltiempo/ausencias/solicitudesvacaciones',
                    permission: ['app:general:recursoshumanos:gestiondeltiempo:ausencias:solicitudesvacaciones:view'],
                    info: () => (
                      <IconButtonBase
                        url="/app/recursoshumanos/gestiondeltiempo/ausencias/solicitudesvacaciones/new"
                        permission={['app:general:recursoshumanos:gestiondeltiempo:'
                          + 'ausencias:solicitudesvacaciones:new']}
                      />
                    ),
                  },
                  {
                    title: 'Incapacidades médicas',
                    href:
                      '/app/recursoshumanos/gestiondeltiempo/ausencias/incapacidadesmedicas',
                    permission: ['app:general:recursoshumanos:gestiondeltiempo:ausencias:incapacidadesmedicas:view'],
                    info: () => (
                      <IconButtonBase
                        url="/app/recursoshumanos/gestiondeltiempo/ausencias/incapacidadesmedicas/new"
                        permission={[
                          'app:general:recursoshumanos:gestiondeltiempo:ausencias:incapacidadesmedicas:new',
                        ]}
                      />
                    ),
                  },
                  {
                    title: 'Registros licencias',
                    href:
                      '/app/recursoshumanos/gestiondeltiempo/ausencias/registroslicencias',
                    permission: ['app:general:recursoshumanos:gestiondeltiempo:ausencias:registrolicencias:view'],
                    info: () => (
                      <IconButtonBase
                        url="/app/recursoshumanos/gestiondeltiempo/ausencias/registroslicencias/new"
                        permission={[
                          'app:general:recursoshumanos:gestiondeltiempo:ausencias:registrolicencias:new',
                        ]}
                      />
                    ),
                  },
                ],
              },
            ],
          },
          {
            title: 'Nómina',
            href: '/app/recursoshumanos/nomina',
            permission: [
              'app:general:recursoshumanos:nomina:liquidaciones:liquidacionvacaciones:view',
              'app:general:recursoshumanos:nomina:liquidaciones:liquidacionprimadeservicios:view',
              'app:general:recursoshumanos:nomina:liquidaciones:liquidacionauxiliocesantias:view',
              'app:general:recursoshumanos:nomina:liquidaciones:liquidacioninterescesantias:view',
              'app:general:recursoshumanos:nomina:liquidaciones:comprobantesnomina:view',
            ],
            items: [
              {
                title: 'Liquidaciones',
                href: '/app/recursoshumanos/nomina/liquidaciones',
                permission: [
                  'app:general:recursoshumanos:nomina:liquidaciones:liquidacionvacaciones:view',
                  'app:general:recursoshumanos:nomina:liquidaciones:liquidacionprimadeservicios:view',
                  'app:general:recursoshumanos:nomina:liquidaciones:liquidacionauxiliocesantias:view',
                  'app:general:recursoshumanos:nomina:liquidaciones:liquidacioninterescesantias:view',
                ],
                items: [
                  {
                    title: 'Liquidación vacaciones',
                    href:
                      '/app/recursoshumanos/nomina/liquidaciones/liquidacionvacaciones',
                    permission: ['app:general:recursoshumanos:nomina:liquidaciones:liquidacionvacaciones:view'],
                    info: () => (
                      <IconButtonBase
                        url="/app/recursoshumanos/nomina/liquidaciones/liquidacionvacaciones/new"
                        permission={[
                          'app:general:recursoshumanos:nomina:liquidaciones:liquidacionvacaciones:new',
                        ]}
                      />
                    ),
                  },
                  {
                    title: 'Liquidación prima de servicios',
                    href:
                      '/app/recursoshumanos/nomina/liquidaciones/liquidacionprimadeservicios',
                    permission: ['app:general:recursoshumanos:nomina:liquidaciones:liquidacionprimadeservicios:view'],
                    info: () => (
                      <IconButtonBase
                        url="/app/recursoshumanos/nomina/liquidaciones/liquidacionprimadeservicios/new"
                        permission={['app:general:recursoshumanos:nomina:'
                          + 'liquidaciones:liquidacionprimadeservicios:new']}
                      />
                    ),
                  },
                  {
                    title: 'Liquidación auxilio cesantías',
                    href:
                      '/app/recursoshumanos/nomina/liquidaciones/liquidacionauxiliocesantias',
                    permission: ['app:general:recursoshumanos:nomina:liquidaciones:liquidacionauxiliocesantias:view'],
                    info: () => (
                      <IconButtonBase
                        url="/app/recursoshumanos/nomina/liquidaciones/liquidacionauxiliocesantias/new"
                        permission={['app:general:recursoshumanos:'
                          + 'nomina:liquidaciones:liquidacionauxiliocesantias:new']}
                      />
                    ),
                  },
                  {
                    title: 'Liquidación interés cesantías',
                    href:
                      '/app/recursoshumanos/nomina/liquidaciones/liquidacioninterescesantias',
                    permission: ['app:general:recursoshumanos:nomina:liquidaciones:liquidacioninterescesantias:view'],
                    info: () => (
                      <IconButtonBase
                        url="/app/recursoshumanos/nomina/liquidaciones/liquidacioninterescesantias/new"
                        permission={['app:general:recursoshumanos:nomina'
                          + ':liquidaciones:liquidacioninterescesantias:new']}
                      />
                    ),
                  },
                ],
              },
              {
                title: 'Comprobantes nómina',
                href: '/app/recursoshumanos/nomina/comprobantesnomina',
                permission: ['app:general:recursoshumanos:nomina:liquidaciones:comprobantesnomina:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/recursoshumanos/nomina/comprobantesnomina/new"
                    permission={[
                      'app:general:recursoshumanos:nomina:liquidaciones:comprobantesnomina:new',
                    ]}
                  />
                ),
              },
              {
                title: 'Colillas de pago',
                href: '/app/recursoshumanos/nomina/colillasdepago',
                // Falta por permisos
              },
            ],
          },
          {
            title: 'Certificados',
            href: '/app/recursoshumanos/certificados',
            items: [
              {
                title: 'Certificados laborales',
                href: '/app/recursoshumanos/certificados/certificadoslaborales',
                // Falta por permisos
              },
            ],
          },
          {
            title: 'Provisiones',
            href: '/app/recursoshumanos/provisiones',
            items: [
              {
                title: 'Provisiones',
                href: '/app/recursoshumanos/provisiones/provisiones',
                // Falta por permisos
              },
            ],
          },
          {
            title: 'Desvinculación laboral',
            href: '/app/recursoshumanos/desvinculacionlaboral',
            items: [
              {
                title: 'Desvinculación laboral',
                href:
                  '/app/recursoshumanos/desvinculacionlaboral/desvinculacionlaboral',
                // Falta por permisos
                info: () => (
                  <IconButtonBase
                    url="/app/recursoshumanos/desvinculacionlaboral/desvinculacionlaboral/new"
                  // Falta por permisos
                  />
                ),
              },
            ],
          },
          {
            title: 'SST',
            href: '/app/recursoshumanos/saludyseguridadeneltrabajo',
            permission: ['app:general:recursoshumanos:saludyseguridadeneltrabajo:view'],
            items: [
              {
                title: 'Identificación - Evaluación riesgos',
                href:
                  '/app/recursoshumanos/saludyseguridadeneltrabajo/identificacionevaluacionriesgos',
                // Falta por permisos
                info: () => (
                  <IconButtonBase url={'/app/recursoshumanos/saludyseguridadeneltrabajo'
                    + '/identificacionevaluacionriesgos/new'}
                  />
                ),
              },
              {
                title: 'Registro de accidentes laborales',
                href:
                  '/app/recursoshumanos/saludyseguridadeneltrabajo/registrodeaccidenteslaborales',
                // Falta por permisos
                info: () => (
                  <IconButtonBase url={'/app/recursoshumanos/saludyseguridadeneltrabajo/'
                    + 'registrodeaccidenteslaborales/new'}
                  />
                ),
              },
            ],
          },
          {
            title: 'Gestión de acceso',
            href: '/app/recursoshumanos/gestiondeacceso',
            permission: [
              'app:general:recursoshumanos:gestiondeacceso:accesoempleados:view',
            ],
            items: [
              {
                title: 'Accesos empleados',
                href: '/app/recursoshumanos/gestiondeacceso/accesosempleados',
                permission: ['app:general:recursoshumanos:gestiondeacceso:accesoempleados:view'],
              },
            ],
          },
          {
            title: 'Consultas',
            href: '/app/recursoshumanos/consultas',
            permission: [
              'app:general:recursoshumanos:consultas:gestiondeltalento:vacantesempleo:view',
              'app:general:recursoshumanos:consultas:gestiondeltalento:solicitudrecursoshumanos:view',
            ],
            items: [
              {
                title: 'Gestión del talento',
                href: '/app/recursoshumanos/consultas/gestiondeltalento',
                permission: [
                  'app:general:recursoshumanos:consultas:gestiondeltalento:vacantesempleo:view',
                  'app:general:recursoshumanos:consultas:gestiondeltalento:solicitudrecursoshumanos:view',
                ],
                items: [
                  {
                    title: 'Vacantes de empleo',
                    href:
                      '/app/recursoshumanos/consultas/gestiondeltalento/vacantesempleo',
                    permission: ['app:general:recursoshumanos:consultas:gestiondeltalento:vacantesempleo:view'],
                  },
                  {
                    title: 'Solicitud recursos humanos',
                    href:
                      '/app/recursoshumanos/consultas/gestiondeltalento/solicitudrecursoshumanos',
                    permission: ['app:general:recursoshumanos:consultas:'
                      + 'gestiondeltalento:solicitudrecursoshumanos:view'],
                  },
                  {
                    title: 'Evaluación de candidatos',
                    href:
                      '/app/recursoshumanos/consultas/gestiondeltalento/evaluacioncandidatos',
                    // Falta por permisos
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    items: [
      {
        title: 'Gobierno corporativo',
        href: '/app/gobiernocorporativo',
        icon: CodesandboxIcon,
        permission: [
          'app:general:gobiernocorporativo:gestionempresarial:gestionempresarial:view',
          'app:general:gobiernocorporativo:gestionempresarial:reglamentointernodetrabajo:view',
          'app:general:gobiernocorporativo:organigrama:view',
        ],
        items: [
          {
            title: 'Gestión empresarial',
            href: '/app/gobiernocorporativo/gestionempresarial',
            items: [
              {
                title: 'Gestión empresarial',
                href:
                  '/app/gobiernocorporativo/gestionempresarial/gestionempresarial',
                permission: ['app:general:gobiernocorporativo:gestionempresarial:gestionempresarial:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/gobiernocorporativo/gestionempresarial/gestionempresarial/new"
                    permission={[
                      'app:general:gobiernocorporativo:gestionempresarial:gestionempresarial:new',
                    ]}
                  />
                ),
              },
              {
                title: 'Reglamento interno de trabajo',
                href:
                  '/app/gobiernocorporativo/gestionempresarial/reglamentointernodetrabajo',
                permission: ['app:general:gobiernocorporativo:gestionempresarial:reglamentointernodetrabajo:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/gobiernocorporativo/gestionempresarial/reglamentointernodetrabajo/new"
                    permission={[
                      'app:general:gobiernocorporativo:gestionempresarial:reglamentointernodetrabajo:new',
                    ]}
                  />
                ),
              },
            ],
          },
          {
            title: 'Organigrama',
            href: '/app/gobiernocorporativo/organigrama',
            permission: ['app:general:gobiernocorporativo:organigrama:view'],
          },
          {
            title: 'Consultas',
            href: '/app/gobiernocorporativo/consultas',
            items: [],
          },
          {
            title: 'Calidad',
            href: '/app/gobiernocorporativo/calidad',
            items: [
              {
                title: 'Consultas',
                href: '/app/gobiernocorporativo/calidad/consultas',
                items: [],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    items: [
      {
        title: 'Gestión documental (ECM)',
        href: '/app/gestiondocumental',
        icon: ArchiveIcon,
        permission: [
          'app:general:gestiondocumental:politicas:view',
          'app:general:gestiondocumental:manualescorporativos:view',
        ],
        items: [
          {
            title: 'Políticas',
            href: '/app/gestiondocumental/politicas',
            permission: ['app:general:gestiondocumental:politicas:view'],
            info: () => (
              <IconButtonBase
                url="/app/gestiondocumental/politicas/new"
                permission={['app:general:gestiondocumental:politicas:new']}
              />
            ),
          },
          {
            title: 'Manuales corporativos',
            href: '/app/gestiondocumental/manualescorporativos',
            permission: ['app:general:gestiondocumental:manualescorporativos:view'],
            info: () => (
              <IconButtonBase
                url="/app/gestiondocumental/manualescorporativos/new"
                permission={['app:general:gestiondocumental:manualescorporativos:new']}
              />
            ),
          },
          {
            title: 'Procedimientos',
            href: '/app/gestiondocumental/procedimientos',
          },
          {
            title: 'Consultas',
            href: '/app/gestiondocumental/consultas',
            items: [],
          },
        ],
      },
    ],
  },
  {
    items: [
      {
        title: 'Gestión de contratos',
        href: '/app/gestiondecontratos',
        icon: FileTextIcon,
        permission: [
          'app:general:gestiondecontratos:contratos:view',
        ],
        items: [
          {
            title: 'Contratos',
            href: '/app/gestiondecontratos/contratos',
            permission: ['app:general:gestiondecontratos:contratos:view'],
            info: () => (
              <IconButtonBase
                url="/app/gestiondecontratos/contratos/new"
                permission={[
                  'app:general:gestiondecontratos:contratos:new',
                ]}
              />
            ),
          },
          {
            title: 'Consultas',
            href: '/app/gestiondecontratos/consultas',
            items: [],
          },
        ],
      },
    ],
  },
  {
    items: [
      {
        title: 'Gestión solicitudes (PQRSF)',
        href: '/app/gestionsolicitudes',
        icon: SmileIcon,
        permission: [
          'app:general:gestionsolicitudes:gestionsolicitudespqrsf:view',
          'app:general:gestionsolicitudes:seguimientosolicitudes:view',
        ],
        items: [
          {
            title: 'Gestión solicitudes - PQRSF',
            href: '/app/gestionsolicitudes/gestionsolicitudespqrsf',
            permission: ['app:general:gestionsolicitudes:gestionsolicitudespqrsf:view'],
            info: () => (
              <IconButtonBase
                url="/app/gestionsolicitudes/gestionsolicitudespqrsf/new"
                permission={['app:general:gestionsolicitudes:gestionsolicitudespqrsf:new']}
              />
            ),
          },
          {
            title: 'Seguimiento solicitudes',
            href: '/app/gestionsolicitudes/seguimientosolicitudes',
            permission: ['app:general:gestionsolicitudes:seguimientosolicitudes:view'],
          },
          {
            title: 'Cierre solicitudes',
            href: '/app/gestionsolicitudes/cierresolicitudes',
            permission: ['app:general:gestionsolicitudes:gestionsolicitudespqrsf:view'],
          },
          {
            title: 'Consultas',
            href: '/app/gestionsolicitudes/consultas',
            items: [],
          },
        ],
      },
    ],
  },
  {
    items: [
      {
        title: 'Centro de ayuda',
        href: '/app/centrodeayuda',
        icon: HelpIcon,
        permission: [
          'app:general:centrodeayuda:enviarpreguntas:view',
          'app:general:centrodeayuda:sugerencias:view',
          'app:general:centrodeayuda:agendardemostracion:view',
          'app:general:centrodeayuda:consultas:preguntasfrecuentes:view',
        ],
        items: [
          {
            title: 'Enviar preguntas',
            href: '/app/centrodeayuda/enviarpreguntas',
            permission: ['app:general:centrodeayuda:enviarpreguntas:view'],
          },
          {
            title: 'Sugerencias',
            href: '/app/centrodeayuda/sugerencias',
            permission: ['app:general:centrodeayuda:sugerencias:view'],
          },
          {
            title: 'Agendar demostración',
            href: '/app/centrodeayuda/agendardemostracion',
            permission: ['app:general:centrodeayuda:agendardemostracion:view'],
          },
          {
            title: 'Consultas',
            href: '/app/centrodeayuda/consultas',
            items: [
              {
                title: 'Preguntas frecuentes',
                href: '/app/centrodeayuda/consultas/preguntasfrecuentes',
                permission: ['app:general:centrodeayuda:consultas:preguntasfrecuentes:view'],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    items: [
      {
        title: 'Configuración',
        href: '/app/configuracion',
        icon: SettingsIcon,
        permission: [
          'app:general:configuracion:crm:consecutivoscrm:view',
          'app:general:configuracion:crm:transaccionescrm:view',
          'app:general:configuracion:crm:prospectos:view',
          'app:general:configuracion:crm:clasificacionesclientes:view',
          'app:general:configuracion:crm:clientes:view',
          'app:general:configuracion:crm:habeasdata:view',
          'app:general:configuracion:crm:medioscomunicacioncliente:view',
          'app:general:configuracion:crm:tiposlistasdeprecios:view',
          'app:general:configuracion:crm:listasdeprecios:view',
          'app:general:configuracion:crm:cotizacion:tiposcotizacion:view',
          'app:general:configuracion:crm:cotizacion:clasificacioncotizacion:view',
          'app:general:configuracion:ventas:consecutivosventas:view',
          'app:general:configuracion:ventas:transaccionesventas:view',
          'app:general:configuracion:ventas:clasificacionesservicios:view',
          'app:general:configuracion:ventas:servicios:view',
          'app:general:configuracion:ventas:descuentos:view',
          'app:general:configuracion:ventas:operacionesdian:view',
          'app:general:configuracion:gestionproveedores:consecutivosgestiondeproveedores:view',
          'app:general:configuracion:gestionproveedores:transaccionesgestiondeproveedores:view',
          'app:general:configuracion:gestionproveedores:clasificacionesproveedores:view',
          'app:general:configuracion:gestionproveedores:proveedores:view',
          'app:general:configuracion:compras:consecutivoscompras:view',
          'app:general:configuracion:compras:transaccionescompras:view',
          'app:general:configuracion:compras:acuerdoscomerciales:view',
          'app:general:configuracion:compras:agentesaduaneros:view',
          'app:general:configuracion:compras:unidadesdeempaque:view',
          'app:general:configuracion:compras:incoterms:view',
          'app:general:configuracion:compras:registrosinvima:view',
          'app:general:configuracion:compras:requisitosimportacion:view',
          'app:general:configuracion:compras:requisitosaduaneros:view',
          'app:general:configuracion:distribucion:generales:centrosdedistribucion:view',
          'app:general:configuracion:distribucion:generales:bodegas:view',
          'app:general:configuracion:distribucion:generales:almacenes:view',
          'app:general:configuracion:distribucion:generales:zonas:view',
          'app:general:configuracion:distribucion:generales:tiposinventario:view',
          'app:general:configuracion:distribucion:generales:unidadesdemedida:view',
          'app:general:configuracion:distribucion:generales:clasificacion1:view',
          'app:general:configuracion:distribucion:generales:clasificacion2:view',
          'app:general:configuracion:distribucion:generales:clasificacion3:view',
          'app:general:configuracion:distribucion:inventariogeneral:consecutivosinventariogeneral:view',
          'app:general:configuracion:distribucion:inventariogeneral:transaccionesinventariogeneral:view',
          'app:general:configuracion:distribucion:inventariogeneral:tiposproductos:view',
          'app:general:configuracion:distribucion:inventariogeneral:productos:view',
          'app:general:configuracion:distribucion:productoterminado:consecutivosproductoterminado:view',
          'app:general:configuracion:distribucion:productoterminado:transaccionesproductoterminado:view',
          'app:general:configuracion:distribucion:productoterminado:categoriascomposicion:view',
          'app:general:configuracion:distribucion:productoterminado:tiposproductos:view',
          'app:general:configuracion:distribucion:productoterminado:productosterminados:view',
          'app:general:configuracion:logistica:consecutivoslogistica:view',
          'app:general:configuracion:logistica:transaccioneslogistica:view',
          'app:general:configuracion:logistica:tiposcontenedores:view',
          'app:general:configuracion:logistica:contenedores:view',
          'app:general:configuracion:logistica:tipostransportes:view',
          'app:general:configuracion:logistica:clasificacionestransportadores:view',
          'app:general:configuracion:logistica:transportadores:view',
          'app:general:configuracion:gestionactivosfijos:consecutivosgestionactivos:view',
          'app:general:configuracion:gestionactivosfijos:transaccionesgestionactivos:view',
          'app:general:configuracion:gestionactivosfijos:tiposactivos:view',
          'app:general:configuracion:gestionactivosfijos:categoriasactivos:view',
          'app:general:configuracion:gestionactivosfijos:gruposactivos:view',
          'app:general:configuracion:gestionactivosfijos:activos:view',
          'app:general:configuracion:gestionactivosfijos:metodosdedepreciacion:view',
          'app:general:configuracion:gestionactivosfijos:mantenimientos:tiposmantenimientos:view',
          'app:general:configuracion:recursoshumanos:consecutivosrrhh:view',
          'app:general:configuracion:recursoshumanos:transaccionesrrhh:view',
          'app:general:configuracion:recursoshumanos:generales:tiposempleados:view',
          'app:general:configuracion:recursoshumanos:generales:subtipoempleado:view',
          'app:general:configuracion:recursoshumanos:generales:niveleseducativos:view',
          'app:general:configuracion:recursoshumanos:generales:clasesderiesgoarl:view',
          'app:general:configuracion:recursoshumanos:generales:altoriesgo:view',
          'app:general:configuracion:recursoshumanos:generales:eps:view',
          'app:general:configuracion:recursoshumanos:generales:tiposdecontratos:view',
          'app:general:configuracion:recursoshumanos:generales:tiposrestricciones:view',
          'app:general:configuracion:recursoshumanos:generales:restriccioneslaborales:view',
          'app:general:configuracion:recursoshumanos:generales:empleados:view',
          'app:general:configuracion:recursoshumanos:gestiondeltalento:cargos:view',
          'app:general:configuracion:recursoshumanos:gestiondeltalento:requisitoscargos:view',
          'app:general:configuracion:recursoshumanos:gestiondeltalento:filtrosgestiondeltalento:view',
          'app:general:configuracion:recursoshumanos:gestiondeltalento:pruebasdeseleccion:view',
          'app:general:configuracion:recursoshumanos:gestiondeltalento:examenesmedicos:view',
          'app:general:configuracion:recursoshumanos:gestiondeltalento:catalogodehabilidades:view',
          'app:general:configuracion:recursoshumanos:gestiondeltalento:catalogodecompetencias:view',
          'app:general:configuracion:recursoshumanos:gestiondeltalento:entrevistas:view',
          'app:general:configuracion:recursoshumanos:gestiondeltalento:candidatos:view',
          'app:general:configuracion:recursoshumanos:seguimientoyretencion:requisitoscontratacionrrhh:view',
          'app:general:configuracion:recursoshumanos:seguimientoyretencion:tiposcapacitaciones:view',
          'app:general:configuracion:recursoshumanos:seguimientoyretencion:temascapacitaciones:view',
          'app:general:configuracion:recursoshumanos:seguimientoyretencion:encuestasevaluacioncapacitaciones:view',
          'app:general:configuracion:recursoshumanos:procesosdisciplinarios:disciplinarios:view',
          'app:general:configuracion:recursoshumanos:bienestarlaboral:tiposbeneficios:view',
          'app:general:configuracion:recursoshumanos:bienestarlaboral:categoriasbeneficios:view',
          'app:general:configuracion:recursoshumanos:bienestarlaboral:beneficios:view',
          'app:general:configuracion:recursoshumanos:bienestarlaboral:tipodebonificacion:view',
          'app:general:configuracion:recursoshumanos:gestiondeltiempo:unidadesdetiempo:view',
          'app:general:configuracion:recursoshumanos:gestiondeltiempo:recesos:view',
          'app:general:configuracion:recursoshumanos:gestiondeltiempo:tiposturnos:view',
          'app:general:configuracion:recursoshumanos:gestiondeltiempo:turnos:view',
          'app:general:configuracion:recursoshumanos:gestiondeltiempo:equiposdetrabajo:view',
          'app:general:configuracion:recursoshumanos:gestiondeltiempo:proyectos:view',
          'app:general:configuracion:recursoshumanos:gestiondeltiempo:hitos:view',
          'app:general:configuracion:recursoshumanos:gestiondeltiempo:tipodehoraextra:view',
          'app:general:configuracion:recursoshumanos:gestiondeltiempo:tiposmodalidadesdetrabajo:view',
          'app:general:configuracion:recursoshumanos:gestiondeltiempo:modalidadesdetrabajo:view',
          'app:general:configuracion:recursoshumanos:gestiondeltiempo:ausencias:tipospermisos:view',
          'app:general:configuracion:recursoshumanos:gestiondeltiempo:ausencias:licencias:view',
          'app:general:configuracion:recursoshumanos:gestiondeltiempo:ausencias:tipodeincapacidad:view',
          'app:general:configuracion:recursoshumanos:nomina:conceptosnomina:view',
          'app:general:configuracion:recursoshumanos:nomina:smlvauxiliodetransporte:view',
          'app:general:configuracion:recursoshumanos:nomina:prestacionessociales:view',
          'app:general:configuracion:recursoshumanos:nomina:frecuenciasdepagos:view',
          'app:general:configuracion:recursoshumanos:nomina:tiposdeducciones:view',
          'app:general:configuracion:recursoshumanos:nomina:deducciones:view',
          'app:general:configuracion:recursoshumanos:pila:tiposdecotizantes:view',
          'app:general:configuracion:recursoshumanos:pila:tiposdeplantillas:view',
          'app:general:configuracion:recursoshumanos:pila:clasesaportantes:view',
          'app:general:configuracion:recursoshumanos:desvinculacionlaboral:requisitospazysalvo:view',
          'app:general:configuracion:recursoshumanos:desvinculacionlaboral:pazysalvo:view',
          'app:general:configuracion:recursoshumanos:saludyseguridadeneltrabajo:consecutivossst:view',
          'app:general:configuracion:recursoshumanos:saludyseguridadeneltrabajo:transaccionessst:view',
          'app:general:configuracion:recursoshumanos:saludyseguridadeneltrabajo:tipoderiesgos:view',
          'app:general:configuracion:recursoshumanos:saludyseguridadeneltrabajo:clasificacionderiesgos:view',
          'app:general:configuracion:recursoshumanos:saludyseguridadeneltrabajo:probabilidadderiesgos:view',
          'app:general:configuracion:recursoshumanos:saludyseguridadeneltrabajo:impactoderiesgos:view',
          'app:general:configuracion:recursoshumanos:saludyseguridadeneltrabajo:tipoequipodetrabajo:view',
          'app:general:configuracion:recursoshumanos:saludyseguridadeneltrabajo:elementosdeproteccionprofesional:view',
          'app:general:configuracion:gobiernocorporativo:consecutivosgobiernocorporativo:view',
          'app:general:configuracion:gobiernocorporativo:transaccionesgobiernocorporativo:view',
          'app:general:configuracion:gobiernocorporativo:configuracionempresa:view',
          'app:general:configuracion:gobiernocorporativo:sedesempresa:view',
          'app:general:configuracion:gobiernocorporativo:clasificacioncalendario:view',
          'app:general:configuracion:gobiernocorporativo:gestionempresarial:faltas:view',
          'app:general:configuracion:gobiernocorporativo:organigrama:estructuraorganizacional:view',
          'app:general:configuracion:gobiernocorporativo:calidad:consecutivoscalidad:view',
          'app:general:configuracion:gobiernocorporativo:calidad:transaccionescalidad:view',
          'app:general:configuracion:gestiondocumental:clasificacionpoliticas:view',
          'app:general:configuracion:gestiondecontratos:consecutivosgestiondecontratos:view',
          'app:general:configuracion:gestiondecontratos:transaccionesgestiondecontratos:view',
          'app:general:configuracion:gestiondecontratos:tiposdocumentoscontratos:view',
          'app:general:configuracion:gestiondecontratos:clasificacioncontratos:view',
          'app:general:configuracion:gestiondecontratos:partesinvolucradas:view',
          'app:general:configuracion:gestionsolicitudes:categoriassolicitud:view',
          'app:general:configuracion:gestionsolicitudes:niveldeservicio:view',
          'app:general:configuracion:gestionsolicitudes:nivel:view',
          'app:general:configuracion:gestionsolicitudes:prioridadimpacto:view',
          'app:general:configuracion:centrodeayuda:ayudasgraficas:view',
          'app:general:configuracion:general:atributosgenerales:view',
          'app:general:configuracion:general:imprimibles:view',
          'app:general:configuracion:general:encuestas:view',
          'app:general:configuracion:general:tiposadjuntos:view',
          'app:general:configuracion:general:tiposdeidentificacion:view',
          'app:general:configuracion:general:nomenclaturas:view',
          'app:general:configuracion:divisionpolitica:paises:view',
          'app:general:configuracion:divisionpolitica:departamentos:view',
          'app:general:configuracion:divisionpolitica:ciudades:view',
          'app:general:configuracion:notificaciones:notificacionesprocesos:view',
          'app:general:configuracion:notificaciones:correosnotificaciones:view',
          'app:general:configuracion:seguridad:usuarios:view',
          'app:general:configuracion:seguridad:roles:view',
          'app:general:configuracion:seguridad:grupospermisos:view',
          'app:general:configuracion:tesoreria:consecutivostesoreria:view',
          'app:general:configuracion:tesoreria:transaccionestesoreria:view',
          'app:general:configuracion:tesoreria:bancos:view',
          'app:general:configuracion:tesoreria:gastos:view',
          'app:general:configuracion:tesoreria:tiposdenotacredito:view',
          'app:general:configuracion:tesoreria:tiposdenotadebito:view',
          'app:general:configuracion:tesoreria:monedas:view',
          'app:general:configuracion:tesoreria:cuentasbancarias:view',
          'app:general:configuracion:tesoreria:formasdepago:view',
          'app:general:configuracion:tesoreria:mediosdepago:view',
          'app:general:configuracion:tesoreria:tiposdepagos:view',
        ],
        items: [
          {
            title: 'CRM',
            href: '/app/configuracion/crm',
            permission: [
              'app:general:configuracion:crm:consecutivoscrm:view',
              'app:general:configuracion:crm:transaccionescrm:view',
              'app:general:configuracion:crm:prospectos:view',
              'app:general:configuracion:crm:clasificacionesclientes:view',
              'app:general:configuracion:crm:clientes:view',
              'app:general:configuracion:crm:habeasdata:view',
              'app:general:configuracion:crm:medioscomunicacioncliente:view',
              'app:general:configuracion:crm:tiposlistasdeprecios:view',
              'app:general:configuracion:crm:listasdeprecios:view',
              'app:general:configuracion:crm:cotizacion:tiposcotizacion:view',
              'app:general:configuracion:crm:cotizacion:clasificacioncotizacion:view',
            ],
            items: [
              {
                title: 'Consecutivos crm',
                href: '/app/configuracion/crm/consecutivoscrm',
                permission: ['app:general:configuracion:crm:consecutivoscrm:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/crm/consecutivoscrm/new"
                    permission={['app:general:configuracion:crm:consecutivoscrm:new']}
                  />
                ),
              },
              {
                title: 'Transacciones crm',
                href: '/app/configuracion/crm/transaccionescrm',
                permission: ['app:general:configuracion:crm:transaccionescrm:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/crm/transaccionescrm/new"
                    permission={['app:general:configuracion:crm:transaccionescrm:new']}
                  />
                ),
              },
              {
                title: 'Prospectos',
                href: '/app/configuracion/crm/prospectos',
                permission: ['app:general:configuracion:crm:prospectos:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/crm/prospectos/new"
                    permission={['app:general:configuracion:crm:prospectos:new']}
                  />
                ),
              },
              {
                title: 'Clasificaciones clientes',
                href: '/app/configuracion/crm/clasificacionesclientes',
                permission: ['app:general:configuracion:crm:clasificacionesclientes:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/crm/clasificacionesclientes/new"
                    permission={['app:general:configuracion:crm:clasificacionesclientes:new']}
                  />
                ),
              },
              {
                title: 'Clientes',
                href: '/app/configuracion/crm/clientes',
                permission: ['app:general:configuracion:crm:clientes:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/crm/clientes/new"
                    permission={['app:general:configuracion:crm:clientes:new']}
                  />
                ),
              },
              {
                title: 'Habeas data',
                href: '/app/configuracion/crm/habeasdata',
                permission: ['app:general:configuracion:crm:habeasdata:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/crm/habeasdata/new"
                    permission={['app:general:configuracion:crm:habeasdata:new']}
                  />
                ),
              },
              {
                title: 'Medios comunicación cliente',
                href: '/app/configuracion/crm/medioscomunicacioncliente',
                permission: ['app:general:configuracion:crm:medioscomunicacioncliente:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/crm/medioscomunicacioncliente/new"
                    permission={['app:general:configuracion:crm:medioscomunicacioncliente:new']}
                  />
                ),
              },
              {
                title: 'Tipos listas de precios',
                href: '/app/configuracion/crm/tiposlistasprecios',
                permission: ['app:general:configuracion:crm:tiposlistasdeprecios:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/crm/tiposlistasprecios/new"
                    permission={['app:general:configuracion:crm:tiposlistasdeprecios:view']}
                  />
                ),
              },
              {
                title: 'Listas de precios',
                href: '/app/configuracion/crm/listasdeprecios',
                permission: ['app:general:configuracion:crm:listasdeprecios:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/crm/listasdeprecios/new"
                    permission={['app:general:configuracion:crm:listasdeprecios:new']}
                  />
                ),
              },
              {
                title: 'Cargar clientes',
                href: '/app/configuracion/crm/carguemasivo',
                permission: ['app:general:configuracion:crm:cargarclientes:view'],
              },
              {
                title: 'Cotización',
                href: '/app/configuracion/crm/cotizacion',
                items: [
                  {
                    title: 'Tipos cotización',
                    href: '/app/configuracion/crm/cotizacion/tiposcotizacion',
                    permission: ['app:general:configuracion:crm:cotizacion:tiposcotizacion:view'],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/crm/cotizacion/tiposcotizacion/new"
                        permission={['app:general:configuracion:crm:cotizacion:tiposcotizacion:new']}
                      />
                    ),
                  },
                  {
                    title: 'Clasificación cotización',
                    href:
                      '/app/configuracion/crm/cotizacion/clasificacioncotizacion',
                    permission: ['app:general:configuracion:crm:cotizacion:clasificacioncotizacion:view'],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/crm/cotizacion/clasificacioncotizacion/new"
                        permission={['app:general:configuracion:crm:cotizacion:clasificacioncotizacion:new']}
                      />
                    ),
                  },
                ],
              },
            ],
          },
          {
            title: 'Ventas',
            href: '/app/configuracion/ventas',
            permission: [
              'app:general:configuracion:ventas:consecutivosventas:view',
              'app:general:configuracion:ventas:transaccionesventas:view',
              'app:general:configuracion:ventas:clasificacionesservicios:view',
              'app:general:configuracion:ventas:servicios:view',
              'app:general:configuracion:ventas:descuentos:view',
              'app:general:configuracion:ventas:operacionesdian:view',
              'app:general:configuracion:ventas:cajasregistradoras:view',
              'app:general:configuracion:ventas:puntosdeventa:view',
            ],
            items: [
              {
                title: 'Consecutivos ventas',
                href: '/app/configuracion/ventas/consecutivosventas',
                permission: ['app:general:configuracion:ventas:consecutivosventas:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/ventas/consecutivosventas/new"
                    permission={['app:general:configuracion:ventas:consecutivosventas:new']}
                  />
                ),
              },
              {
                title: 'Transacciones ventas',
                href: '/app/configuracion/ventas/transaccionesventas',
                permission: ['app:general:configuracion:ventas:transaccionesventas:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/ventas/transaccionesventas/new"
                    permission={['app:general:configuracion:ventas:transaccionesventas:new']}
                  />
                ),
              },
              {
                title: 'Clasificaciones servicios',
                href: '/app/configuracion/ventas/clasificacionesservicios',
                permission: ['app:general:configuracion:ventas:clasificacionesservicios:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/ventas/clasificacionesservicios/new"
                    permission={['app:general:configuracion:ventas:clasificacionesservicios:new']}
                  />
                ),
              },
              {
                title: 'Servicios',
                href: '/app/configuracion/ventas/servicios',
                permission: ['app:general:configuracion:ventas:servicios:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/ventas/servicios/new"
                    permission={['app:general:configuracion:ventas:servicios:new']}
                  />
                ),
              },
              {
                title: 'Descuentos',
                href: '/app/configuracion/ventas/descuentos',
                permission: ['app:general:configuracion:ventas:descuentos:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/ventas/descuentos"
                    permission={['app:general:configuracion:ventas:descuentos:new']}
                  />
                ),
              },
              {
                title: 'Operaciones DIAN',
                href: '/app/configuracion/ventas/operacionesdian',
                permission: ['app:general:configuracion:ventas:operacionesdian:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/ventas/operacionesdian/new"
                    permission={['app:general:configuracion:ventas:operacionesdian:new']}
                  />
                ),
              },
              {
                title: 'Cajas registradoras',
                href: '/app/configuracion/ventas/cajasregistradoras',
                permission: ['app:general:configuracion:ventas:cajasregistradoras:view'],
              },
              {
                title: 'Puntos de venta',
                href: '/app/configuracion/ventas/puntosventa',
                permission: ['app:general:configuracion:ventas:puntosdeventa:view'],
              },
            ],
          },
          {
            title: 'Gestión proveedores (SRM)',
            href: '/app/configuracion/gestionproveedores',
            permission: [
              'app:general:configuracion:gestionproveedores:consecutivosgestiondeproveedores:view',
              'app:general:configuracion:gestionproveedores:transaccionesgestiondeproveedores:view',
              'app:general:configuracion:gestionproveedores:clasificacionesproveedores:view',
              'app:general:configuracion:gestionproveedores:proveedores:view',
              'app:general:configuracion:ventas:operacionesdian:view',
              'app:general:configuracion:gestionproveedores:cargarproveedores:view',
              'app:general:configuracion:gestionproveedores:requisitosproveedores:view',
              'app:general:configuracion:gestionproveedores:criteriosevaluacion:view',
            ],
            items: [
              {
                title: 'Consecutivos gestión de proveedores',
                href:
                  '/app/configuracion/gestionproveedores/consecutivogestiondeproveedores',
                permission: [
                  'app:general:configuracion:gestionproveedores:consecutivosgestiondeproveedores:view',
                ],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/gestionproveedores/consecutivogestiondeproveedores/new"
                    permission={[
                      'app:general:configuracion:gestionproveedores:consecutivosgestiondeproveedores:new'
                    ]}
                  />
                ),
              },
              {
                title: 'Transacciones gestión de proveedores',
                href:
                  '/app/configuracion/gestionproveedores/transaccionesgestiondeproveedores',
                permission: [
                  'app:general:configuracion:gestionproveedores:transaccionesgestiondeproveedores:view',
                ],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/gestionproveedores/transaccionesgestiondeproveedores/new"
                    permission={[
                      'app:general:configuracion:gestionproveedores:transaccionesgestiondeproveedores:new'
                    ]}
                  />
                ),
              },
              {
                title: 'Clasificaciones proveedores',
                href:
                  '/app/configuracion/gestionproveedores/clasificacionesproveedores',
                permission: [
                  'app:general:configuracion:gestionproveedores:clasificacionesproveedores:view',
                ],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/gestionproveedores/clasificacionesproveedores/new"
                    permission={[
                      'app:general:configuracion:gestionproveedores:clasificacionesproveedores:new'
                    ]}
                  />
                ),
              },
              {
                title: 'Proveedores',
                href: '/app/configuracion/gestionproveedores/proveedores',
                permission: ['app:general:configuracion:gestionproveedores:proveedores:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/gestionproveedores/proveedores/new"
                    permission={[
                      'app:general:configuracion:gestionproveedores:proveedores:new'
                    ]}
                  />
                ),
              },
              {
                title: 'Requisitos proveedores',
                href:
                  '/app/configuracion/gestionproveedores/requisitosproveedores',
                permission: [
                  'app:general:configuracion:gestionproveedores:requisitosproveedores:view',
                ],
              },
              {
                title: 'Criterios evaluación',
                href:
                  '/app/configuracion/gestionproveedores/criteriosevaluacion',
                permission: [
                  'app:general:configuracion:gestionproveedores:criteriosevaluacion:view',
                ],
              },
              {
                title: 'Cargar proveedores',
                href: '/app/configuracion/gestionproveedores/carguemasivo',
                permission: ['app:general:configuracion:gestionproveedores:cargarproveedores:view'],
              },
            ],
          },
          {
            title: 'Compras',
            href: '/app/configuracion/compras',
            permission: [
              'app:general:configuracion:compras:consecutivoscompras:view',
              'app:general:configuracion:compras:transaccionescompras:view',
              'app:general:configuracion:compras:acuerdoscomerciales:view',
              'app:general:configuracion:compras:agentesaduaneros:view',
              'app:general:configuracion:compras:unidadesdeempaque:view',
              'app:general:configuracion:compras:incoterms:view',
              'app:general:configuracion:compras:registrosinvima:view',
              'app:general:configuracion:compras:requisitosimportacion:view',
              'app:general:configuracion:compras:requisitosaduaneros:view',
            ],
            items: [
              {
                title: 'Consecutivos compras',
                href: '/app/configuracion/compras/consecutivoscompras',
                permission: ['app:general:configuracion:compras:consecutivoscompras:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/compras/consecutivoscompras/new"
                    permission={['app:general:configuracion:compras:consecutivoscompras:new']}
                  />
                ),
              },
              {
                title: 'Transacciones compras',
                href: '/app/configuracion/compras/transaccionescompras',
                permission: ['app:general:configuracion:compras:transaccionescompras:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/compras/transaccionescompras/new"
                    permission={['app:general:configuracion:compras:transaccionescompras:new']}
                  />
                ),
              },
              {
                title: 'Acuerdos comerciales',
                href: '/app/configuracion/compras/acuerdoscomerciales',
                permission: ['app:general:configuracion:compras:acuerdoscomerciales:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/compras/acuerdoscomerciales/new"
                    permission={['app:general:configuracion:compras:acuerdoscomerciales:new']}
                  />
                ),
              },
              {
                title: 'Agentes aduaneros',
                href: '/app/configuracion/compras/agentesaduaneros',
                permission: ['app:general:configuracion:compras:agentesaduaneros:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/compras/agentesaduaneros/new"
                    permission={['app:general:configuracion:compras:agentesaduaneros:new']}
                  />
                ),
              },
              {
                title: 'Unidades de empaques',
                href: '/app/configuracion/compras/unidadesempaques',
                permission: ['app:general:configuracion:compras:unidadesdeempaque:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/compras/unidadesempaques/new"
                    permission={['app:general:configuracion:compras:unidadesdeempaque:new']}
                  />
                ),
              },
              {
                title: 'Incoterms',
                href: '/app/configuracion/compras/incoterms',
                permission: ['app:general:configuracion:compras:incoterms:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/compras/incoterms/new"
                    permission={['app:general:configuracion:compras:incoterms:new']}
                  />
                ),
              },
              {
                title: 'Registros invima',
                href: '/app/configuracion/compras/registrosinvima',
                permission: ['app:general:configuracion:compras:registrosinvima:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/compras/registrosinvima/new"
                    permission={['app:general:configuracion:compras:registrosinvima:new']}
                  />
                ),
              },
              {
                title: 'Requisitos importación',
                href: '/app/configuracion/compras/requisitosimportacion',
                permission: ['app:general:configuracion:compras:requisitosimportacion:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/compras/requisitosimportacion/new"
                    permission={['app:general:configuracion:compras:requisitosimportacion:new']}
                  />
                ),
              },
              {
                title: 'Requisitos aduaneros',
                href: '/app/configuracion/compras/requisitosaduaneros',
                permission: ['app:general:configuracion:compras:requisitosaduaneros:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/compras/requisitosaduaneros/new"
                    permission={['app:general:configuracion:compras:requisitosaduaneros:new']}
                  />
                ),
              },
            ],
          },
          {
            title: 'Producción (PLM)',
            href: '/app/configuracion/produccion',
            permission: ['app:general:produccion:view'],
            items: [],
          },
          {
            title: 'Distribución (WMS)',
            href: '/app/configuracion/distribucion',
            permission: [
              'app:general:configuracion:distribucion:generales:centrosdedistribucion:view',
              'app:general:configuracion:distribucion:generales:bodegas:view',
              'app:general:configuracion:distribucion:generales:almacenes:view',
              'app:general:configuracion:distribucion:generales:zonas:view',
              'app:general:configuracion:distribucion:generales:tiposinventario:view',
              'app:general:configuracion:distribucion:generales:unidadesdemedida:view',
              'app:general:configuracion:distribucion:generales:clasificacion1:view',
              'app:general:configuracion:distribucion:generales:clasificacion2:view',
              'app:general:configuracion:distribucion:generales:clasificacion3:view',
              'app:general:configuracion:distribucion:inventariogeneral:consecutivosinventariogeneral:view',
              'app:general:configuracion:distribucion:inventariogeneral:transaccionesinventariogeneral:view',
              'app:general:configuracion:distribucion:inventariogeneral:tiposproductos:view',
              'app:general:configuracion:distribucion:inventariogeneral:productos:view',
              'app:general:configuracion:distribucion:productoterminado:consecutivosproductoterminado:view',
              'app:general:configuracion:distribucion:productoterminado:transaccionesproductoterminado:view',
              'app:general:configuracion:distribucion:productoterminado:categoriascomposicion:view',
              'app:general:configuracion:distribucion:productoterminado:tiposproductos:view',
              'app:general:configuracion:distribucion:productoterminado:productosterminados:view',
              'app:general:distribucion:materiaprima:view',
              'app:general:configuracion:distribucion:generales:cargarproductos:view',
            ],
            items: [
              {
                title: 'Generales',
                href: '/app/configuracion/distribucion/generales',
                permission: [
                  'app:general:configuracion:distribucion:generales:centrosdedistribucion:view',
                  'app:general:configuracion:distribucion:generales:bodegas:view',
                  'app:general:configuracion:distribucion:generales:almacenes:view',
                  'app:general:configuracion:distribucion:generales:zonas:view',
                  'app:general:configuracion:distribucion:generales:tiposinventario:view',
                  'app:general:configuracion:distribucion:generales:unidadesdemedida:view',
                  'app:general:configuracion:distribucion:generales:clasificacion1:view',
                  'app:general:configuracion:distribucion:generales:clasificacion2:view',
                  'app:general:configuracion:distribucion:generales:clasificacion3:view',
                  'app:general:configuracion:distribucion:generales:cargarproductos:view',
                ],
                items: [
                  {
                    title: 'Centros de distribución',
                    href:
                      '/app/configuracion/distribucion/generales/centrosdedistribucion',
                    permission: [
                      'app:general:configuracion:distribucion:generales:centrosdedistribucion:view',
                    ],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/distribucion/generales/centrosdedistribucion/new"
                        permission={['app:general:configuracion:distribucion:generales:centrosdedistribucion:new']}
                      />
                    ),
                  },
                  {
                    title: 'Bodegas',
                    href: '/app/configuracion/distribucion/generales/bodegas',
                    permission: [
                      'app:general:configuracion:distribucion:generales:bodegas:view',
                    ],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/distribucion/generales/bodegas/new"
                        permission={['app:general:configuracion:distribucion:generales:bodegas:new']}
                      />
                    ),
                  },
                  {
                    title: 'Almacenes',
                    href: '/app/configuracion/distribucion/generales/almacenes',
                    permission: [
                      'app:general:configuracion:distribucion:generales:almacenes:view',
                    ],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/distribucion/generales/almacenes/new"
                        permission={['app:general:configuracion:distribucion:generales:almacenes:new']}
                      />
                    ),
                  },
                  {
                    title: 'Zonas',
                    href: '/app/configuracion/distribucion/generales/zonas',
                    permission: ['app:general:configuracion:distribucion:generales:zonas:view'],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/distribucion/generales/zonas/new"
                        permission={['app:general:configuracion:distribucion:generales:zonas:new']}
                      />
                    ),
                  },
                  {
                    title: 'Tipos inventarios',
                    href:
                      '/app/configuracion/distribucion/generales/tiposinventario',
                    permission: [
                      'app:general:configuracion:distribucion:generales:tiposinventario:view',
                    ],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/distribucion/generales/tiposinventario/new"
                        permission={['app:general:configuracion:distribucion:generales:tiposinventario:new']}
                      />
                    ),
                  },
                  {
                    title: 'Unidades de medida',
                    href:
                      '/app/configuracion/distribucion/generales/unidadesdemedida',
                    permission: [
                      'app:general:configuracion:distribucion:generales:unidadesdemedida:view',
                    ],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/distribucion/generales/unidadesdemedida/new"
                        permission={['app:general:configuracion:distribucion:generales:unidadesdemedida:new']}
                      />
                    ),
                  },
                  {
                    title: 'Clasificación 1',
                    href:
                      '/app/configuracion/distribucion/generales/clasificacion1',
                    permission: [
                      'app:general:configuracion:distribucion:generales:clasificacion1:view',
                    ],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/distribucion/generales/clasificacion1/new"
                        permission={['app:general:configuracion:distribucion:generales:clasificacion1:new']}
                      />
                    ),
                  },
                  {
                    title: 'Clasificación 2',
                    href:
                      '/app/configuracion/distribucion/generales/clasificacion2',
                    permission: [
                      'app:general:configuracion:distribucion:generales:clasificacion2:view',
                    ],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/distribucion/generales/clasificacion2/new"
                        permission={['app:general:configuracion:distribucion:generales:clasificacion2:new']}
                      />
                    ),
                  },
                  {
                    title: 'Clasificación 3',
                    href:
                      '/app/configuracion/distribucion/generales/clasificacion3',
                    permission: [
                      'app:general:configuracion:distribucion:generales:clasificacion3:view',
                    ],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/distribucion/generales/clasificacion3/new"
                        permission={['app:general:configuracion:distribucion:generales:clasificacion3:new']}
                      />
                    ),
                  },
                  {
                    title: 'Cargar productos',
                    href:
                      '/app/configuracion/distribucion/generales/carguemasivo',
                    permission: ['app:general:configuracion:distribucion:generales:cargarproductos:view'],
                  },
                ],
              },
              {
                title: 'Inventario general',
                href: '/app/configuracion/distribucion/inventariogeneral',
                permission: [
                  'app:general:configuracion:distribucion:inventariogeneral:consecutivosinventariogeneral:view',
                  'app:general:configuracion:distribucion:inventariogeneral:transaccionesinventariogeneral:view',
                  'app:general:configuracion:distribucion:inventariogeneral:tiposproductos:view',
                  'app:general:configuracion:distribucion:inventariogeneral:productos:view',
                ],
                items: [
                  {
                    title: 'Consecutivos inventario general',
                    href:
                      '/app/configuracion/distribucion/inventariogeneral/consecutivosinventariogeneral',
                    permission: [
                      'app:general:configuracion:distribucion:inventariogeneral:consecutivosinventariogeneral:view',
                    ],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/distribucion/inventariogeneral/consecutivosinventariogeneral/new"
                        permission={[
                          'app:general:configuracion:distribucion:inventariogeneral:consecutivosinventariogeneral:new',
                        ]}
                      />
                    ),
                  },
                  {
                    title: 'Transacciones inventario general',
                    href:
                      '/app/configuracion/distribucion/inventariogeneral/transaccionesinventariogeneral',
                    permission: [
                      'app:general:configuracion:distribucion:inventariogeneral:transaccionesinventariogeneral:view',
                    ],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/distribucion/inventariogeneral/transaccionesinventariogeneral/new"
                        permission={[
                          'app:general:configuracion:distribucion:inventariogeneral:transaccionesinventariogeneral:new',
                        ]}
                      />
                    ),
                  },
                  {
                    title: 'Tipos productos',
                    href:
                      '/app/configuracion/distribucion/inventariogeneral/tipoproductoinventariogeneral',
                    permission: [
                      'app:general:configuracion:distribucion:inventariogeneral:tiposproductos:view',
                    ],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/distribucion/inventariogeneral/tipoproductoinventariogeneral/new"
                        permission={[
                          'app:general:configuracion:distribucion:inventariogeneral:tiposproductos:new',
                        ]}
                      />
                    ),
                  },
                  {
                    title: 'Productos',
                    href:
                      '/app/configuracion/distribucion/inventariogeneral/inventariogeneral/IG',
                    permission: [
                      'app:general:configuracion:distribucion:inventariogeneral:productos:view',
                    ],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/distribucion/inventariogeneral/inventariogeneral/IG/new"
                        permission={[
                          'app:general:configuracion:distribucion:inventariogeneral:productos:new',
                        ]}
                      />
                    ),
                  },
                ],
              },
              {
                title: 'Materia prima',
                href: '/app/configuracion/distribucion/materiaprima',
                permission: [
                  'app:general:distribucion:materiaprima:view',
                ],
                items: [
                  {
                    title: 'Consecutivos materia prima',
                    href:
                      '/app/configuracion/distribucion/materiaprima/consecutivomateriaprima',
                    permission: [
                      'configuracion:scm:inventario:inventariogeneral:consecutivos:list',
                    ],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/distribucion/materiaprima/consecutivomateriaprima/new"
                        permission={[
                          'configuracion:scm:inventario:inventariogeneral:consecutivos:create',
                        ]}
                      />
                    ),
                  },
                  {
                    title: 'Transacciones materia prima',
                    href:
                      '/app/configuracion/distribucion/materiaprima/transaccionesmateriaprima',
                    permission: [
                      'configuracion:scm:inventario:inventariogeneral:documento:list',
                    ],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/distribucion/materiaprima/transaccionesmateriaprima/new"
                        permission={[
                          'configuracion:scm:inventario:inventariogeneral:documento:create',
                        ]}
                      />
                    ),
                  },
                  {
                    title: 'Tipos productos',
                    href:
                      '/app/configuracion/distribucion/materiaprima/tiposproductos',
                    permission: [
                      'configuracion:scm:inventario:inventariogeneral:tipoproducto:list',
                    ],
                    info: () => (
                      <RouterLink to="/app/configuracion/distribucion/materiaprima/tiposproductos/new">
                        <IconButton>
                          <PlusIcon />
                        </IconButton>
                      </RouterLink>
                    ),
                  },
                  {
                    title: 'Productos',
                    href:
                      '/app/configuracion/distribucion/materiaprima/materiaprima/MP',
                    permission: [
                      'configuracion:scm:inventario:inventariogeneral:producto:list',
                    ],
                    info: () => (
                      <RouterLink to="/app/configuracion/distribucion/materiaprima/MP/new">
                        <IconButton>
                          <PlusIcon />
                        </IconButton>
                      </RouterLink>
                    ),
                  },
                ],
              },
              {
                title: 'Producto terminado',
                href: '/app/configuracion/distribucion/productoterminado',
                permission: [
                  'app:general:configuracion:distribucion:productoterminado:consecutivosproductoterminado:view',
                  'app:general:configuracion:distribucion:productoterminado:transaccionesproductoterminado:view',
                  'app:general:configuracion:distribucion:productoterminado:categoriascomposicion:view',
                  'app:general:configuracion:distribucion:productoterminado:tiposproductos:view',
                  'app:general:configuracion:distribucion:productoterminado:productosterminados:view',
                ],
                items: [
                  // {
                  //   title: 'Agrupación',
                  //   href: '/app/scm/inventario/productoterminado/maestros/agrupacion',
                  // },
                  // {
                  //   title: 'PT - Categorías',
                  //   href: '/app/configuracion/scm/inventario/productoterminado/categorias',
                  // },
                  {
                    title: 'Consecutivos Producto terminado',
                    href:
                      '/app/configuracion/distribucion/productoterminado/consecutivosproductoterminado',
                    permission: [
                      'app:general:configuracion:distribucion:productoterminado:consecutivosproductoterminado:view',
                    ],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/distribucion/productoterminado/consecutivosproductoterminado/new"
                        permission={[
                          'app:general:configuracion:distribucion:productoterminado:consecutivosproductoterminado:new',
                        ]}
                      />
                    ),
                  },
                  {
                    title: 'Transacciones Producto terminado',
                    href:
                      '/app/configuracion/distribucion/productoterminado/transaccionesproductoterminado',
                    permission: [
                      'app:general:configuracion:distribucion:productoterminado:transaccionesproductoterminado:view',
                    ],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/distribucion/productoterminado/transaccionesproductoterminado/new"
                        permission={[
                          'app:general:configuracion:distribucion:productoterminado:transaccionesproductoterminado:new',
                        ]}
                      />
                    ),
                  },
                  {
                    title: 'Categorías composición',
                    href:
                      '/app/configuracion/distribucion/productoterminado/categoriascomposicion',
                    permission: [
                      'app:general:configuracion:distribucion:productoterminado:categoriascomposicion:view',
                    ],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/distribucion/productoterminado/categoriascomposicion/new"
                        permission={[
                          'app:general:configuracion:distribucion:productoterminado:categoriascomposicion:new',
                        ]}
                      />
                    ),
                  },
                  {
                    title: 'Tipos productos',
                    href:
                      '/app/configuracion/distribucion/productoterminado/tiposproductos',
                    permission: [
                      'app:general:configuracion:distribucion:productoterminado:tiposproductos:view',
                    ],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/distribucion/productoterminado/tiposproductos/new"
                        permission={[
                          'app:general:configuracion:distribucion:productoterminado:tiposproductos:new',
                        ]}
                      />
                    ),
                  },
                  {
                    title: 'Productos terminados',
                    href:
                      '/app/configuracion/distribucion/productoterminado/productoterminado/PT',
                    permission: [
                      'app:general:configuracion:distribucion:productoterminado:productosterminados:view',
                    ],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/distribucion/productoterminado/productoterminado/PT/new"
                        permission={[
                          'app:general:configuracion:distribucion:productoterminado:productosterminados:new',
                        ]}
                      />
                    ),
                  },
                ],
              },
            ],
          },
          {
            title: 'Logística',
            href: '/app/configuracion/logistica',
            permission: [
              'app:general:configuracion:logistica:consecutivoslogistica:view',
              'app:general:configuracion:logistica:transaccioneslogistica:view',
              'app:general:configuracion:logistica:tiposcontenedores:view',
              'app:general:configuracion:logistica:contenedores:view',
              'app:general:configuracion:logistica:tipostransportes:view',
              'app:general:configuracion:logistica:clasificacionestransportadores:view',
              'app:general:configuracion:logistica:transportadores:view'],
            items: [
              {
                title: 'Consecutivos logística',
                href: '/app/configuracion/logistica/consecutivoslogistica',
                permission: ['app:general:configuracion:logistica:consecutivoslogistica:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/logistica/consecutivoslogistica/new"
                    permission={[
                      'app:general:configuracion:logistica:consecutivoslogistica:new',
                    ]}
                  />
                ),
              },
              {
                title: 'Transacciones logística',
                href: '/app/configuracion/logistica/transaccioneslogistica',
                permission: ['app:general:configuracion:logistica:transaccioneslogistica:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/logistica/transaccioneslogistica/new"
                    permission={[
                      'app:general:configuracion:logistica:transaccioneslogistica:new',
                    ]}
                  />
                ),
              },
              {
                title: 'Tipos contenedores',
                href: '/app/configuracion/logistica/tiposcontenedores',
                permission: ['app:general:configuracion:logistica:tiposcontenedores:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/logistica/tiposcontenedores/new"
                    permission={[
                      'app:general:configuracion:logistica:tiposcontenedores:new',
                    ]}
                  />
                ),
              },
              {
                title: 'Contenedores',
                href: '/app/configuracion/logistica/contenedores',
                permission: ['app:general:configuracion:logistica:contenedores:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/logistica/contenedores/new"
                    permission={[
                      'app:general:configuracion:logistica:contenedores:new',
                    ]}
                  />
                ),
              },
              {
                title: 'Tipos transportes',
                href: '/app/configuracion/logistica/tipostransportes',
                permission: [
                  'app:general:configuracion:logistica:tipostransportes:view',
                ],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/logistica/tipostransportes/new"
                    permission={[
                      'app:general:configuracion:logistica:tipostransportes:new',
                    ]}
                  />
                ),
              },
              {
                title: 'Clasificaciones transportadores',
                href:
                  '/app/configuracion/logistica/clasificacionestransportadores',
                permission: [
                  'app:general:configuracion:logistica:clasificacionestransportadores:view',
                ],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/logistica/clasificacionestransportadores/new"
                    permission={[
                      'app:general:configuracion:logistica:clasificacionestransportadores:new',
                    ]}
                  />
                ),
              },
              {
                title: 'Transportadores',
                href: '/app/configuracion/logistica/transportadores',
                permission: [
                  'app:general:configuracion:logistica:transportadores:view',
                ],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/logistica/transportadores/new"
                    permission={[
                      'app:general:configuracion:logistica:transportadores:new',
                    ]}
                  />
                ),
              },
            ],
          },
          {
            title: 'Contabilidad',
            href: '/app/configuracion/contabilidad',
            permission: [
              'app:general:configuracion:contabilidad:niif:configuracionempresa:view',
              'app:general:configuracion:contabilidad:niif:basesyporcentajesdetiposimpuestos:view',
              'app:general:configuracion:contabilidad:tributarios:tiposdeimpuestos:view',
              'app:general:configuracion:contabilidad:tributarios:tiposderetenciones:view',
              'app:general:configuracion:contabilidad:tributarios:impuestos:view',
              'app:general:configuracion:contabilidad:tributarios:retenciones:view',
              'app:general:configuracion:contabilidad:tributarios:clasificaciontributariaproducto:view',
              'app:general:configuracion:contabilidad:tributarios:clasificaciontributariagastos:view',
              'app:general:configuracion:contabilidad:tributarios:clasificaciontributariaservicios:view',
              'app:general:configuracion:contabilidad:tributarios:clasificaciontributariaactivo:view',
              'app:general:configuracion:contabilidad:tributarios:clasificaciontributaria:view',
              'app:general:configuracion:contabilidad:libroscontables:view',
            ],
            items: [
              {
                title: 'NIIF',
                href: '/app/contabilidad/niif',
                permission: [
                  'app:general:configuracion:contabilidad:niif:configuracionempresa:view',
                  'app:general:configuracion:contabilidad:niif:basesyporcentajesdetiposimpuestos:view',
                ],
                items: [
                  {
                    title: 'Configuración de empresa',
                    href:
                      '/app/configuracion/contabilidad/niif/configuracionempresa',
                    permission: [
                      'app:general:configuracion:contabilidad:niif:configuracionempresa:view',
                    ],
                  },
                  {
                    title: 'Bases y porcentajes de tipos de impuestos',
                    href:
                      '/app/configuracion/contabilidad/niif/basesporcentajesimpuestos',
                    permission: [
                      'app:general:configuracion:contabilidad:niif:basesyporcentajesdetiposimpuestos:view',
                    ],
                  },
                ],
              },
              {
                title: 'Tributarios',
                href: '/app/configuracion/contabilidad/tributarios',
                permission: [
                  'app:general:configuracion:contabilidad:tributarios:tiposdeimpuestos:view',
                  'app:general:configuracion:contabilidad:tributarios:tiposderetenciones:view',
                  'app:general:configuracion:contabilidad:tributarios:impuestos:view',
                  'app:general:configuracion:contabilidad:tributarios:retenciones:view',
                  'app:general:configuracion:contabilidad:tributarios:clasificaciontributariaproducto:view',
                  'app:general:configuracion:contabilidad:tributarios:clasificaciontributariagastos:view',
                  'app:general:configuracion:contabilidad:tributarios:clasificaciontributariaservicios:view',
                  'app:general:configuracion:contabilidad:tributarios:clasificaciontributariaactivo:view',
                  'app:general:configuracion:contabilidad:tributarios:clasificaciontributaria:view',
                  'app:general:configuracion:contabilidad:clasificacioncuentacontable:view',
                  'app:general:configuracion:contabilidad:catalogodecuentas:view',
                ],
                items: [
                  {
                    title: 'Tipos de impuestos',
                    href:
                      '/app/configuracion/contabilidad/tributarios/tiposimpuestos',
                    permission: [
                      'app:general:configuracion:contabilidad:tributarios:tiposdeimpuestos:view',
                    ],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/contabilidad/tributarios/tiposimpuestos/new"
                        permission={[
                          'app:general:configuracion:contabilidad:tributarios:tiposdeimpuestos:new',
                        ]}
                      />
                    ),
                  },
                  {
                    title: 'Tipos de retenciones',
                    href:
                      '/app/configuracion/contabilidad/tributarios/tiposretenciones',
                    permission: [
                      'app:general:configuracion:contabilidad:tributarios:tiposderetenciones:view',
                    ],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/contabilidad/tributarios/tiposretenciones/new"
                        permission={[
                          'app:general:configuracion:contabilidad:tributarios:tiposderetenciones:new',
                        ]}
                      />
                    ),
                  },
                  {
                    title: 'Impuestos',
                    href:
                      '/app/configuracion/contabilidad/tributarios/impuestos',
                    permission: [
                      'app:general:configuracion:contabilidad:tributarios:impuestos:view',
                    ],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/contabilidad/tributarios/impuestos/new"
                        permission={[
                          'app:general:configuracion:contabilidad:tributarios:impuestos:new',
                        ]}
                      />
                    ),
                  },
                  {
                    title: 'Retenciones',
                    href:
                      '/app/configuracion/contabilidad/tributarios/retenciones',
                    permission: [
                      'app:general:configuracion:contabilidad:tributarios:retenciones:view',
                    ],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/contabilidad/tributarios/retenciones/new"
                        permission={[
                          'app:general:configuracion:contabilidad:tributarios:retenciones:new',
                        ]}
                      />
                    ),
                  },
                  {
                    title: 'Clasificación tributaria producto',
                    href:
                      '/app/configuracion/contabilidad/tributarios/clasificaciontributariaproducto',
                    permission: [
                      'app:general:configuracion:contabilidad:tributarios:clasificaciontributariaproducto:view',
                    ],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/contabilidad/tributarios/clasificaciontributariaproducto/new"
                      // Falta por permiso
                      />
                    ),
                  },
                  {
                    title: 'Clasificación tributaria gasto',
                    href:
                      '/app/configuracion/contabilidad/tributarios/clasificaciontributariagasto',
                    permission: [
                      'app:general:configuracion:contabilidad:tributarios:clasificaciontributariagastos:view',
                    ],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/contabilidad/tributarios/clasificaciontributariagasto/new"
                      // Falta por permiso
                      />
                    ),
                  },
                  {
                    title: 'Clasificación tributaria servicio',
                    href:
                      '/app/configuracion/contabilidad/tributarios/clasificaciontributariaservicio',
                    permission: [
                      'app:general:configuracion:contabilidad:tributarios:clasificaciontributariaservicios:view',
                    ],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/contabilidad/tributarios/clasificaciontributariaservicio/new"
                      // Falta por permiso
                      />
                    ),
                  },
                  {
                    title: 'Clasificación tributaria activo',
                    href:
                      '/app/configuracion/contabilidad/tributarios/clasificaciontributariaactivo',
                    permission: [
                      'app:general:configuracion:contabilidad:tributarios:clasificaciontributariaactivo:view',
                    ],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/contabilidad/tributarios/clasificaciontributariaactivo/new"
                      // Falta por permiso
                      />
                    ),
                  },
                  {
                    title: 'Clasificación tributaria',
                    href:
                      '/app/configuracion/general/tributarios/clasificacionTributariaTercero',
                    permission: [
                      'app:general:configuracion:contabilidad:tributarios:clasificaciontributaria:view',
                    ],
                  },
                ],
              },
              {
                title: 'Consecutivos contabilidad',
                href: '/app/configuracion/contabilidad/consecutivos',
                permission: ['configuracion:contabilidad:consecutivos:list'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/contabilidad/consecutivos/new"
                    permission={[
                      'configuracion:contabilidad:consecutivos:create',
                    ]}
                  />
                ),
              },
              {
                title: 'Transacciones contabilidad',
                href: '/app/configuracion/contabilidad/documentoscontabilidad',
                permission: ['configuracion:contabilidad:documentos:list'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/contabilidad/documentoscontabilidad/new"
                    permission={[
                      'configuracion:contabilidad:documentos:create',
                    ]}
                  />
                ),
              },
              {
                title: 'Configuración Contable Empresa',
                href: '/app/configuracion/contabilidad/configuracioncontable',
                permission: ['configuracion:configuracion:list'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/contabilidad/configuracioncontable"
                  // Falta permisos
                  />
                ),
              },
              {
                title: 'Clasificación cuenta contable',
                href:
                  '/app/configuracion/contabilidad/clasificacioncuentacontable',
                permission: [
                  'app:general:configuracion:contabilidad:clasificacioncuentacontable:view',
                ],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/contabilidad/clasificacioncuentacontable/new"
                    permission={[
                      'app:general:configuracion:contabilidad:clasificacioncuentacontable:new',
                    ]}
                  />
                ),
              },
              {
                title: 'Catálogo de cuentas',
                href: '/app/configuracion/contabilidad/catalogoscuentas',
                permission: [
                  'app:general:configuracion:contabilidad:catalogodecuentas:view',
                ],
              },
              {
                title: 'Actividades económicas',
                href: '/app/configuracion/contabilidad/actividadeseconomicas',
                permission: [
                  'app:general:configuracion:contabilidad:actividadeseconomicas:view',
                ],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/contabilidad/actividadeseconomicas/new"
                    permission={[
                      'app:general:configuracion:contabilidad:actividadeseconomicas:new',
                    ]}
                  />
                ),
              },
              {
                title: 'Clasificación jurídica',
                href:
                  '/app/configuracion/contabilidad/clasificacionesjuridicas',
                permission: [
                  'app:general:configuracion:contabilidad:clasificacionjuridica:view',
                ],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/contabilidad/clasificacionesjuridicas/new"
                    permission={[
                      'app:general:configuracion:contabilidad:clasificacionjuridica:new',
                    ]}
                  />
                ),
              },
              {
                title: 'Responsabilidades fiscales',
                href:
                  '/app/configuracion/contabilidad/responsabilidadesFiscales',
                permission: [
                  'app:general:configuracion:contabilidad:responsabilidadesfiscales:view',
                ],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/contabilidad/responsabilidadesFiscales/new"
                    permission={[
                      'app:general:configuracion:contabilidad:responsabilidadesfiscales:new',
                    ]}
                  />
                ),
              },
              {
                title: 'Responsabilidades tributarias',
                href:
                  '/app/configuracion/contabilidad/responsabilidadestributarias',
                permission: [
                  'app:general:configuracion:contabilidad:responsabilidadestributarias:view',
                ],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/contabilidad/responsabilidadestributarias/new"
                    permission={[
                      'app:general:configuracion:contabilidad:responsabilidadestributarias:new',
                    ]}
                  />
                ),
              },
              {
                title: 'Rubro',
                href: '/app/configuracion/contabilidad/rubros',
                permission: ['configuracion:contabilidad:rubros:list'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/contabilidad/rubros/new"
                    permission={['configuracion:contabilidad:rubros:create']}
                  />
                ),
              },
              {
                title: 'Uvt',
                href: '/app/configuracion/contabilidad/uvt',
                permission: ['configuracion:contabilidad:uvt:list'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/contabilidad/uvt/new"
                    permission={['configuracion:contabilidad:uvt:create']}
                  />
                ),
              },
              {
                title: 'Centros de costos',
                href: '/app/configuracion/contabilidad/centroscostos',
                permission: ['app:general:configuracion:contabilidad:centrosdecostos:view'],
              },
              {
                title: 'Centros de negocios',
                href: '/app/configuracion/contabilidad/centrosnegocios',
                permission: ['app:general:configuracion:contabilidad:centrosdenegocios:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/contabilidad/centrosnegocios/new"
                    permission={[
                      'app:general:configuracion:contabilidad:centrosdenegocios:new',
                    ]}
                  />
                ),
              },
              {
                title: 'Grupos unidades de negocios',
                href: '/app/configuracion/contabilidad/gruposunidadesnegocios',
                permission: [
                  'configuracion:contabilidad:gruposunidadesnegocios:list',
                ],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/contabilidad/gruposunidadesnegocios/new"
                    permission={[
                      'configuracion:contabilidad:documentos:create',
                    ]}
                  />
                ),
              },
              {
                title: 'Unidades de negocios',
                href: '/app/configuracion/contabilidad/unidadesnegocios',
                permission: [
                  'configuracion:contabilidad:unidadesnegocios:list',
                ],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/contabilidad/unidadesnegocios/new"
                    permission={[
                      'configuracion:contabilidad:unidadesnegocios:create',
                    ]}
                  />
                ),
              },
              {
                title: 'Configuración estado financiero',
                href:
                  '/app/configuracion/contabilidad/configuracionestadosfinancieros',
                permission: [
                  'configuracion:contabilidad:configuracionesestadosfinancieros:list',
                ],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/contabilidad/configuracionestadosfinancieros/new"
                    permission={[
                      'configuracion:contabilidad:configuracionesestadosfinancieros:create',
                    ]}
                  />
                ),
              },
              {
                title: 'Libros contables',
                href: '/app/configuracion/contabilidad/libroscontables',
                permission: ['app:general:configuracion:contabilidad:libroscontables:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/contabilidad/libroscontables/new"
                    permission={[
                      'app:general:configuracion:contabilidad:libroscontables:new',
                    ]}
                  />
                ),
              },
            ],
          },
          {
            title: 'Tesorería',
            href: '/app/configuracion/tesoreria',
            permission: [
              'app:general:configuracion:tesoreria:consecutivostesoreria:view',
              'app:general:configuracion:tesoreria:transaccionestesoreria:view',
              'app:general:configuracion:tesoreria:bancos:view',
              'app:general:configuracion:tesoreria:gastos:view',
              'app:general:configuracion:tesoreria:tiposdenotacredito:view',
              'app:general:configuracion:tesoreria:tiposdenotadebito:view',
              'app:general:configuracion:tesoreria:monedas:view',
              'app:general:configuracion:tesoreria:cuentasbancarias:view',
              'app:general:configuracion:tesoreria:formasdepago:view',
              'app:general:configuracion:tesoreria:mediosdepago:view',
            ],
            items: [
              {
                title: 'Consecutivos tesorería',
                href: '/app/configuracion/tesoreria/consecutivos',
                permission: ['app:general:configuracion:tesoreria:consecutivostesoreria:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/tesoreria/consecutivos/new"
                    permission={['app:general:configuracion:tesoreria:consecutivostesoreria:new']}
                  />
                ),
              },
              {
                title: 'Transacciones tesorería',
                href: '/app/configuracion/tesoreria/documentostesoreria',
                permission: [
                  'app:general:configuracion:tesoreria:transaccionestesoreria:view',
                ],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/tesoreria/documentostesoreria/new"
                    permission={[
                      'app:general:configuracion:tesoreria:transaccionestesoreria:new',
                    ]}
                  />
                ),
              },
              {
                title: 'Bancos',
                href: '/app/configuracion/tesoreria/bancos',
                permission: ['app:general:configuracion:tesoreria:bancos:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/tesoreria/bancos/new"
                    permission={['app:general:configuracion:tesoreria:bancos:new']}
                  />
                ),
              },
              {
                title: 'Frecuencias de pagos',
                href:
                  '/app/configuracion/tesoreria/frecuenciasdepagos',
                permission: ['hrm:maestros:frecuenciaspagos:list'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/tesoreria/frecuenciasdepagos/new"
                    permission={['hrm:maestros:frecuenciaspagos:create']}
                  />
                ),
              },
              {
                title: 'Cajas',
                href: '/app/configuracion/tesoreria/cajas',
                permission: ['configuracion:tesoreria:cajas:list'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/tesoreria/cajas/new"
                    permission={['configuracion:tesoreria:cajas:create']}
                  />
                ),
              },
              {
                title: 'Clasificaciones de bancos',
                href: '/app/configuracion/tesoreria/clasificacionbanco',
                permission: [
                  'configuracion:tesoreria:clasificacionesbancos:list',
                ],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/tesoreria/clasificacionbanco/new"
                    permission={[
                      'configuracion:tesoreria:clasificacionesbancos:create',
                    ]}
                  />
                ),
              },
              {
                title: 'Clasificaciones gastos',
                href: '/app/configuracion/tesoreria/clasificacionesgastos',
                permission: [
                  'configuracion:scm:compra:clasificacionesgastos:list',
                ],
                info: () => (
                  <RouterLink to="/app/configuracion/tesoreria/clasificacionesgastos/new">
                    <IconButton>
                      <PlusIcon />
                    </IconButton>
                  </RouterLink>
                ),
              },
              {
                title: 'Gastos',
                href: '/app/configuracion/tesoreria/gastos',
                permission: ['app:general:configuracion:tesoreria:gastos:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/tesoreria/gastos/new"
                    permission={['app:general:configuracion:tesoreria:gastos:new']}
                  />
                ),
              },
              {
                title: 'Cuentas bancarias',
                href: '/app/configuracion/tesoreria/cuentasbancarias',
                permission: ['app:general:configuracion:tesoreria:cuentasbancarias:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/tesoreria/cuentasbancarias/new"
                    permission={[
                      'app:general:configuracion:tesoreria:cuentasbancarias:new',
                    ]}
                  />
                ),
              },
              {
                title: 'Grupos de conceptos tesorería',
                href: '/app/configuracion/tesoreria/grupoconceptotesoreria',
                permission: [
                  'configuracion:tesoreria:gruposconceptostesoreria:list',
                ],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/tesoreria/grupoconceptotesoreria/new"
                    permission={[
                      'configuracion:tesoreria:gruposconceptostesoreria:create',
                    ]}
                  />
                ),
              },
              {
                title: 'Conceptos tesorería',
                href: '/app/configuracion/tesoreria/conceptostesoreria',
                permission: ['configuracion:tesoreria:conceptostesoreria:list'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/tesoreria/conceptostesoreria/new"
                    permission={[
                      'configuracion:tesoreria:conceptostesoreria:create',
                    ]}
                  />
                ),
              },
              {
                title: 'Formas de pago',
                href: '/app/configuracion/tesoreria/formaspago',
                permission: ['app:general:configuracion:tesoreria:formasdepago:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/tesoreria/formaspago/new"
                    permission={['app:general:configuracion:tesoreria:formasdepago:new']}
                  />
                ),
              },
              {
                title: 'Medios de pago',
                href: '/app/configuracion/tesoreria/mediospago',
                permission: ['app:general:configuracion:tesoreria:mediosdepago:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/tesoreria/mediospago/new"
                    permission={['app:general:configuracion:tesoreria:mediosdepago:new']}
                  />
                ),
              },
              {
                title: 'Monedas',
                href: '/app/configuracion/tesoreria/monedas',
                permission: ['app:general:configuracion:tesoreria:monedas:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/tesoreria/monedas/new"
                    permission={['app:general:configuracion:tesoreria:monedas:new']}
                  />
                ),
              },
              {
                title: 'Tarjetas de crédito',
                href: '/app/configuracion/tesoreria/tarjetascredito',
                permission: ['configuracion:tesoreria:tarjetascredito:list'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/tesoreria/tarjetascredito/new"
                    permission={[
                      'configuracion:tesoreria:tarjetascredito:create',
                    ]}
                  />
                ),
              },
              {
                title: 'Tipos de nota crédito',
                href: '/app/configuracion/tesoreria/tiposnotacredito',
                permission: ['app:general:configuracion:tesoreria:tiposdenotacredito:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/tesoreria/tiposnotacredito/new"
                    permission={[
                      'app:general:configuracion:tesoreria:tiposdenotacredito:new',
                    ]}
                  />
                ),
              },
              {
                title: 'Tipos de nota débito',
                href: '/app/configuracion/tesoreria/tiposnotadebito',
                permission: ['app:general:configuracion:tesoreria:tiposdenotadebito:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/tesoreria/tiposnotadebito/new"
                    permission={[
                      'app:general:configuracion:tesoreria:tiposdenotadebito:new',
                    ]}
                  />
                ),
              },
              {
                title: 'Tipos de documentos electrónicos',
                href: '/app/configuracion/tesoreria/tiposdedocumentoselectronicos',
                permission: ['app:general:configuracion:tesoreria:tiposdedocumentoselectronicos:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/tesoreria/tiposdedocumentoselectronicos/new"
                    permission={[
                      'app:general:configuracion:tesoreria:tiposdedocumentoselectronicos:new',
                    ]}
                  />
                ),
              },
              {
                title: 'Tipos de cuentas bancarias',
                href: '/app/configuracion/tesoreria/tiposcuentasbancarias',
                permission: ['app:general:configuracion:tesoreria:tiposdecuentasbancarias:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/tesoreria/tiposcuentasbancarias/new"
                    permission={[
                      'app:general:configuracion:tesoreria:tiposdecuentasbancarias:new',
                    ]}
                  />
                ),
              },
              {
                title: 'Tipos de pagos',
                href: '/app/configuracion/tesoreria/tipospagos',
                permission: ['app:general:configuracion:tesoreria:tiposdepagos:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/tesoreria/tipospagos/new"
                    permission={['app:general:configuracion:tesoreria:tiposdepagos:new']}
                  />
                ),
              },
              {
                title: 'Tipos de tasas',
                href: '/app/configuracion/tesoreria/tipostasas',
                permission: ['configuracion:tesoreria:tipostasas:list'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/tesoreria/tipostasas/new"
                    permission={['configuracion:tesoreria:tipostasas:create']}
                  />
                ),
              },
              {
                title: 'Categorización gastos',
                href: '/app/configuracion/tesoreria/categorizaciongastos',
                permission: ['app:general:configuracion:tesoreria:categorizaciongastos:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/tesoreria/categorizaciongastos/new"
                    permission={[
                      'app:general:configuracion:tesoreria:categorizaciongastos:new',
                    ]}
                  />
                ),
              },
              {
                title: 'Costos',
                href: '/app/configuracion/tesoreria/costos',
                permission: ['configuracion:general:costos:list'],
                info: () => (
                  <RouterLink to="/app/configuracion/tesoreria/costos/new">
                    <IconButton>
                      <PlusIcon />
                    </IconButton>
                  </RouterLink>
                ),
              },
            ],
          },
          {
            title: 'Gestión activos fijos',
            href: '/app/configuracion/gestionactivosfijos',
            permission: [
              'app:general:configuracion:gestionactivosfijos:consecutivosgestionactivos:view',
              'app:general:configuracion:gestionactivosfijos:transaccionesgestionactivos:view',
              'app:general:configuracion:gestionactivosfijos:tiposactivos:view',
              'app:general:configuracion:gestionactivosfijos:categoriasactivos:view',
              'app:general:configuracion:gestionactivosfijos:gruposactivos:view',
              'app:general:configuracion:gestionactivosfijos:activos:view',
              'app:general:configuracion:gestionactivosfijos:metodosdedepreciacion:view',
              'app:general:configuracion:gestionactivosfijos:mantenimientos:tiposmantenimientos:view',
            ],
            items: [
              {
                title: 'Consecutivos gestión activos',
                href:
                  '/app/configuracion/gestionactivosfijos/consecutivosgestionactivos',
                permission: ['app:general:configuracion:gestionactivosfijos:consecutivosgestionactivos:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/gestionactivosfijos/consecutivosgestionactivos/new"
                    permission={[
                      'app:general:configuracion:gestionactivosfijos:consecutivosgestionactivos:new',
                    ]}
                  />
                ),
              },
              {
                title: 'Transacciones gestión activos',
                href:
                  '/app/configuracion/gestionactivosfijos/transaccionesgestionactivos',
                permission: ['app:general:configuracion:gestionactivosfijos:transaccionesgestionactivos:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/gestionactivosfijos/transaccionesgestionactivos/new"
                    permission={[
                      'app:general:configuracion:gestionactivosfijos:transaccionesgestionactivos:new',
                    ]}
                  />
                ),
              },
              {
                title: 'Tipos activos',
                href: '/app/configuracion/gestionactivosfijos/tiposactivos',
                permission: ['app:general:configuracion:gestionactivosfijos:tiposactivos:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/gestionactivosfijos/tiposactivos/new"
                    permission={[
                      'app:general:configuracion:gestionactivosfijos:tiposactivos:new',
                    ]}
                  />
                ),
              },
              {
                title: 'Categorías activos',
                href:
                  '/app/configuracion/gestionactivosfijos/categoriasactivos',
                permission: ['app:general:configuracion:gestionactivosfijos:categoriasactivos:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/gestionactivosfijos/categoriasactivos/new"
                    permission={[
                      'app:general:configuracion:gestionactivosfijos:categoriasactivos:new',
                    ]}
                  />
                ),
              },
              {
                title: 'Grupos activos',
                href: '/app/configuracion/gestionactivosfijos/gruposactivos',
                permission: ['app:general:configuracion:gestionactivosfijos:gruposactivos:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/gestionactivosfijos/gruposactivos/new"
                    permission={[
                      'app:general:configuracion:gestionactivosfijos:gruposactivos:new',
                    ]}
                  />
                ),
              },
              {
                title: 'Activos',
                href: '/app/configuracion/gestionactivosfijos/activos',
                permission: ['app:general:configuracion:gestionactivosfijos:activos:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/gestionactivosfijos/activos/new"
                    permission={[
                      'app:general:configuracion:gestionactivosfijos:activos:new',
                    ]}
                  />
                ),
              },
              {
                title: 'Métodos de depreciación',
                href:
                  '/app/configuracion/gestionactivosfijos/metodosdepreciacion',
                permission: [
                  'app:general:configuracion:gestionactivosfijos:metodosdedepreciacion:view',
                ],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/gestionactivosfijos/metodosdepreciacion/new"
                    permission={[
                      'app:general:configuracion:gestionactivosfijos:metodosdedepreciacion:new',
                    ]}
                  />
                ),
              },
              {
                title: 'Mantenimientos',
                href: '/app/configuracion/gestionactivosfijos/mantenimientos',
                items: [
                  {
                    title: 'Tipos mantenimientos',
                    href:
                      '/app/configuracion/gestionactivosfijos/mantenimientos/tiposmantenimientos',
                    permission: [
                      'app:general:configuracion:gestionactivosfijos:mantenimientos:tiposmantenimientos:view',
                    ],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/gestionactivosfijos/mantenimientos/tiposmantenimientos/new"
                        permission={[
                          'app:general:configuracion:gestionactivosfijos:mantenimientos:tiposmantenimientos:new',
                        ]}
                      />
                    ),
                  },
                ],
              },
            ],
          },
          {
            title: 'Gestión financiera',
            href: '/app/configuracion/gestionfinanciera',
            permission: ['app:general:gestionfinanciera:view'],
            items: [],
          },
          {
            title: 'Recursos humanos  (RRHH)',
            href: '/app/configuracion/recursoshumanos',
            permission: [
              'app:general:configuracion:recursoshumanos:consecutivosrrhh:view',
              'app:general:configuracion:recursoshumanos:transaccionesrrhh:view',
              'app:general:configuracion:recursoshumanos:generales:tiposempleados:view',
              'app:general:configuracion:recursoshumanos:generales:subtipoempleado:view',
              'app:general:configuracion:recursoshumanos:generales:niveleseducativos:view',
              'app:general:configuracion:recursoshumanos:generales:clasesderiesgoarl:view',
              'app:general:configuracion:recursoshumanos:generales:altoriesgo:view',
              'app:general:configuracion:recursoshumanos:generales:eps:view',
              'app:general:configuracion:recursoshumanos:generales:tiposdecontratos:view',
              'app:general:configuracion:recursoshumanos:generales:tiposrestricciones:view',
              'app:general:configuracion:recursoshumanos:generales:restriccioneslaborales:view',
              'app:general:configuracion:recursoshumanos:generales:empleados:view',
              'app:general:configuracion:recursoshumanos:gestiondeltalento:cargos:view',
              'app:general:configuracion:recursoshumanos:gestiondeltalento:requisitoscargos:view',
              'app:general:configuracion:recursoshumanos:gestiondeltalento:filtrosgestiondeltalento:view',
              'app:general:configuracion:recursoshumanos:gestiondeltalento:pruebasdeseleccion:view',
              'app:general:configuracion:recursoshumanos:gestiondeltalento:examenesmedicos:view',
              'app:general:configuracion:recursoshumanos:gestiondeltalento:catalogodehabilidades:view',
              'app:general:configuracion:recursoshumanos:gestiondeltalento:catalogodecompetencias:view',
              'app:general:configuracion:recursoshumanos:gestiondeltalento:entrevistas:view',
              'app:general:configuracion:recursoshumanos:gestiondeltalento:candidatos:view',
              'app:general:configuracion:recursoshumanos:seguimientoyretencion:requisitoscontratacionrrhh:view',
              'app:general:configuracion:recursoshumanos:seguimientoyretencion:tiposcapacitaciones:view',
              'app:general:configuracion:recursoshumanos:seguimientoyretencion:temascapacitaciones:view',
              'app:general:configuracion:recursoshumanos:'
              + 'seguimientoyretencion:encuestasevaluacioncapacitaciones:view',
              'app:general:configuracion:recursoshumanos:procesosdisciplinarios:disciplinarios:view',
              'app:general:configuracion:recursoshumanos:bienestarlaboral:tiposbeneficios:view',
              'app:general:configuracion:recursoshumanos:bienestarlaboral:categoriasbeneficios:view',
              'app:general:configuracion:recursoshumanos:bienestarlaboral:beneficios:view',
              'app:general:configuracion:recursoshumanos:bienestarlaboral:tipodebonificacion:view',
              'app:general:configuracion:recursoshumanos:gestiondeltiempo:unidadesdetiempo:view',
              'app:general:configuracion:recursoshumanos:gestiondeltiempo:recesos:view',
              'app:general:configuracion:recursoshumanos:gestiondeltiempo:tiposturnos:view',
              'app:general:configuracion:recursoshumanos:gestiondeltiempo:turnos:view',
              'app:general:configuracion:recursoshumanos:gestiondeltiempo:equiposdetrabajo:view',
              'app:general:configuracion:recursoshumanos:gestiondeltiempo:proyectos:view',
              'app:general:configuracion:recursoshumanos:gestiondeltiempo:hitos:view',
              'app:general:configuracion:recursoshumanos:gestiondeltiempo:tipodehoraextra:view',
              'app:general:configuracion:recursoshumanos:gestiondeltiempo:tiposmodalidadesdetrabajo:view',
              'app:general:configuracion:recursoshumanos:gestiondeltiempo:modalidadesdetrabajo:view',
              'app:general:configuracion:recursoshumanos:gestiondeltiempo:ausencias:tipospermisos:view',
              'app:general:configuracion:recursoshumanos:gestiondeltiempo:ausencias:licencias:view',
              'app:general:configuracion:recursoshumanos:gestiondeltiempo:ausencias:tipodeincapacidad:view',
              'app:general:configuracion:recursoshumanos:nomina:conceptosnomina:view',
              'app:general:configuracion:recursoshumanos:nomina:smlvauxiliodetransporte:view',
              'app:general:configuracion:recursoshumanos:nomina:prestacionessociales:view',
              'app:general:configuracion:recursoshumanos:nomina:frecuenciasdepagos:view',
              'app:general:configuracion:recursoshumanos:nomina:tiposdeducciones:view',
              'app:general:configuracion:recursoshumanos:nomina:deducciones:view',
              'app:general:configuracion:recursoshumanos:pila:tiposdecotizantes:view',
              'app:general:configuracion:recursoshumanos:pila:tiposdeplantillas:view',
              'app:general:configuracion:recursoshumanos:pila:clasesaportantes:view',
              'app:general:configuracion:recursoshumanos:desvinculacionlaboral:requisitospazysalvo:view',
              'app:general:configuracion:recursoshumanos:desvinculacionlaboral:pazysalvo:view',
              'app:general:configuracion:recursoshumanos:saludyseguridadeneltrabajo:consecutivossst:view',
              'app:general:configuracion:recursoshumanos:saludyseguridadeneltrabajo:transaccionessst:view',
              'app:general:configuracion:recursoshumanos:saludyseguridadeneltrabajo:tipoderiesgos:view',
              'app:general:configuracion:recursoshumanos:saludyseguridadeneltrabajo:clasificacionderiesgos:view',
              'app:general:configuracion:recursoshumanos:saludyseguridadeneltrabajo:probabilidadderiesgos:view',
              'app:general:configuracion:recursoshumanos:saludyseguridadeneltrabajo:impactoderiesgos:view',
              'app:general:configuracion:recursoshumanos:saludyseguridadeneltrabajo:tipoequipodetrabajo:view',
              'app:general:configuracion:recursoshumanos:'
              + 'saludyseguridadeneltrabajo:elementosdeproteccionprofesional:view',
              'app:general:configuracion:recursoshumanos:certificados:view',
              'app:general:configuracion:recursoshumanos:provisiones:view',
            ],
            items: [
              {
                title: 'Consecutivos RRHH',
                href: '/app/configuracion/recursoshumanos/consecutivosrrhh',
                permission: [
                  'app:general:configuracion:recursoshumanos:consecutivosrrhh:view',
                ],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/recursoshumanos/consecutivosrrhh/new"
                    permission={[
                      'app:general:configuracion:recursoshumanos:consecutivosrrhh:new',
                    ]}
                  />
                ),
              },
              {
                title: 'Transacciones RRHH',
                href: '/app/configuracion/recursoshumanos/transaccionesrrhh',
                permission: [
                  'app:general:configuracion:recursoshumanos:transaccionesrrhh:view',
                ],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/recursoshumanos/transaccionesrrhh/new"
                    permission={[
                      'app:general:configuracion:recursoshumanos:transaccionesrrhh:new',
                    ]}
                  />
                ),
              },
              {
                title: 'Generales',
                href: '/app/configuracion/recursoshumanos/generales',
                items: [
                  {
                    title: 'Tipos empleados',
                    href:
                      '/app/configuracion/recursoshumanos/generales/tiposempleados',
                    permission: ['app:general:configuracion:recursoshumanos:generales:tiposempleados:view'],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/recursoshumanos/generales/tiposempleados/new"
                        permission={[
                          'app:general:configuracion:recursoshumanos:generales:tiposempleados:new',
                        ]}
                      />
                    ),
                  },
                  {
                    title: 'Sub tipo empleado',
                    href:
                      '/app/configuracion/recursoshumanos/generales/subtipoempleado',
                    permission: ['app:general:configuracion:recursoshumanos:generales:subtipoempleado:view'],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/recursoshumanos/generales/subtipoempleado/new"
                        permission={['app:general:configuracion:recursoshumanos:generales:subtipoempleado:new']}
                      />
                    ),
                  },
                  {
                    title: 'Niveles educativos',
                    href:
                      '/app/configuracion/recursoshumanos/generales/niveleseducativos',
                    permission: [
                      'app:general:configuracion:recursoshumanos:generales:niveleseducativos:view',
                    ],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/recursoshumanos/generales/niveleseducativos/new"
                        permission={[
                          'app:general:configuracion:recursoshumanos:generales:niveleseducativos:new',
                        ]}
                      />
                    ),
                  },
                  {
                    title: 'Clases de riesgo ARL',
                    href:
                      '/app/configuracion/recursoshumanos/generales/clasesderiesgoarl',
                    permission: ['app:general:configuracion:recursoshumanos:generales:clasesderiesgoarl:view'],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/recursoshumanos/generales/clasesderiesgoarl/new"
                        permission={['app:general:configuracion:recursoshumanos:generales:clasesderiesgoarl:new']}
                      />
                    ),
                  },
                  {
                    title: 'Alto riesgo',
                    href:
                      '/app/configuracion/recursoshumanos/generales/altoriesgo',
                    permission: ['app:general:configuracion:recursoshumanos:generales:altoriesgo:view'],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/recursoshumanos/generales/altoriesgo/new"
                        permission={['app:general:configuracion:recursoshumanos:generales:altoriesgo:new']}
                      />
                    ),
                  },
                  {
                    title: 'Eps',
                    href: '/app/configuracion/recursoshumanos/generales/eps',
                    permission: [
                      'app:general:configuracion:recursoshumanos:generales:eps:view',
                    ],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/recursoshumanos/generales/eps/new"
                        permission={['app:general:configuracion:recursoshumanos:generales:eps:new']}
                      />
                    ),
                  },
                  {
                    title: 'Tipos de contratos',
                    href:
                      '/app/configuracion/recursoshumanos/generales/tiposdecontratos',
                    permission: ['app:general:configuracion:recursoshumanos:generales:tiposdecontratos:view'],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/recursoshumanos/generales/tiposdecontratos/new"
                        permission={[
                          'app:general:configuracion:recursoshumanos:generales:tiposdecontratos:new',
                        ]}
                      />
                    ),
                  },
                  {
                    title: 'Tipos restricciones',
                    href:
                      '/app/configuracion/recursoshumanos/generales/tiposrestricciones',
                    permission: ['app:general:configuracion:recursoshumanos:generales:tiposrestricciones:view'],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/recursoshumanos/generales/tiposrestricciones/new"
                        permission={[
                          'app:general:configuracion:recursoshumanos:generales:tiposrestricciones:new',
                        ]}
                      />
                    ),
                  },
                  {
                    title: 'Restricciones laborales',
                    href:
                      '/app/configuracion/recursoshumanos/generales/restriccioneslaborales',
                    permission: [
                      'app:general:configuracion:recursoshumanos:generales:restriccioneslaborales:view',
                    ],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/recursoshumanos/generales/restriccioneslaborales/new"
                        permission={[
                          'app:general:configuracion:recursoshumanos:generales:restriccioneslaborales:new',
                        ]}
                      />
                    ),
                  },
                  {
                    title: 'Empleados',
                    href:
                      '/app/configuracion/recursoshumanos/generales/empleados',
                    permission: ['app:general:configuracion:recursoshumanos:generales:empleados:view'],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/recursoshumanos/generales/empleados/new"
                        permission={['app:general:configuracion:recursoshumanos:generales:empleados:new']}
                      />
                    ),
                  },
                  /*
                  {
                    title: 'Tipos de afiliación',
                    href: '/app/configuracion/rrhh/gestiondeltalento/tipoafiliacion',
                    permission: ['configuracion:rpdental:tiposafiliacioneseps:list'],
                    info: () => (
                      <RouterLink to="/app/configuracion/rrhh/gestiondeltalento/tipoafiliacion/new">
                        <IconButton>
                          <PlusIcon />
                        </IconButton>
                      </RouterLink>
                    ),
                  },
                  */
                ],
              },
              {
                title: 'Gestión del talento',
                href: '/app/configuracion/recursoshumanos/gestiondeltalento',
                permission: [
                  'app:general:configuracion:recursoshumanos:gestiondeltalento:cargos:view',
                  'app:general:configuracion:recursoshumanos:gestiondeltalento:requisitoscargos:view',
                  'app:general:configuracion:recursoshumanos:gestiondeltalento:filtrosgestiondeltalento:view',
                  'app:general:configuracion:recursoshumanos:gestiondeltalento:pruebasdeseleccion:view',
                  'app:general:configuracion:recursoshumanos:gestiondeltalento:examenesmedicos:view',
                  'app:general:configuracion:recursoshumanos:gestiondeltalento:catalogodehabilidades:view',
                  'app:general:configuracion:recursoshumanos:gestiondeltalento:catalogodecompetencias:view',
                  'app:general:configuracion:recursoshumanos:gestiondeltalento:entrevistas:view',
                  'app:general:configuracion:recursoshumanos:gestiondeltalento:candidatos:view',
                ],
                items: [
                  {
                    title: 'Cargos',
                    href:
                      '/app/configuracion/recursoshumanos/gestiondeltalento/cargos',
                    permission: ['app:general:configuracion:recursoshumanos:gestiondeltalento:cargos:view'],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/recursoshumanos/gestiondeltalento/cargos/new"
                        permission={['app:general:configuracion:recursoshumanos:gestiondeltalento:cargos:new']}
                      />
                    ),
                  },
                  {
                    title: 'Requisitos cargos',
                    href:
                      '/app/configuracion/recursoshumanos/gestiondeltalento/requisitoscargos',
                    permission: [
                      'app:general:configuracion:recursoshumanos:gestiondeltalento:requisitoscargos:view',
                    ],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/recursoshumanos/gestiondeltalento/requisitoscargos/new"
                        permission={[
                          'app:general:configuracion:recursoshumanos:gestiondeltalento:requisitoscargos:new',
                        ]}
                      />
                    ),
                  },
                  {
                    title: 'Filtros gestión del talento',
                    href:
                      '/app/configuracion/recursoshumanos/gestiondeltalento/filtrosgestiondeltalento',
                    permission: [
                      'app:general:configuracion:recursoshumanos:gestiondeltalento:filtrosgestiondeltalento:view',
                    ],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/recursoshumanos/gestiondeltalento/filtrosgestiondeltalento/new"
                        permission={[
                          'app:general:configuracion:recursoshumanos:gestiondeltalento:filtrosgestiondeltalento:new',
                        ]}
                      />
                    ),
                  },
                  {
                    title: 'Pruebas de selección',
                    href:
                      '/app/configuracion/recursoshumanos/gestiondeltalento/pruebasdeseleccion',
                    permission: [
                      'app:general:configuracion:recursoshumanos:gestiondeltalento:pruebasdeseleccion:view',
                    ],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/recursoshumanos/gestiondeltalento/pruebasdeseleccion/new"
                        permission={[
                          'app:general:configuracion:recursoshumanos:gestiondeltalento:pruebasdeseleccion:new',
                        ]}
                      />
                    ),
                  },
                  {
                    title: 'Exámenes médicos',
                    href:
                      '/app/configuracion/recursoshumanos/gestiondeltalento/examenesmedicos',
                    permission: [
                      'app:general:configuracion:recursoshumanos:gestiondeltalento:examenesmedicos:view',
                    ],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/recursoshumanos/gestiondeltalento/examenesmedicos/new"
                        permission={[
                          'app:general:configuracion:recursoshumanos:gestiondeltalento:examenesmedicos:new',
                        ]}
                      />
                    ),
                  },
                  {
                    title: 'Catálogo de habilidades',
                    href:
                      '/app/configuracion/recursoshumanos/gestiondeltalento/catalogodehabilidades',
                    permission: [
                      'app:general:configuracion:recursoshumanos:gestiondeltalento:catalogodehabilidades:view',
                    ],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/recursoshumanos/gestiondeltalento/catalogodehabilidades/new"
                        permission={[
                          'app:general:configuracion:recursoshumanos:gestiondeltalento:catalogodehabilidades:new',
                        ]}
                      />
                    ),
                  },
                  {
                    title: 'Catálogo de competencias',
                    href:
                      '/app/configuracion/recursoshumanos/gestiondeltalento/catalogodecompetencias',
                    permission: [
                      'app:general:configuracion:recursoshumanos:gestiondeltalento:catalogodecompetencias:view',
                    ],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/recursoshumanos/gestiondeltalento/catalogodecompetencias/new"
                        permission={[
                          'app:general:configuracion:recursoshumanos:gestiondeltalento:catalogodecompetencias:new',
                        ]}
                      />
                    ),
                  },
                  {
                    title: 'Entrevistas',
                    href:
                      '/app/configuracion/recursoshumanos/gestiondeltalento/entrevistas',
                    permission: [
                      'app:general:configuracion:recursoshumanos:gestiondeltalento:entrevistas:view',
                    ],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/recursoshumanos/gestiondeltalento/entrevistas/new"
                        permission={[
                          'app:general:configuracion:recursoshumanos:gestiondeltalento:entrevistas:new',
                        ]}
                      />
                    ),
                  },
                  {
                    title: 'Candidatos',
                    href:
                      '/app/configuracion/recursoshumanos/gestiondeltalento/candidatos',
                    permission: [
                      'app:general:configuracion:recursoshumanos:gestiondeltalento:candidatos:view',
                    ],
                  },
                  /*
                  {
                    title: 'Responsabilidades',
                    href:
                      '/app/configuracion/rrhh/gestiondeltalento/responsabilidades',
                    // Falta por permisos
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/rrhh/gestiondeltalento/responsabilidades/new"
                      // Falta por permisos
                      />
                    ),
                  },
                  */
                ],
              },
              {
                title: 'Seguimiento y retención',
                href:
                  '/app/configuracion/recursoshumanos/seguimientoyretencion',
                permission: [
                  'app:general:configuracion:recursoshumanos:seguimientoyretencion:requisitoscontratacionrrhh:view',
                  'app:general:configuracion:recursoshumanos:seguimientoyretencion:tiposcapacitaciones:view',
                  'app:general:configuracion:recursoshumanos:seguimientoyretencion:temascapacitaciones:view',
                  'app:general:configuracion:recursoshumanos:'
                  + 'seguimientoyretencion:encuestasevaluacioncapacitaciones:view',
                ],
                items: [
                  {
                    title: 'Requisitos contratación RRHH',
                    href:
                      '/app/configuracion/recursoshumanos/seguimientoyretencion/requisitoscontratacionrrhh',
                    permission: [
                      'app:general:configuracion:recursoshumanos:seguimientoyretencion:requisitoscontratacionrrhh:view',
                    ],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/recursoshumanos/seguimientoyretencion/requisitoscontratacionrrhh/new"
                        permission={[
                          'app:general:configuracion:recursoshumanos:'
                          + 'seguimientoyretencion:requisitoscontratacionrrhh:new',
                        ]}
                      />
                    ),
                  },
                  {
                    title: 'Tipos capacitaciones',
                    href:
                      '/app/configuracion/recursoshumanos/seguimientoyretencion/tiposcapacitaciones',
                    permission: [
                      'app:general:configuracion:recursoshumanos:seguimientoyretencion:tiposcapacitaciones:view',
                    ],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/recursoshumanos/seguimientoyretencion/tiposcapacitaciones/new"
                        permission={[
                          'app:general:configuracion:recursoshumanos:seguimientoyretencion:tiposcapacitaciones:new',
                        ]}
                      />
                    ),
                  },
                  {
                    title: 'Temas capacitaciones',
                    href:
                      '/app/configuracion/recursoshumanos/seguimientoyretencion/temascapacitaciones',
                    permission: [
                      'app:general:configuracion:recursoshumanos:seguimientoyretencion:temascapacitaciones:view',
                    ],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/recursoshumanos/seguimientoyretencion/temascapacitaciones/new"
                        permission={[
                          'app:general:configuracion:recursoshumanos:seguimientoyretencion:temascapacitaciones:new',
                        ]}
                      />
                    ),
                  },
                  {
                    title: 'Encuestas evaluación capacitación',
                    href:
                      '/app/configuracion/recursoshumanos/seguimientoyretencion/encuestasevaluacioncapacitaciones',
                    permission: [
                      'app:general:configuracion:recursoshumanos:'
                      + 'seguimientoyretencion:encuestasevaluacioncapacitaciones:view',
                    ],
                  },
                ],
              },
              {
                title: 'Procesos disciplinarios',
                href:
                  '/app/configuracion/recursoshumanos/procesosdisciplinarios',
                permission: [
                  'app:general:configuracion:recursoshumanos:procesosdisciplinarios:disciplinarios:view',
                ],
                items: [
                  {
                    title: 'Disciplinarios',
                    href:
                      '/app/configuracion/recursoshumanos/procesosdisciplinarios/disciplinarios',
                    permission: [
                      'app:general:configuracion:recursoshumanos:procesosdisciplinarios:disciplinarios:view',
                    ],
                  },
                ],
              },
              {
                title: 'Bienestar laboral',
                href: '/app/configuracion/recursoshumanos/bienestarlaboral',
                permission: [
                  'app:general:configuracion:recursoshumanos:bienestarlaboral:tiposbeneficios:view',
                  'app:general:configuracion:recursoshumanos:bienestarlaboral:categoriasbeneficios:view',
                  'app:general:configuracion:recursoshumanos:bienestarlaboral:beneficios:view',
                  'app:general:configuracion:recursoshumanos:bienestarlaboral:tipodebonificacion:view',
                ],
                items: [
                  {
                    title: 'Tipos beneficios',
                    href:
                      '/app/configuracion/recursoshumanos/bienestarlaboral/tiposbeneficios',
                    permission: ['app:general:configuracion:recursoshumanos:bienestarlaboral:tiposbeneficios:view'],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/recursoshumanos/bienestarlaboral/tiposbeneficios/new"
                        permission={[
                          'app:general:configuracion:recursoshumanos:bienestarlaboral:tiposbeneficios:new',
                        ]}
                      />
                    ),
                  },
                  {
                    title: 'Categorías beneficios',
                    href:
                      '/app/configuracion/recursoshumanos/bienestarlaboral/categoriasbeneficios',
                    permission: [
                      'app:general:configuracion:recursoshumanos:bienestarlaboral:categoriasbeneficios:view',
                    ],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/recursoshumanos/bienestarlaboral/categoriasbeneficios/new"
                        permission={[
                          'app:general:configuracion:recursoshumanos:bienestarlaboral:categoriasbeneficios:new',
                        ]}
                      />
                    ),
                  },
                  {
                    title: 'Beneficios',
                    href:
                      '/app/configuracion/recursoshumanos/bienestarlaboral/beneficios',
                    permission: ['app:general:configuracion:recursoshumanos:bienestarlaboral:beneficios:view'],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/recursoshumanos/bienestarlaboral/beneficios/new"
                        permission={['app:general:configuracion:recursoshumanos:bienestarlaboral:beneficios:new']}
                      />
                    ),
                  },
                  {
                    title: 'Tipo de bonificación',
                    href:
                      '/app/configuracion/recursoshumanos/bienestarlaboral/tipodebonificacion',
                    permission: ['app:general:configuracion:recursoshumanos:bienestarlaboral:tipodebonificacion:view'],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/recursoshumanos/bienestarlaboral/tipodebonificacion/new"
                        permission={[
                          'app:general:configuracion:recursoshumanos:bienestarlaboral:tipodebonificacion:new',
                        ]}
                      />
                    ),
                  },
                ],
              },
              {
                title: 'Gestión del tiempo',
                href: '/app/configuracion/recursoshumanos/gestiondeltiempo',
                permission: [
                  'app:general:configuracion:recursoshumanos:gestiondeltiempo:unidadesdetiempo:view',
                  'app:general:configuracion:recursoshumanos:gestiondeltiempo:recesos:view',
                  'app:general:configuracion:recursoshumanos:gestiondeltiempo:tiposturnos:view',
                  'app:general:configuracion:recursoshumanos:gestiondeltiempo:turnos:view',
                  'app:general:configuracion:recursoshumanos:gestiondeltiempo:equiposdetrabajo:view',
                  'app:general:configuracion:recursoshumanos:gestiondeltiempo:proyectos:view',
                  'app:general:configuracion:recursoshumanos:gestiondeltiempo:hitos:view',
                  'app:general:configuracion:recursoshumanos:gestiondeltiempo:tipodehoraextra:view',
                  'app:general:configuracion:recursoshumanos:gestiondeltiempo:tiposmodalidadesdetrabajo:view',
                  'app:general:configuracion:recursoshumanos:gestiondeltiempo:modalidadesdetrabajo:view',
                  'app:general:configuracion:recursoshumanos:gestiondeltiempo:ausencias:tipospermisos:view',
                  'app:general:configuracion:recursoshumanos:gestiondeltiempo:ausencias:licencias:view',
                  'app:general:configuracion:recursoshumanos:gestiondeltiempo:ausencias:tipodeincapacidad:view',
                ],
                items: [
                  {
                    title: 'Unidades de tiempo',
                    href:
                      '/app/configuracion/recursoshumanos/gestiondeltiempo/unidadesdetiempo',
                    permission: [
                      'app:general:configuracion:recursoshumanos:gestiondeltiempo:unidadesdetiempo:view',
                    ],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/recursoshumanos/gestiondeltiempo/unidadesdetiempo/new"
                        permission={[
                          'app:general:configuracion:recursoshumanos:gestiondeltiempo:unidadesdetiempo:new',
                        ]}
                      />
                    ),
                  },
                  {
                    title: 'Recesos',
                    href:
                      '/app/configuracion/recursoshumanos/gestiondeltiempo/recesos',
                    permission: ['app:general:configuracion:recursoshumanos:gestiondeltiempo:recesos:view'],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/recursoshumanos/gestiondeltiempo/recesos/new"
                        permission={['app:general:configuracion:recursoshumanos:gestiondeltiempo:recesos:new']}
                      />
                    ),
                  },
                  {
                    title: 'Tipos turnos',
                    href:
                      '/app/configuracion/recursoshumanos/gestiondeltiempo/tiposturnos',
                    permission: [
                      'app:general:configuracion:recursoshumanos:gestiondeltiempo:tiposturnos:view',
                    ],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/recursoshumanos/gestiondeltiempo/tiposturnos/new"
                        permission={[
                          'app:general:configuracion:recursoshumanos:gestiondeltiempo:tiposturnos:new',
                        ]}
                      />
                    ),
                  },
                  {
                    title: 'Turnos',
                    href:
                      '/app/configuracion/recursoshumanos/gestiondeltiempo/turnos',
                    permission: ['app:general:configuracion:recursoshumanos:gestiondeltiempo:turnos:view'],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/recursoshumanos/gestiondeltiempo/turnos/new"
                        permission={['app:general:configuracion:recursoshumanos:gestiondeltiempo:turnos:new']}
                      />
                    ),
                  },
                  {
                    title: 'Equipos de trabajo',
                    href:
                      '/app/configuracion/recursoshumanos/gestiondeltiempo/equiposdetrabajo',
                    permission: [
                      'app:general:configuracion:recursoshumanos:gestiondeltiempo:equiposdetrabajo:view',
                    ],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/recursoshumanos/gestiondeltiempo/equiposdetrabajo/new"
                        permission={[
                          'app:general:configuracion:recursoshumanos:gestiondeltiempo:equiposdetrabajo:new',
                        ]}
                      />
                    ),
                  },
                  {
                    title: 'Proyectos',
                    href:
                      '/app/configuracion/recursoshumanos/gestiondeltiempo/proyectos',
                    permission: [
                      'app:general:configuracion:recursoshumanos:gestiondeltiempo:proyectos:view',
                    ],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/recursoshumanos/gestiondeltiempo/proyectos/new"
                        permission={[
                          'app:general:configuracion:recursoshumanos:gestiondeltiempo:proyectos:new',
                        ]}
                      />
                    ),
                  },
                  {
                    title: 'Hitos',
                    href:
                      '/app/configuracion/recursoshumanos/gestiondeltiempo/hitos',
                    permission: [
                      'app:general:configuracion:recursoshumanos:gestiondeltiempo:hitos:view',
                    ],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/recursoshumanos/gestiondeltiempo/hitos/new"
                        permission={[
                          'app:general:configuracion:recursoshumanos:gestiondeltiempo:hitos:new',
                        ]}
                      />
                    ),
                  },
                  {
                    title: 'Tipo de hora extra',
                    href:
                      '/app/configuracion/recursoshumanos/gestiondeltiempo/tipodehoraextra',
                    permission: ['app:general:configuracion:recursoshumanos:gestiondeltiempo:tipodehoraextra:view'],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/recursoshumanos/gestiondeltiempo/tipodehoraextra/new"
                        permission={[
                          'app:general:configuracion:recursoshumanos:gestiondeltiempo:tipodehoraextra:new',
                        ]}
                      />
                    ),
                  },
                  {
                    title: 'Horas extras',
                    href:
                      '/app/configuracion/recursoshumanos/gestiondeltiempo/horasextras',
                    permission: ['configuracion:rrhh:horasextras:list'],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/recursoshumanos/gestiondeltiempo/horasextras/new"
                        permission={['configuracion:rrhh:horasextras:create']}
                      />
                    ),
                  },
                  {
                    title: 'Tipos modalidades de trabajo',
                    href:
                      '/app/configuracion/recursoshumanos/gestiondeltiempo/tiposmodalidadesdetrabajo',
                    permission: ['app:general:configuracion:recursoshumanos:'
                      + 'gestiondeltiempo:tiposmodalidadesdetrabajo:view'],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/recursoshumanos/gestiondeltiempo/tiposmodalidadesdetrabajo/new"
                        permission={['app:general:configuracion:recursoshumanos:'
                          + 'gestiondeltiempo:tiposmodalidadesdetrabajo:new']}
                      />
                    ),
                  },
                  {
                    title: 'Modalidades de trabajo',
                    href:
                      '/app/configuracion/recursoshumanos/gestiondeltiempo/modalidadesdetrabajo',
                    permission: ['app:general:configuracion:recursoshumanos:'
                      + 'gestiondeltiempo:modalidadesdetrabajo:view'],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/recursoshumanos/gestiondeltiempo/modalidadesdetrabajo/new"
                        permission={['app:general:configuracion:recursoshumanos:'
                          + 'gestiondeltiempo:modalidadesdetrabajo:new']}
                      />
                    ),
                  },
                  {
                    title: 'Ausencias',
                    href:
                      '/app/configuracion/recursoshumanos/gestiondeltiempo/ausencias',
                    permission: [
                      'app:general:configuracion:recursoshumanos:gestiondeltiempo:ausencias:tipospermisos:view',
                      'app:general:configuracion:recursoshumanos:gestiondeltiempo:ausencias:licencias:view',
                      'app:general:configuracion:recursoshumanos:gestiondeltiempo:ausencias:tipodeincapacidad:view',
                    ],
                    items: [
                      {
                        title: 'Tipos permisos',
                        href:
                          '/app/configuracion/recursoshumanos/gestiondeltiempo/ausencias/tipospermisos',
                        permission: ['app:general:configuracion:recursoshumanos:'
                          + 'gestiondeltiempo:ausencias:tipospermisos:view'],
                        info: () => (
                          <IconButtonBase
                            url="/app/configuracion/recursoshumanos/gestiondeltiempo/ausencias/tipospermisos/new"
                            permission={[
                              'app:general:configuracion:recursoshumanos:'
                              + 'gestiondeltiempo:ausencias:tipospermisos:new',
                            ]}
                          />
                        ),
                      },
                      {
                        title: 'Licencias',
                        href:
                          '/app/configuracion/recursoshumanos/gestiondeltiempo/ausencias/licencias',
                        permission: [
                          'app:general:configuracion:recursoshumanos:gestiondeltiempo:ausencias:licencias:view',
                        ],
                        info: () => (
                          <IconButtonBase
                            url="/app/configuracion/recursoshumanos/gestiondeltiempo/ausencias/licencias/new"
                            permission={[
                              'app:general:configuracion:recursoshumanos:gestiondeltiempo:ausencias:licencias:new',
                            ]}
                          />
                        ),
                      },
                      {
                        title: 'Tipo de incapacidad',
                        href:
                          '/app/configuracion/recursoshumanos/gestiondeltiempo/ausencias/tipodeincapacidad',
                        permission: [
                          'app:general:configuracion:recursoshumanos:'
                          + 'gestiondeltiempo:ausencias:tipodeincapacidad:view',
                        ],
                        info: () => (
                          <IconButtonBase
                            url="/app/configuracion/recursoshumanos/gestiondeltiempo/ausencias/tipodeincapacidad/new"
                            permission={[
                              'app:general:configuracion:recursoshumanos:'
                              + 'gestiondeltiempo:ausencias:tipodeincapacidad:new',
                            ]}
                          />
                        ),
                      },
                    ],
                  },
                ],
              },
              {
                title: 'Nómina',
                href: '/app/configuracion/recursoshumanos/nomina',
                permission: [
                  'app:general:configuracion:recursoshumanos:nomina:conceptosnomina:view',
                  'app:general:configuracion:recursoshumanos:nomina:smlvauxiliodetransporte:view',
                  'app:general:configuracion:recursoshumanos:nomina:prestacionessociales:view',
                  'app:general:configuracion:recursoshumanos:nomina:frecuenciasdepagos:view',
                  'app:general:configuracion:recursoshumanos:nomina:tiposdeducciones:view',
                  'app:general:configuracion:recursoshumanos:nomina:deducciones:view',
                ],
                items: [
                  {
                    title: 'Conceptos nómina',
                    href:
                      '/app/configuracion/recursoshumanos/nomina/conceptosnomina',
                    permission: [
                      'app:general:configuracion:recursoshumanos:nomina:conceptosnomina:view',
                    ],
                  },
                  {
                    title: 'SMLV - AUXILIO DE TRANSPORTE',
                    href:
                      '/app/configuracion/recursoshumanos/nomina/smlvauxiliodetransporte',
                    permission: [
                      'app:general:configuracion:recursoshumanos:nomina:smlvauxiliodetransporte:view',
                    ],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/recursoshumanos/nomina/smlvauxiliodetransporte/new"
                        permission={[
                          'app:general:configuracion:recursoshumanos:nomina:smlvauxiliodetransporte:new',
                        ]}
                      />
                    ),
                  },
                  {
                    title: 'Prestaciones sociales',
                    href:
                      '/app/configuracion/recursoshumanos/nomina/prestacionessociales',
                    permission: [
                      'app:general:configuracion:recursoshumanos:nomina:prestacionessociales:view',
                    ],
                  },
                  {
                    title: 'Frecuencias de pagos',
                    href:
                      '/app/configuracion/recursoshumanos/nomina/frecuenciasdepagos',
                    permission: ['app:general:configuracion:recursoshumanos:nomina:frecuenciasdepagos:view'],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/recursoshumanos/nomina/frecuenciasdepagos/new"
                        permission={['app:general:configuracion:recursoshumanos:nomina:frecuenciasdepagos:new']}
                      />
                    ),
                  },
                  {
                    title: 'Tipos deducciones',
                    href:
                      '/app/configuracion/recursoshumanos/nomina/tiposdeducciones',
                    permission: ['app:general:configuracion:recursoshumanos:nomina:tiposdeducciones:view'],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/recursoshumanos/nomina/tiposdeducciones/new"
                        permission={[
                          'app:general:configuracion:recursoshumanos:nomina:tiposdeducciones:new',
                        ]}
                      />
                    ),
                  },
                  {
                    title: 'Deducciones',
                    href:
                      '/app/configuracion/recursoshumanos/nomina/deducciones',
                    permission: ['app:general:configuracion:recursoshumanos:nomina:deducciones:view'],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/recursoshumanos/nomina/deducciones/new"
                        permission={['app:general:configuracion:recursoshumanos:nomina:deducciones:new']}
                      />
                    ),
                  },
                ],
              },
              {
                title: 'PILA',
                href: '/app/configuracion/recursoshumanos/pila',
                permission: [
                  'app:general:configuracion:recursoshumanos:pila:tiposdecotizantes:view',
                  'app:general:configuracion:recursoshumanos:pila:tiposdeplantillas:view',
                  'app:general:configuracion:recursoshumanos:pila:clasesaportantes:view',
                ],
                items: [
                  {
                    title: 'Tipos de cotizantes',
                    href:
                      '/app/configuracion/recursoshumanos/pila/tiposdecotizantes',
                    permission: ['app:general:configuracion:recursoshumanos:pila:tiposdecotizantes:view'],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/recursoshumanos/pila/tiposdecotizantes/new"
                        permission={['app:general:configuracion:recursoshumanos:pila:tiposdecotizantes:new']}
                      />
                    ),
                  },
                  {
                    title: 'Tipos de planillas',
                    href:
                      '/app/configuracion/recursoshumanos/pila/tiposdeplanillas',
                    permission: ['app:general:configuracion:recursoshumanos:pila:tiposdeplantillas:view'],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/recursoshumanos/pila/tiposdeplanillas/new"
                        permission={['app:general:configuracion:recursoshumanos:pila:tiposdeplantillas:new']}
                      />
                    ),
                  },
                  {
                    title: 'Clases aportantes',
                    href:
                      '/app/configuracion/recursoshumanos/pila/clasesaportantes',
                    permission: ['app:general:configuracion:recursoshumanos:pila:clasesaportantes:view'],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/recursoshumanos/pila/clasesaportantes/new"
                        permission={['app:general:configuracion:recursoshumanos:pila:clasesaportantes:new']}
                      />
                    ),
                  },
                ],
              },
              {
                title: 'Certificados',
                href: '/app/configuracion/recursoshumanos/certificados',
                permission: ['app:general:configuracion:recursoshumanos:certificados:view'],
                items: [],
              },
              {
                title: 'Provisiones',
                href: '/app/configuracion/recursoshumanos/provisiones',
                permission: ['app:general:configuracion:recursoshumanos:provisiones:view'],
                items: [],
              },
              {
                title: 'Desvinculación laboral',
                href:
                  '/app/configuracion/recursoshumanos/desvinculacionlaboral',
                permission: [
                  'app:general:configuracion:recursoshumanos:desvinculacionlaboral:requisitospazysalvo:view',
                  'app:general:configuracion:recursoshumanos:desvinculacionlaboral:pazysalvo:view',
                ],
                items: [
                  {
                    title: 'Requisitos paz y salvo',
                    href:
                      '/app/configuracion/recursoshumanos/desvinculacionlaboral/requisitospazysalvo',
                    permission: ['app:general:configuracion:recursoshumanos:'
                      + 'desvinculacionlaboral:requisitospazysalvo:view'],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/recursoshumanos/desvinculacionlaboral/requisitospazysalvo/new"
                        permission={['app:general:configuracion:recursoshumanos:'
                          + 'desvinculacionlaboral:requisitospazysalvo:new']}
                      />
                    ),
                  },
                  {
                    title: 'Paz y salvo',
                    href:
                      '/app/configuracion/recursoshumanos/desvinculacionlaboral/pazysalvo',
                    permission: ['app:general:configuracion:recursoshumanos:desvinculacionlaboral:pazysalvo:view'],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/recursoshumanos/desvinculacionlaboral/pazysalvo/new"
                        permission={['app:general:configuracion:recursoshumanos:desvinculacionlaboral:pazysalvo:new']}
                      />
                    ),
                  },
                ],
              },
              {
                title: 'Salud y seguridad en el trabajo (SST)',
                href:
                  '/app/configuracion/recursoshumanos/saludyseguridadeneltrabajo',
                permission: [
                  'app:general:configuracion:recursoshumanos:saludyseguridadeneltrabajo:consecutivossst:view',
                  'app:general:configuracion:recursoshumanos:saludyseguridadeneltrabajo:transaccionessst:view',
                  'app:general:configuracion:recursoshumanos:saludyseguridadeneltrabajo:tipoderiesgos:view',
                  'app:general:configuracion:recursoshumanos:saludyseguridadeneltrabajo:clasificacionderiesgos:view',
                  'app:general:configuracion:recursoshumanos:saludyseguridadeneltrabajo:probabilidadderiesgos:view',
                  'app:general:configuracion:recursoshumanos:saludyseguridadeneltrabajo:impactoderiesgos:view',
                  'app:general:configuracion:recursoshumanos:saludyseguridadeneltrabajo:tipoequipodetrabajo:view',
                  'app:general:configuracion:recursoshumanos:'
                  + 'saludyseguridadeneltrabajo:elementosdeproteccionprofesional:view',
                ],
                items: [
                  {
                    title: 'Consecutivos SST',
                    href:
                      '/app/configuracion/recursoshumanos/saludyseguridadeneltrabajo/consecutivossst',
                    permission: ['app:general:configuracion:recursoshumanos:'
                      + 'saludyseguridadeneltrabajo:consecutivossst:view'],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/recursoshumanos/saludyseguridadeneltrabajo/consecutivossst/new"
                        permission={['app:general:configuracion:recursoshumanos:'
                          + 'saludyseguridadeneltrabajo:consecutivossst:new']}
                      />
                    ),
                  },
                  {
                    title: 'Transacciones SST',
                    href:
                      '/app/configuracion/recursoshumanos/saludyseguridadeneltrabajo/transaccionessst',
                    permission: ['app:general:configuracion:recursoshumanos:'
                      + 'saludyseguridadeneltrabajo:transaccionessst:view'],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/recursoshumanos/saludyseguridadeneltrabajo/transaccionessst/new"
                        permission={['app:general:configuracion:recursoshumanos:'
                          + 'saludyseguridadeneltrabajo:transaccionessst:new']}
                      />
                    ),
                  },
                  {
                    title: 'Tipo de riesgos',
                    href:
                      '/app/configuracion/recursoshumanos/saludyseguridadeneltrabajo/tipoderiesgos',
                    permission: ['app:general:configuracion:recursoshumanos:'
                      + 'saludyseguridadeneltrabajo:tipoderiesgos:view'],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/recursoshumanos/saludyseguridadeneltrabajo/tipoderiesgos/new"
                        permission={['app:general:configuracion:recursoshumanos:'
                          + 'saludyseguridadeneltrabajo:tipoderiesgos:new']}
                      />
                    ),
                  },
                  {
                    title: 'Clasificación de riesgos',
                    href:
                      '/app/configuracion/recursoshumanos/saludyseguridadeneltrabajo/clasificacionderiesgos',
                    permission: ['app:general:configuracion:recursoshumanos:'
                      + 'saludyseguridadeneltrabajo:clasificacionderiesgos:view'],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/recursoshumanos/saludyseguridadeneltrabajo/clasificacionderiesgos/new"
                        permission={['app:general:configuracion:recursoshumanos:'
                          + 'saludyseguridadeneltrabajo:clasificacionderiesgos:new']}
                      />
                    ),
                  },
                  {
                    title: 'Probabilidad de riesgos',
                    href:
                      '/app/configuracion/recursoshumanos/saludyseguridadeneltrabajo/probabilidadderiesgos',
                    permission: ['app:general:configuracion:recursoshumanos:'
                      + 'saludyseguridadeneltrabajo:probabilidadderiesgos:view'],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/recursoshumanos/saludyseguridadeneltrabajo/probabilidadderiesgos/new"
                        permission={['app:general:configuracion:recursoshumanos:'
                          + 'saludyseguridadeneltrabajo:probabilidadderiesgos:new']}
                      />
                    ),
                  },
                  {
                    title: 'Impacto de riesgos',
                    href:
                      '/app/configuracion/recursoshumanos/saludyseguridadeneltrabajo/impactoderiesgos',
                    permission: ['app:general:configuracion:recursoshumanos:'
                      + 'saludyseguridadeneltrabajo:impactoderiesgos:view'],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/recursoshumanos/saludyseguridadeneltrabajo/impactoderiesgos/new"
                        permission={['app:general:configuracion:recursoshumanos:'
                          + 'saludyseguridadeneltrabajo:impactoderiesgos:new']}
                      />
                    ),
                  },
                  {
                    title: 'Tipo equipo trabajo',
                    href:
                      '/app/configuracion/recursoshumanos/saludyseguridadeneltrabajo/tipoequipotrabajo',
                    permission: ['app:general:configuracion:recursoshumanos:'
                      + 'saludyseguridadeneltrabajo:tipoequipodetrabajo:view'],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/recursoshumanos/saludyseguridadeneltrabajo/tipoequipotrabajo/new"
                        permission={['app:general:configuracion:recursoshumanos:'
                          + 'saludyseguridadeneltrabajo:tipoequipodetrabajo:new']}
                      />
                    ),
                  },
                  {
                    title: 'Elementos de protección profesional',
                    href:
                      '/app/configuracion/recursoshumanos/saludyseguridadeneltrabajo/elementosdeproteccionprofesional',
                    permission: ['app:general:configuracion:recursoshumanos:'
                      + 'saludyseguridadeneltrabajo:elementosdeproteccionprofesional:view'],
                    info: () => (
                      <IconButtonBase
                        url={'/app/configuracion/recursoshumanos/saludyseguridadeneltrabajo/'
                          + 'elementosdeproteccionprofesional/new'}
                        permission={['app:general:configuracion:recursoshumanos:'
                          + 'saludyseguridadeneltrabajo:elementosdeproteccionprofesional:new']}
                      />
                    ),
                  },
                ],
              },
              {
                title: 'Gestión de acceso',
                href: '/app/configuracion/recursoshumanos/gestiondeacceso',
                permission: ['app:general:configuracion:recursoshumanos:gestiondeacceso:accesoempleados:view'],
                items: [],
              },
            ],
          },
          {
            title: 'Gobierno corporativo',
            href: '/app/configuracion/gobiernocorporativo',
            permission: [
              'app:general:configuracion:gobiernocorporativo:consecutivosgobiernocorporativo:view',
              'app:general:configuracion:gobiernocorporativo:transaccionesgobiernocorporativo:view',
              'app:general:configuracion:gobiernocorporativo:configuracionempresa:view',
              'app:general:configuracion:gobiernocorporativo:sedesempresa:view',
              'app:general:configuracion:gobiernocorporativo:clasificacioncalendario:view',
              'app:general:configuracion:gobiernocorporativo:gestionempresarial:faltas:view',
              'app:general:configuracion:gobiernocorporativo:organigrama:estructuraorganizacional:view',
              'app:general:configuracion:gobiernocorporativo:calidad:consecutivoscalidad:view',
              'app:general:configuracion:gobiernocorporativo:calidad:transaccionescalidad:view',
            ],
            items: [
              {
                title: 'Consecutivos gobierno corporativo',
                href:
                  '/app/configuracion/gobiernocorporativo/consecutivosgobiernocorporativo',
                permission: ['app:general:configuracion:gobiernocorporativo:'
                  + 'consecutivosgobiernocorporativo:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/gobiernocorporativo/consecutivosgobiernocorporativo/new"
                    permission={[
                      'app:general:configuracion:gobiernocorporativo:'
                      + 'consecutivosgobiernocorporativo:new',
                    ]}
                  />
                ),
              },
              {
                title: 'Transacciones gobierno corporativo',
                href:
                  '/app/configuracion/gobiernocorporativo/transaccionesgobiernocorporativo',
                permission: ['app:general:configuracion:gobiernocorporativo:'
                  + 'transaccionesgobiernocorporativo:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/gobiernocorporativo/transaccionesgobiernocorporativo/new"
                    permission={[
                      'app:general:configuracion:gobiernocorporativo:transaccionesgobiernocorporativo:new',
                    ]}
                  />
                ),
              },
              {
                title: 'Configuración empresa',
                href:
                  '/app/configuracion/gobiernocorporativo/configuracionempresa',
                permission: ['app:general:configuracion:gobiernocorporativo:configuracionempresa:edit'],
              },
              {
                title: 'Sedes empresa',
                href: '/app/configuracion/gobiernocorporativo/sedesempresa',
                permission: ['app:general:configuracion:gobiernocorporativo:sedesempresa:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/gobiernocorporativo/sedesempresa/new"
                    permission={[
                      'app:general:configuracion:gobiernocorporativo:sedesempresa:new',
                    ]}
                  />
                ),
              },
              {
                title: 'Clasificación Calendario',
                href:
                  '/app/configuracion/gobiernocorporativo/clasificacioncalendario',
                permission: ['app:general:configuracion:gobiernocorporativo:clasificacioncalendario:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/gobiernocorporativo/clasificacioncalendario/new"
                    permission={[
                      'app:general:configuracion:gobiernocorporativo:clasificacioncalendario:new',
                    ]}
                  />
                ),
              },
              {
                title: 'Gestión empresarial',
                href:
                  '/app/configuracion/gobiernocorporativo/gestionempresarial',
                items: [
                  {
                    title: 'Faltas',
                    href:
                      '/app/configuracion/gobiernocorporativo/gestionempresarial/faltas',
                    permission: [
                      'app:general:configuracion:gobiernocorporativo:gestionempresarial:faltas:view',
                    ],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/gobiernocorporativo/gestionempresarial/faltas/new"
                        permission={[
                          'app:general:configuracion:gobiernocorporativo:gestionempresarial:faltas:new',
                        ]}
                      />
                    ),
                  },
                ],
              },
              {
                title: 'Organigrama',
                href: '/app/configuracion/gobiernocorporativo/organigrama',
                items: [
                  {
                    title: 'Estructura organizacional',
                    href:
                      '/app/configuracion/gobiernocorporativo/organigrama/estructuraorganizacional',
                    permission: [
                      'app:general:configuracion:gobiernocorporativo:organigrama:estructuraorganizacional:view',
                    ],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/gobiernocorporativo/organigrama/estructuraorganizacional/new"
                        permission={[
                          'app:general:configuracion:gobiernocorporativo:organigrama:estructuraorganizacional:new',
                        ]}
                      />
                    ),
                  },
                ],
              },
              {
                title: 'Calidad',
                href: '/app/configuracion/gobiernocorporativo/calidad',
                items: [
                  {
                    title: 'Consecutivos calidad',
                    href:
                      '/app/configuracion/gobiernocorporativo/calidad/consecutivoscalidad',
                    permission: [
                      'app:general:configuracion:gobiernocorporativo:calidad:consecutivoscalidad:view',
                    ],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/gobiernocorporativo/calidad/consecutivoscalidad/new"
                        permission={[
                          'app:general:configuracion:gobiernocorporativo:calidad:consecutivoscalidad:new',
                        ]}
                      />
                    ),
                  },
                  {
                    title: 'Transacciones calidad',
                    href:
                      '/app/configuracion/gobiernocorporativo/calidad/transaccionescalidad',
                    permission: [
                      'app:general:configuracion:gobiernocorporativo:calidad:transaccionescalidad:view',
                    ],
                    info: () => (
                      <IconButtonBase
                        url="/app/configuracion/gobiernocorporativo/calidad/transaccionescalidad/new"
                        permission={[
                          'app:general:configuracion:gobiernocorporativo:calidad:transaccionescalidad:new',
                        ]}
                      />
                    ),
                  },
                ],
              },
            ],
          },
          {
            title: 'Gestión documental (ECM)',
            href: '/app/configuracion/gestiondocumental',
            permission: [
              'app:general:configuracion:gestiondocumental:clasificacionpoliticas:view',
            ],
            items: [
              {
                title: 'Clasificación políticas',
                href:
                  '/app/configuracion/gestiondocumental/clasificacionpoliticas',
                permission: [
                  'app:general:configuracion:gestiondocumental:clasificacionpoliticas:view',
                ],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/gestiondocumental/clasificacionpoliticas/new"
                    permission={[
                      'app:general:configuracion:gestiondocumental:clasificacionpoliticas:new',
                    ]}
                  />
                ),
              },
            ],
          },
          {
            title: 'Gestión de contratos',
            href: '/app/configuracion/gestiondecontratos',
            permission: [
              'app:general:configuracion:gestiondecontratos:consecutivosgestiondecontratos:view',
              'app:general:configuracion:gestiondecontratos:transaccionesgestiondecontratos:view',
              'app:general:configuracion:gestiondecontratos:tiposdocumentoscontratos:view',
              'app:general:configuracion:gestiondecontratos:clasificacioncontratos:view',
              'app:general:configuracion:gestiondecontratos:partesinvolucradas:view',
            ],
            items: [
              {
                title: 'Consecutivos gestión de contratos',
                href:
                  '/app/configuracion/gestiondecontratos/consecutivosgestiondecontratos',
                permission: [
                  'app:general:configuracion:gestiondecontratos:consecutivosgestiondecontratos:view',
                ],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/gestiondecontratos/consecutivosgestiondecontratos/new"
                    permission={[
                      'app:general:configuracion:gestiondecontratos:consecutivosgestiondecontratos:new',
                    ]}
                  />
                ),
              },
              {
                title: 'Transacciones gestión de contratos',
                href:
                  '/app/configuracion/gestiondecontratos/transaccionesgestiondecontratos',
                permission: [
                  'app:general:configuracion:gestiondecontratos:transaccionesgestiondecontratos:view',
                ],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/gestiondecontratos/transaccionesgestiondecontratos/new"
                    permission={[
                      'app:general:configuracion:gestiondecontratos:transaccionesgestiondecontratos:new',
                    ]}
                  />
                ),
              },
              {
                title: 'Tipos documentos contratos',
                href:
                  '/app/configuracion/gestiondecontratos/tiposdocumentoscontratos',
                permission: [
                  'app:general:configuracion:gestiondecontratos:tiposdocumentoscontratos:view',
                ],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/gestiondecontratos/tiposdocumentoscontratos/new"
                    permission={[
                      'app:general:configuracion:gestiondecontratos:tiposdocumentoscontratos:new',
                    ]}
                  />
                ),
              },
              {
                title: 'Clasificación contratos',
                href:
                  '/app/configuracion/gestiondecontratos/clasificacioncontratos',
                permission: [
                  'app:general:configuracion:gestiondecontratos:clasificacioncontratos:view',
                ],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/gestiondecontratos/clasificacioncontratos/new"
                    permission={[
                      'app:general:configuracion:gestiondecontratos:clasificacioncontratos:new',
                    ]}
                  />
                ),
              },
              {
                title: 'Partes involucradas',
                href:
                  '/app/configuracion/gestiondecontratos/partesinvolucradas',
                permission: [
                  'app:general:configuracion:gestiondecontratos:partesinvolucradas:view',
                ],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/gestiondecontratos/partesinvolucradas/new"
                    permission={[
                      'app:general:configuracion:gestiondecontratos:partesinvolucradas:new',
                    ]}
                  />
                ),
              },
            ],
          },
          {
            title: 'Gestión solicitudes (PQRSF)',
            href: '/app/configuracion/gestionsolicitudes',
            permission: [
              'app:general:configuracion:gestionsolicitudes:categoriassolicitud:view',
              'app:general:configuracion:gestionsolicitudes:niveldeservicio:view',
              'app:general:configuracion:gestionsolicitudes:nivel:view',
              'app:general:configuracion:gestionsolicitudes:prioridadimpacto:view',
            ],
            items: [
              {
                title: 'Categorías solicitud',
                href:
                  '/app/configuracion/gestionsolicitudes/categoriassolicitud',
                permission: ['app:general:configuracion:gestionsolicitudes:categoriassolicitud:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/gestionsolicitudes/categoriassolicitud/new"
                    permission={[
                      'app:general:configuracion:gestionsolicitudes:categoriassolicitud:new',
                    ]}
                  />
                ),
              },
              {
                title: 'Nivel de servicio',
                href: '/app/configuracion/gestionsolicitudes/niveldeservicio',
                permission: ['app:general:configuracion:gestionsolicitudes:niveldeservicio:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/gestionsolicitudes/niveldeservicio/new"
                    permission={[
                      'app:general:configuracion:gestionsolicitudes:niveldeservicio:new',
                    ]}
                  />
                ),
              },
              {
                title: 'Nivel',
                href: '/app/configuracion/gestionsolicitudes/nivel',
                permission: ['app:general:configuracion:gestionsolicitudes:nivel:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/gestionsolicitudes/nivel/new"
                    permission={[
                      'app:general:configuracion:gestionsolicitudes:nivel:new',
                    ]}
                  />
                ),
              },
              {
                title: 'Prioridad - Impacto',
                href: '/app/configuracion/gestionsolicitudes/prioridadimpacto',
                permission: ['app:general:configuracion:gestionsolicitudes:prioridadimpacto:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/gestionsolicitudes/prioridadimpacto/new"
                    permission={[
                      'app:general:configuracion:gestionsolicitudes:prioridadimpacto:new',
                    ]}
                  />
                ),
              },
            ],
          },
          {
            title: 'Centro de ayuda',
            href: '/app/configuracion/centroayuda',
            permission: [
              'app:general:configuracion:centrodeayuda:ayudasgraficas:view',
              'app:general:configuracion:centrodeayuda:preguntasfrecuentes:view',
            ],
            items: [
              {
                title: 'Ayudas gráficas',
                href: '/app/configuracion/centroayuda/ayudasgraficas',
                permission: ['app:general:configuracion:centrodeayuda:ayudasgraficas:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/centroayuda/ayudasgraficas/new"
                    permission={[
                      'app:general:configuracion:centrodeayuda:ayudasgraficas:new',
                    ]}
                  />
                ),
              },
              {
                title: 'Preguntas frecuentes',
                href: '/app/configuracion/centroayuda/preguntasfrecuentes',
                permission: ['app:general:configuracion:centrodeayuda:preguntasfrecuentes:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/centroayuda/preguntasfrecuentes/new"
                    permission={['app:general:configuracion:centrodeayuda:preguntasfrecuentes:new']}
                  />
                ),
              },
            ],
          },
          {
            title: 'General',
            href: '/app/configuracion/general',
            permission: [
              'app:general:configuracion:general:atributosgenerales:view',
              'app:general:configuracion:general:imprimibles:view',
              'app:general:configuracion:general:encuestas:view',
              'app:general:configuracion:general:tiposadjuntos:view',
              'app:general:configuracion:general:tiposdeidentificacion:view',
              'app:general:configuracion:general:nomenclaturas:view',
              'app:general:configuracion:general:novedades360:view',
            ],
            items: [
              {
                title: 'Atributos generales',
                href: '/app/configuracion/general/atributosgenerales',
                permission: ['app:general:configuracion:general:atributosgenerales:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/general/atributosgenerales/new"
                    permission={[
                      'app:general:configuracion:general:atributosgenerales:new',
                    ]}
                  />
                ),
              },
              /* {
                    title: 'Etiquetas',
                    href: '/app/configuracion/general/atributos/etiquetas',
                  },
              */
              {
                title: 'Imprimibles',
                href: '/app/configuracion/general/imprimibles',
                permission: ['app:general:configuracion:general:imprimibles:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/general/imprimibles/new"
                    permission={[
                      'app:general:configuracion:general:imprimibles:new',
                    ]}
                  />
                ),
              },
              {
                title: 'Encuestas',
                href: '/app/configuracion/general/encuestas',
                permission: ['app:general:configuracion:general:encuestas:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/general/encuestas/new"
                    permission={[
                      'app:general:configuracion:general:encuestas:new',
                    ]}
                  />
                ),
              },
              {
                title: 'Tipos adjuntos',
                href: '/app/configuracion/general/tiposadjuntos',
                permission: ['app:general:configuracion:general:tiposadjuntos:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/general/tiposadjuntos/new"
                    permission={[
                      'app:general:configuracion:general:tiposadjuntos:new',
                    ]}
                  />
                ),
              },
              {
                title: 'Tipos de identificación',
                href: '/app/configuracion/general/tiposdeidentificacion',
                permission: ['app:general:configuracion:general:tiposdeidentificacion:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/general/tiposdeidentificacion/new"
                    permission={[
                      'app:general:configuracion:general:tiposdeidentificacion:new',
                    ]}
                  />
                ),
              },
              {
                title: 'Nomenclaturas',
                href: '/app/configuracion/general/nomenclaturas',
                permission: ['app:general:configuracion:general:nomenclaturas:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/general/nomenclaturas/new"
                    permission={['app:general:configuracion:general:nomenclaturas:new']}
                  />
                ),
              },
              {
                title: 'Novedades 360',
                href: '/app/configuracion/general/novedades360',
                permission: ['app:general:configuracion:general:novedades360:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/general/novedades360/new"
                    permission={['app:general:configuracion:general:novedades360:new']}
                  />
                ),
              },
              /* {
                title: 'Trazabilidad',
                href: '/app/configuracion/general/trazabilidad',
                items: [
                  {
                    title: 'Puntos de control',
                    href: '/app/configuracion/general/trazabilidad/puntocontrol',
                  },
                  {
                    title: 'Master de Trazabilidad',
                    href: '/app/configuracion/general/trazabilidad/mastertrazabilidad',
                  },
                ],
              }, */
              // {
              //   title: 'Tributarios',
              //   href: '/app/configuracion/general/tributarios',
              //   items: [
              //     {
              //       title: 'Conceptos tributarios',
              //       href: '/app/configuracion/general/tributarios/conceptos',
              //     },
              //     {
              //       title: 'Clasificaciones por item',
              //       href: '/app/configuracion/general/tributarios/clasificacionTributarioItem',
              //     },
              //   ],
              // },
            ],
          },
          {
            title: 'División política',
            href: '/app/configuracion/divisionpolitica',
            permission: [
              'app:general:configuracion:divisionpolitica:paises:view',
              'app:general:configuracion:divisionpolitica:departamentos:view',
              'app:general:configuracion:divisionpolitica:ciudades:view',
            ],
            items: [
              {
                title: 'Países',
                href: '/app/configuracion/divisionpolitica/paises',
                permission: [
                  'app:general:configuracion:divisionpolitica:paises:view',
                ],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/divisionpolitica/paises/new"
                    permission={[
                      'app:general:configuracion:divisionpolitica:paises:new',
                    ]}
                  />
                ),
              },
              {
                title: 'Departamentos',
                href: '/app/configuracion/divisionpolitica/departamentos',
                permission: [
                  'app:general:configuracion:divisionpolitica:departamentos:view',
                ],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/divisionpolitica/departamentos/new"
                    permission={[
                      'app:general:configuracion:divisionpolitica:departamentos:new',
                    ]}
                  />
                ),
              },
              {
                title: 'Ciudades',
                href: '/app/configuracion/divisionpolitica/ciudades',
                permission: [
                  'app:general:configuracion:divisionpolitica:ciudades:view',
                ],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/divisionpolitica/ciudades/new"
                    permission={[
                      'app:general:configuracion:divisionpolitica:ciudades:new',
                    ]}
                  />
                ),
              },
            ],
          },
          {
            title: 'Notificaciones',
            href: '/app/configuracion/notificaciones',
            permission: [
              'app:general:configuracion:notificaciones:notificacionesprocesos:view',
              'app:general:configuracion:notificaciones:correosnotificaciones:view',
            ],
            items: [
              {
                title: 'Notificaciones procesos',
                href:
                  '/app/configuracion/notificaciones/notificacionesprocesos',
                permission: ['app:general:configuracion:notificaciones:notificacionesprocesos:view'],
              },
              {
                title: 'Correos notificaciones',
                href: '/app/configuracion/notificaciones/correosnotificaciones',
                permission: ['app:general:configuracion:notificaciones:correosnotificaciones:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/notificaciones/correosnotificaciones/new"
                    permission={['app:general:configuracion:notificaciones:correosnotificaciones:new']}
                  />
                ),
              },
            ],
          },
          {
            title: 'Seguridad',
            href: '/app/configuracion/seguridad',
            permission: [
              'app:general:configuracion:seguridad:usuarios:view',
              'app:general:configuracion:seguridad:roles:view',
              'app:general:configuracion:seguridad:grupospermisos:view',
              'app:general:configuracion:seguridad:cambiodecontrasena:general:view',
            ],
            items: [
              {
                title: 'Usuarios',
                href: '/app/configuracion/seguridad/usuarios',
                permission: ['app:general:configuracion:seguridad:usuarios:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/seguridad/usuarios/new"
                    permission={['app:general:configuracion:seguridad:usuarios:new']}
                  />
                ),
              },
              {
                title: 'Cambio de contraseña',
                href: '/app/configuracion/seguridad/cambiodecontraseña',
                permission: ['app:general:configuracion:seguridad:cambiodecontrasena:general:view'],
              },
              {
                title: 'Roles',
                href: '/app/configuracion/seguridad/roles',
                permission: ['app:general:configuracion:seguridad:roles:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/seguridad/roles/new"
                    permission={['app:general:configuracion:seguridad:roles:new']}
                  />
                ),
              },
              {
                title: 'Grupos permisos',
                href: '/app/configuracion/seguridad/grupospermisos',
                permission: ['app:general:configuracion:seguridad:grupospermisos:view'],
                info: () => (
                  <IconButtonBase
                    url="/app/configuracion/seguridad/grupospermisos/new"
                    permission={['app:general:configuracion:seguridad:grupospermisos:new']}
                  />
                ),
              },
            ],
          },
          // {
          //   title: 'RP Dental',
          //   href: '/app/configuracion/rpdental',
          //   items: [
          //     {
          //       title: 'Especialidades',
          //       href: '/app/configuracion/rpdental/especialidad',
          //       permission: ['configuracion:rpdental:especialidades:list'],
          //       info: () => (
          //         <RouterLink to="/app/configuracion/rpdental/especialidad/new">
          //           <IconButton>
          //             <PlusIcon />
          //           </IconButton>
          //         </RouterLink>
          //       ),
          //     },
          //     {
          //       title: 'Diagnósticos',
          //       href: '/app/configuracion/rpdental/diagnostico',
          //       permission: ['configuracion:rpdental:diagnosticos:list'],
          //       info: () => (
          //         <RouterLink to="/app/configuracion/rpdental/diagnostico/new">
          //           <IconButton>
          //             <PlusIcon />
          //           </IconButton>
          //         </RouterLink>
          //       ),
          //     },
          //     {
          //       title: 'Terceros',
          //       href: '/app/configuracion/rpdental/terceros',
          //       items: [
          //         {
          //           title: 'Instituciones',
          //           href: '/app/configuracion/rpdental/terceros/instituciones',
          //           permission: ['configuracion:rpdental:terceros:instituciones:list'],
          //           info: () => (
          //             <RouterLink to="/app/configuracion/rpdental/terceros/instituciones/new">
          //               <IconButton>
          //                 <PlusIcon />
          //               </IconButton>
          //             </RouterLink>
          //           ),
          //         },
          //         {
          //           title: 'Pacientes',
          //           href: '/app/configuracion/rpdental/terceros/pacientes',
          //           permission: ['configuracion:rpdental:terceros:pacientes:list'],
          //           info: () => (
          //             <RouterLink to="/app/configuracion/rpdental/terceros/pacientes/New">
          //               <IconButton>
          //                 <PlusIcon />
          //               </IconButton>
          //             </RouterLink>
          //           ),
          //         },
          //         {
          //           title: 'Cirujanos',
          //           href: '/app/configuracion/rpdental/terceros/cirujano',
          //           permission: ['configuracion:rpdental:terceros:cirujanos:list'],
          //           info: () => (
          //             <RouterLink to="/app/configuracion/rpdental/terceros/cirujano/New">
          //               <IconButton>
          //                 <PlusIcon />
          //               </IconButton>
          //             </RouterLink>
          //           ),
          //         },
          //       ],
          //     },
          //   ],
          // },
          // {
          //   title: 'PLM',
          //   href: '/app/plm',
          //   items: [
          //     {
          //       title: 'Producto',
          //       href: '/app/plm/maestros/producto/PT',
          //     },
          //   ],
          // },
          /* {
            title: 'PQRSF',
            href: '/app/configuracion/pqrsf',
            items: [
              {
                title: 'Tipo de solicitud',
                href: '/app/configuracion/pqrsf/tiposolicitud',
              },
            ],
          }, */
        ],
      },
    ],
  },
  /* {
    items: [
      {
        title: 'PQRSF',
        href: '/app/pqrsf',
        icon: FileTextIcon,
        items: [
          {
            title: 'PQRSF',
            href: '/app/pqrsf/solicitud',
          },
        ],
      },
    ],
  }, */
];

export default navConfig;
