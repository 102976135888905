import React, {
  useRef,
  useState,
  useEffect,
} from 'react';
import {
  Box,
  Tooltip,
  Divider,
  Popover,
  makeStyles,
  IconButton,
  Typography,
} from '@material-ui/core';
import { LabelLink, Table } from 'components';
import endPoints from 'endPoints/endPoints';
import { AlertTriangle } from 'react-feather';
import { withPermissionsChecker } from 'wrappers';

const labelLinkUrl = {
  urlInstructivo: '/app/configuracion/centroayuda/ayudasgraficas/edit',
  urlInstructivoPermissions: 'app:general:configuracion:centrodeayuda:ayudasgraficas:edit',
};

const useStyles = makeStyles({
  popover: {
    width: 1000,
  },
  divider: {
    marginTop: 5,
    marginBottom: 2,
  },
  listItem: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#eeeeee',
    },
  },
});

const Novedades = () => {
  const ref = useRef();
  const buttonRef = useRef();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const openPopover = (event) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const closePopover = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  useEffect(() => {
    setOpen(true);
    setAnchorEl(buttonRef.current);
  }, []);

  const columns = [
    {
      name: 'titulo',
    },
    {
      name: 'detalle',
    },
    {
      name: 'url_instructivo',
      component: row => (
        <LabelLink
          labelName={row.url_instructivo}
          labelUrl={`${labelLinkUrl.urlInstructivo}/${row.id}`}
          targetType="_blank"
          permission={[labelLinkUrl.urlInstructivoPermissions]}
        />
      ),
    },
  ];

  return (
    <>
      <Tooltip title="Novedades 360">
        <IconButton
          innerRef={buttonRef}
          color="inherit"
          onClick={openPopover}
        // disabled={!checkAccess(['app:general:configuracion:general:novedades360:view'])}
        >
          <AlertTriangle />
        </IconButton>
      </Tooltip>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={closePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        classes={{ paper: classes.popover }}
      >
        <Box p={2}>
          <Typography variant="h4">
            Novedades 360
          </Typography>
          <Divider
            className={classes.divider}
          />
          <Table
            columns={columns}
            forwardedRef={ref}
            filtersTable={false}
            genericSearch={false}
            serverSideUrl={endPoints.general.maestros.novedad360.getActivos}
          />
        </Box>
      </Popover>
    </>
  );
};

export default withPermissionsChecker(Novedades);
